import React from "react";
import { Link } from "react-router";
import { fn, api } from "app/utils";
import { url } from "app/constants";
import { connect } from "react-redux";
import { fetchData } from "app/actions";
import { Dialog, Form, TextInput } from "@xandanet/react-components";
import ReactGA from "react-ga";
import {
  makeGetProduct,
  makeGetProducts
} from "app/containers/product/selector";

import {makeGetPowerBIGroups} from "app/containers/powerbi-groups/selector";

@connect((state, ownProps) => {
  const getProduct = makeGetProduct();
  const getProducts = makeGetProducts();
  const getGroups = makeGetPowerBIGroups();

  return {
    product: getProduct(state, ownProps.params.productId),
    products: getProducts(state),
    powerBIGroups: getGroups(state),
    header: state.header
  };
})
export default class SiteHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      changePassword: false,
      dialogErrorNotification: false,
      activeMenu: false,
      activeProductMenu: false
    };
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);

    const { dashboard } = this.props;
    if (!dashboard) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  async fetchData() {
    // await this.props.dispatch(
    //   fetchData({
    //     type: "PRODUCT",
    //     url: `/sisense/folders`
    //   })
    // );

    const { powerBIGroups } = this.props;
    if (!powerBIGroups.collection.length) {
      await this.props.dispatch(fetchData({
        type: 'POWER_BI_GROUP',
        url: '/powerbi/groups-access',
      }));
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.header.activeMenu !== this.state.activeMenu){
      this.setState({
        activeMenu: this.props.header.activeMenu
      })
    }
  }

  renderUpdateProfileDialog = () => {
    const { me } = this.props;

    if (!me) {
      return;
    }

    return (
      <div>

        {!!this.state.dialogErrorNotification && (
          <div className="form-group-wide">
            <span className="dialog-error form-field-error-wrapper">
              Error: {this.state.dialogErrorNotification}
            </span>
          </div>
        )}

        {this.state.changePassword ? (
          <Form
            onSubmit={this.handlePasswordChange}
            className="edit-profile-form"
          >
            <TextInput
              name="current_password"
              validation="required"
              label="Existing Password"
              type="password"
              onChange={this.handleInputChange}
              className="input-main ghost-input"
              wide
            />
            <TextInput
              name="password"
              validation="required"
              label="New Password"
              type="password"
              onChange={this.handleInputChange}
              className="input-main ghost-input"
              wide
            />
            <TextInput
              ref={ref => (this.refPasswordConfirm = ref)}
              name="passwordConfirm"
              validation="required"
              label="Confirm Password"
              type="password"
              onChange={this.handleInputChange}
              className="input-main ghost-input"
              wide
            />
            <div className="buttonWrapper wide">
              <span className="button-secondary" onClick={this.handeChangeDetails}>
                cancel
              </span>
              <button className="button">save</button>
            </div>
          </Form>
        ) : (
          <Form
            onSubmit={this.handleProfileUpdate}
            className="edit-profile-form"
          >
            <TextInput
              name="firstname"
              label="First Name"
              validation="required"
              onChange={this.handleInputChange}
              value={me.data.firstName}
              className="input-main ghost-input"
              wide
            />
            <TextInput
              name="lastname"
              label="Last Name"
              validation="required"
              onChange={this.handleInputChange}
              value={me.data.lastName}
              className="input-main ghost-input"
              wide
            />
            <TextInput
              name="email"
              label="Email"
              validation="required|email"
              onChange={this.handleInputChange}
              value={me.data.email}
              className="input-main ghost-input"
              wide
            />

            {!this.state.changePassword && (
              <div className="form-group form-type-input input-main ghost-input">
              <span
                className="button-secondary"
                onClick={this.handleChangePassword}
              >
                      Change Password
                    </span>
              </div>
            )}

            <div className="buttonWrapper wide">
              <button className="button-primary">Save</button>
            </div>
          </Form>
        )}
      </div>
    );
  };

  isAdmin = () => {
    const { me } = this.props;

    console.log("NEW IS ADMIN",me && me.data && me.data.roleName === "admin");

    return me && me.data && me.data.roleName === "admin";

  }

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value,
      dialogErrorNotification: false
    });
  };

  handeChangeDetails = () => {
    this.setState({
      changePassword: false
    });
  };

  handleChangePassword = () => {
    this.setState({
      changePassword: true
    });
  };

  handleToggleMenu = () => {
    const showHide = this.state.activeMenu === true ? false : true;

    this.props.dispatch({
      type: "MOBILE_MENU_TOGGLE",
      payload: showHide
    });
  };

  handleToggleProductMenu = () => {
    const { activeProductMenu } = this.state;
    this.setState({
      activeProductMenu: activeProductMenu === true ? false : true
    });
  };

  handleClick = e => {
    if (this.node) {
      if (!this.node.contains(e.target)) {
        this.setState({ activeProductMenu: false });
      }
    }
  };

  handleProfileUpdate = async () => {
    const formData = new FormData();

    formData.append("firstname", this.state.firstname);
    formData.append("lastname", this.state.lastname);
    formData.append("email", this.state.email);

    const response = await api.update("/account", formData);

    if (api.error(response, false)) {
      this.setState({
        dialogErrorNotification:
          "There was an error updating your details. Please try again."
      });
    } else {
      this.props.dispatch({
        type: "ME_FULFILLED",
        payload: response
      });

      this.refDialog.close();
      fn.showNotification("Your details have been updated!", "success");
    }
  };

  handlePasswordChange = async () => {
    const formData = new FormData();

    if (this.state.password !== this.state.passwordConfirm) {
      this.refPasswordConfirm.setValidationMessage({
        valid: false,
        errors: ["Your passwords do not match."]
      });
      return false;
    }
    if (this.state.password == this.state.current_password) {
      this.refPasswordConfirm.setValidationMessage({
        valid: false,
        errors: [
          "Your new password must be different to your existing password."
        ]
      });
      return false;
    }
    formData.append("password", this.state.password);
    formData.append("current_password", this.state.current_password);

    const response = await api.update("/account/change-password", formData);
    if (api.error(response, false)) {
      this.setState({
        dialogErrorNotification: response.data
      });
    } else {
      ReactGA.event({
        category: "Authorization",
        action: "Change",
        label: `Password changed`
      });
      this.refDialog.close();
      fn.showNotification("Your details have been updated!", "success");
    }
  };

  renderMainMenu = () => {
    const { adminArea, params, me } = this.props;
    const { activeMenu } = this.state;

    const dashboardClassName =
      this.props.location.pathname.indexOf("/dashboard") !== -1
        ? "is-active"
        : "";

    const navClass = activeMenu === true ? "is-open-menu" : "";

    return (
      <React.Fragment>
          {adminArea ? (
            <React.Fragment>
              <ul className={`menu ${navClass}`}>
                <li className="menu-item" key="pages">
                  <Link
                    to={`/admin/pages/`}
                    activeClassName="is-active"
                    className="main-nav-link"
                  >
                    Pages
                  </Link>
                </li>

                <li className="menu-item" key="docs">
                  <Link
                    to={`/admin/resources/`}
                    activeClassName="is-active"
                    className="main-nav-link"
                  >
                    Resources
                  </Link>
                </li>

                {this.isAdmin() &&
                  <li className="menu-item">
                      <Link className="account-nav-menu-subitem" to="/">
                        Switch to Frontend
                      </Link>
                  </li>
                }
              </ul>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {params.reportId && (
                <ul className="menu">
                  <li className="menu-item" key="dashboards">
                    <Link
                      to={`/groups/${this.props.params.groupId}/report/${this.props.params.reportId}/`}
                      className={`main-nav-link ${dashboardClassName}`}
                      title="Dashboards"
                    >
                      Dashboards
                    </Link>
                  </li>

                  <li className="menu-item" key="docs">
                    <Link
                      to={`/groups/${this.props.params.groupId}/report/${this.props.params.reportId}/methodologies`}
                      activeClassName="is-active"
                      className="main-nav-link"
                      onClick={() =>
                        ReactGA.event({
                          category: "Menu",
                          action: "Click",
                          label: "Documents"
                        })
                      }
                      title="Resources"
                    >
                      Resources
                    </Link>
                  </li>

                  <li className="menu-item" key="access-management">
                    <Link
                      to={`/groups/${this.props.params.groupId}/access-management`}
                      activeClassName="is-active"
                      className="main-nav-link"
                      title="Access Management"
                    >
                      Access Management
                    </Link>
                  </li>

                  {this.isAdmin() &&
                    <li className="menu-item">
                      <Link className="menu-item" to="/admin/">
                        Admin
                      </Link>
                    </li>
                  }

                  {/* Render account menu items on mobile */}
                  {!me?.data?.firstName
                    ? ((
                        <li
                          className="menu-item"
                          onClick={() =>
                            ReactGA.event({
                              category: "Header",
                              action: "Click",
                              label: "Name"
                            })
                          }
                          key="update"
                        >
                          <Dialog
                            title={
                              <span>
                                Update Account
                                {!this.state.changePassword && (
                                  <span
                                    className="dialog-title-button"
                                    onClick={this.handleChangePassword}
                                  >
                                    Change Password
                                  </span>
                                )}
                              </span>
                            }
                            className="edit-profile-dialog"
                            content={this.renderUpdateProfileDialog()}
                            ref={ref => (this.refDialog = ref)}
                          >
                            <a className="main-nav-link clickable">
                              {me.data.firstName} {me.data.lastName}
                            </a>
                          </Dialog>
                        </li>
                      ),
                      this.isAdmin() && adminArea ? (
                        <li className="menu-item">
                          <Link className="main-nav-link" to="/">
                            Switch to Frontend
                          </Link>
                        </li>
                      ) : this.isAdmin() && !adminArea ? (
                        <li className="menu-item">
                          <Link className="main-nav-link" to="/admin/">
                            Admin
                          </Link>
                        </li>
                      ) : null,
                      (
                        <li className="menu-item" key="logout">
                          <Link
                            to={url.logout}
                            onClick={() =>
                              ReactGA.event({
                                category: "Header",
                                action: "Click",
                                label: "Logout Logo"
                              })
                            }
                            className="main-nav-link logout"
                          >
                            <span>Logout</span>
                          </Link>
                        </li>
                      ))
                    : null}
                </ul>
              )}
            </React.Fragment>
          )}
      </React.Fragment>
    );
  };

  renderAccountMenu = () => {
    const { adminArea, me } = this.props;

    if (!me?.data?.firstName) return;

    return (
        <ul className="menu">
          <li
            className="menu-item"
            onClick={() =>
              ReactGA.event({
                category: "Header",
                action: "Click",
                label: "Name"
              })
            }
            key="update"
          >
            <Dialog
              title={
                <span>
                  Update Account
                </span>
              }
              className="edit-profile-dialog"
              content={this.renderUpdateProfileDialog()}
              ref={ref => (this.refDialog = ref)}
            >
              <a className="clickable">
                {me.data.firstName} {me.data.lastName}
              </a>
            </Dialog>

          </li>

          <li className="menu-item">
            <Link
              to={url.logout}
              onClick={() =>
                ReactGA.event({
                  category: "Header",
                  action: "Click",
                  label: "Logout Logo"
                })
              }
              className="logout"
            >
              <span>Logout</span>
            </Link>
          </li>
        </ul>
    );
  };

  // retrieve a single report from the list of groups
  getReportFromGroups = () => {
    const { powerBIGroups, params } = this.props;
    const { collection } = powerBIGroups;

    for (let group of collection) {
      for (let report of group.reports) {
        if (report.report_id === params.reportId) {
          return report;
        }
      }
    }
    return null;
  }

  getGroupFromGroups = () => {
    const { powerBIGroups, params } = this.props;
    const { collection } = powerBIGroups;

    for (let group of collection) {
      if (group.group_id === params.groupId) {
        return group;
      }
    }
    return null;
  }

  renderProductMenu = () => {
    const { products, product, params, powerBIGroups } = this.props;

    if (!params.reportId || powerBIGroups.isLoading) return;

    const foundReport = this.getReportFromGroups();
    const foundGroup = this.getGroupFromGroups();

    if (!foundReport || !foundGroup) return;

    return (
      <ul
        className={`menu product-menu`}
        onClick={this.handleToggleProductMenu}
      >
        <li className={`menu-item ${this.state.activeProductMenu ? "open-submenu" : ""}`}>
          <span className="product-menu-current-label">Selected Product</span>
          <span className="product-menu-current-title">{foundReport.name}</span>
          <ul className="sub-menu">
            {_.map(powerBIGroups.collection, group => {
              if (_.isEmpty(group.reports)) {
                return;
              }

              return (
                <li className={`menu-item`}  key={group.group_id}>
                  {group.alias}
                  <ul className="product-menu-options-submenu">
                    {_.map(group.reports, report => {
                      return (
                        <li
                          className="menu-item"
                          key={report.report_id}
                        >
                          <Link to={`/groups/${group.group_id}/report/${report.report_id}`}>
                            {report.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </li>
    </ul>
    );
  };

  render() {
    const { adminArea, params } = this.props;
    const { activeMenu } = this.state;

    const hamburgerClass = activeMenu === true ? "is-active" : "";

    return (
      <header className="header" role="banner">
        <div className="nav-wrapper">
          <div className="header-inner">
            <div className="header-logo">
              <a href="https://www.esgdc.org/">
                <img
                  className="header-logo-image"
                  src="/images/Header_logo_Tagline.svg"
                />
              </a>
              <a href="https://www.expandresearch.com">
                <img className="header-logo-image secondary" alt="Expand Research" src={`/images/expand-logo-light.svg`} />
              </a>
            </div>
            {!adminArea && params.productId && (
              <span
                className={`hamburger ${hamburgerClass}`}
                onClick={this.handleToggleMenu}
              >
              <span className="line-1" />
              <span className="line-2" />
              <span className="line-3" />
            </span>
            )}

            <nav className="main-nav">
              {this.renderProductMenu()}
              {this.renderMainMenu()}
            </nav>

          </div>
        </div>
        <div className="account-area hide-on-mobile">
          <div className="menu-wrapper">
            {this.renderAccountMenu()}
          </div>
        </div>
      </header>
    );
  }
}
