import { createSelector } from 'reselect';

export const getAllPowerBIGroups = (state) => {
  console.log('State in selector:', state);
  return state.allPowerBiGroups;
};

export const makeGetAllPowerBIGroups = () => createSelector([getAllPowerBIGroups], (obj) => {
  console.log('Selector output:', obj);
  return obj;
});
