import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Table } from '@xandanet/react-components';
import { fn, api } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import * as selector from './selector';
import ReactGA from "react-ga";

const setActiveId = id => (state, props) => { return { itemOnHover: id }; };

@connect((state) => {
	const getReports = selector.makeGetReports();
	return {
		reports: getReports(state),
	};
})

export default class List extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 1
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		this.props.dispatch(fetchData({
			type: 'REPORT',
			url: `/report?page=${this.state.currentPage}&product_id=${this.props.params.productId}`,
		}));
	}

	handlePageChange = (currentPage) => {
		this.setState({ currentPage }, this.fetchData);
	}

	handleDownload = async (e, report) => {
		e.preventDefault()

		const url = await api.get(report.url, {responseType: 'blob'})
		const downloadUrl = window.URL.createObjectURL(new Blob([url.data]))
		const link = document.createElement('a')
		link.href = downloadUrl
		link.setAttribute('download', report.original_file_name)
		document.body.appendChild(link)
		link.click()

		const { reports } = this.props

		ReactGA.event({
			category: 'Reports',
			action: 'Download',
			label: `Report downloaded`
		})
	}

	render() {
		const {
			reports
		} = this.props

		return (
      <div className="page-wrap">
        <div className="centering">
          <div className="page-header">
            <h1>Reports</h1>
          </div>
				<section className="list reports">
					<ContentLoader
						data={reports.currentCollection}
						isLoading={reports.isLoading}
						noResults="No reports can be found."
						/*
						pagination={{
							// reports.pager.currentPage,
							onPageChange: this.fetchData,
							total: reports.pager.total,
						}}*/
					>
						<span className="pagination-status">(Showing items {reports.pager.to} of {reports.pager.total})</span>
						<span className="report-wrapper grid">
							{reports.currentCollection.map((id) => {
								const report = reports.collection[id];
								return (
									<article className="grid-3">
										<div className="report box">
											<div className={`report-inner`} key={id} onClick={(e) => this.handleDownload(e, report)}>
												<span className={`report-icon type-${report.type}`}></span>
												<span className="report-mid-content">
													<span className="report-type">{_.find(fn.getReportTypes(), {'id': report.type}).title}</span>
													<h2 className="report-title">{report.title}</h2>
												</span>
												<span className="report-low-content">
													<span className="report-date">{fn.formatDate(report.created_at)}</span>
													<span className="report-download">Download</span>
												</span>
											</div>
										</div>
									</article>
								);
							})}
						</span>
					</ContentLoader>
				</section>
        </div>
      </div>
		);
	}

}
