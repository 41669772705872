import { api } from 'app/utils';

const defaultState = {
  collection: {},
  count: null,
  currentCollection: [],
  error: null,
  filters: [],
  isLoading: true,
  misc: {},
  pager: {},
};

export default function reducer(state = defaultState, action) {
  switch	(action.type) {
    case 'ALL_POWER_BI_GROUPS_PENDING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'ALL_POWER_BI_GROUPS_REJECTED': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
      };
    }
    case 'ALL_POWER_BI_GROUPS_FULFILLED': {
      return {
        ...state,
        isLoading: false,
        collection: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
