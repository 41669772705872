import React from 'react';
import { Link } from 'react-router';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { Dialog, Form, TextInput } from '@xandanet/react-components';

export default class AdminHeader extends React.PureComponent {

	getDialogContent = () => (
		<Form onSubmit={this.handleSubmit} className="edit-profile-form">
			<TextInput name="firstName" label="First Name" validation="required" onChange={this.handleInputChange} className="input-main ghost-input" />
			<TextInput name="lastName" label="Last Name" validation="required" onChange={this.handleInputChange} className="input-main ghost-input" />
			<TextInput name="email" label="Email" validation="required|email" onChange={this.handleInputChange} className="input-main ghost-input" />
			<TextInput name="bank" label="Bank" validation="required" onChange={this.handleInputChange} className="input-main ghost-input" />
			<div className="form-spacing" />
			<TextInput name="password" label="Change Password" type="password" validation="required" onChange={this.handleInputChange} className="input-main ghost-input" />
			<TextInput name="passwordConfirm" label="Confirm New Password" type="password" validation="required" onChange={this.handleInputChange} className="input-main ghost-input" />
			<span className="buttonWrapper wide"><button className="button">save</button></span>
		</Form>
	)

handleInputChange = (name, value) => this.setState({ [name]: value });

handleSubmit = () => {
	console.warn('Attempted to submit form data but action is not implemented');
}

render() {
	const {
		me,
		loggedIn
	} = this.props;

	return (
		<header className="site-header" role="banner">
			<div className="site-header-inner">
				<div className="header-logo">
					<Link to="/">
						<img className="logo" src="/images/Header_logo_Tagline.svg" />
					</Link>
				</div>
				{this.props.loggedIn &&
				<React.Fragment>
					<nav className="main-nav" id="main-nav" role="navigation">
						<div className="menu">
							<Link to="/admin/pages" activeClassName="is-active" className="main-nav-link"><div className="menu-item">Pages</div></Link>
							<Link to="/admin/resources" activeClassName="is-active" className="main-nav-link"><div className="menu-item">Methodologies</div></Link>
						</div>

						<table className="account-nav">
							<tbody>
								<tr>
									<td><Dialog title="Update Account" className="edit-profile-dialog" content={this.getDialogContent()}><a className="clickable" onClick={() => {}}>{me.data.firstName} {me.data.lastName}</a></Dialog></td>
									<td>
										<Link to={url.logout} className="nav-logout">
											<span>Logout</span>
											<span><img src="/images/Header_icon_logout.png" /></span>
										</Link>
									</td>
								</tr>
							</tbody>
						</table>
					</nav>
				</React.Fragment>
				}
			</div>
		</header>
	);
}

}
