import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginTop: "0px",
    marginLeft: "12px",
  },
}));

export default function AdditionalInformation(props) {
  const classes = useStyles();
  const [requestForm, setRequestForm] = props.formData;

  const [deanonymization, setDeanonymization] = useState(
    requestForm.approver_deanonymization
  );
  const [sfdrPai, setSfdrPai] = useState(requestForm.approver_sfdr_pai);

  const handleDeanonymizationChange = (event) => {
    const checked = event.target.checked;
    setDeanonymization(checked);
    setRequestForm((form) => ({ ...form, approver_deanonymization: checked }));
  };

  const handleSfdrPaiChange = (event) => {
    const checked = event.target.checked;
    setSfdrPai(checked);
    setRequestForm((form) => ({ ...form, approver_sfdr_pai: checked }));
  };

  return (
    <>
      <Typography gutterBottom style={{ padding: "10px", fontSize: "12px" }}>
        Additional Information
      </Typography>
      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              checked={deanonymization}
              onChange={handleDeanonymizationChange}
              color="primary"
            />
          }
          label="Company Names"
        />
        <Typography style={{ paddingLeft: "12px", fontSize: "12px" }}>
          By granting access, you are giving approval for your EDCI data to be
          shared alongside real names of funds or portfolio companies (if
          uploaded into the EDCI dataset). This is what the majority of LPs are
          requiring when requesting data. If you do not select this option, the
          funds and portfolio companies will be shared alongside arbitrary IDs.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={sfdrPai}
              onChange={handleSfdrPaiChange}
              color="primary"
            />
          }
          label="SFDR PAI"
        />
        <Typography style={{ paddingLeft: "12px", fontSize: "12px" }}>
          By granting access to the SFDR Principal Adverse Impact (PAI) data,
          you are giving approval for the SFDR PAI indicators (if uploaded into
          the EDCI dataset) to be shared alongside your EDCI data.
        </Typography>
      </FormGroup>
    </>
  );
}
