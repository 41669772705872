import React from 'react';
import {api} from 'app/utils';

import {DragSource} from 'react-dnd';

const itemSource = {
    beginDrag(props) {
        return props.image;
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }

        props.handleAdd(props.image.id);
    }
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

class CarouselImage extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            url: ''
        }
    }

    async componentDidMount() {
        const {image} = this.props
        const response = await api.get(image.url, {responseType: 'arraybuffer'})
        const prefix = "data:" + response.headers["content-type"] + ";base64,"
        const base64 = btoa(
            new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            )
        )
        this.setState({url: prefix + base64})
    }

    removeDocument = () => {
        const {documents, moveDocument, image} = this.props;
        const existingDocument = _.remove(documents, (curDocument) => {
            return image.id !== curDocument.id;
        })
        moveDocument(existingDocument);
    }

    render() {
        const {url} = this.state;
        const {
            images,
            image,
            documents,
            selected,
            connectDragSource,
            connectDragPreview,
            fullscreen,
            handleAdd
        } = this.props;

        const isSelected = _.find(documents, (curImage) => curImage.id === image.id);

        const img = new Image();
        img.src = '/images/green.png';
        connectDragPreview(img)

        if (!url) {
            return null;
        }

        return (
            connectDragSource(
                <section>
                    {fullscreen ?
                        isSelected ?
                            <div className={`carousel-header remove ${selected ? ' selected' : ''}`}>
                                <button onClick={this.removeDocument}>
                                    <i className="icon ion-star"></i> Remove slide from your document
                                </button>

                                <button onClick={this.props.closeFullScreen}
                                        className="text-white">
                                    Close <i className="icon ion-close-round"></i>
                                </button>
                            </div>
                            :
                            <div className={`carousel-header ${selected ? ' selected' : ''}`}>
                                <button onClick={() => handleAdd(image.id)}>
                                    <i className="icon ion-ios-star-outline"></i> Add slide to your document
                                </button>

                                <button onClick={this.props.closeFullScreen}>
                                    Close <i className="icon ion-close-round"></i>
                                </button>
                            </div>
                        :
                        ''}

                    <div className={`carousel-image ${selected && "carousel-image-selected"}`}>
                        <img src={url}/>
                    </div>
                </section>
            )
        )
    }
}

export default DragSource('image', itemSource, collect)(CarouselImage);
