import React from 'react';
import { Link } from 'react-router';
import { Form, TextInput } from '@xandanet/react-components';
import { api, fn } from 'app/utils';
import { url } from 'app/constants';
import { SiteHeaderLogo, PageTitle } from 'app/components';
import ReactGA from "react-ga";

export default class ForgotPassword extends React.PureComponent {
	constructor(props) {
		super(props);

	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleSubmit = async () => {
		this.setState({ submitting: true })
		const formData = new FormData();
		formData.append('email', this.state.email);
		const response = await api.post('/account/reset-password', formData);

		if (api.error(response)) {
			fn.showNotification('Something went wrong. Please try again.', 'error')
		} else {
			//add reset password event
			ReactGA.event({
				category: 'Authorization',
				action: 'Load',
				label: `Reset password`
			})
			fn.navigate(url.login + '?reset=1');
		}
	}

	render() {
		return (
			<div className="login-page-wrap">
        <SiteHeaderLogo altColour={true} />
        <div className="page-content">
          <div className="page-content-inner">
            <div className="page-header">
              <PageTitle>Reset Password</PageTitle>
            </div>
            <div>
              <div className="form-description">
                <p>Enter the email you registered with and we'll send you a link to create a new password.</p>
              </div>
              <Form onSubmit={this.handleSubmit}>
                <TextInput wide name="email" label="Email" validation="required|email" onChange={this.handleInputChange} className="input-main"/>

                <div className="form-submit">
                  <button className="button-primary" type="submit">Submit</button>
                </div>

              </Form>
              <div className="action-forgotten-password">
                <Link to={url.login}> Back to Log In </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
		);
	}

}
