import React from 'react';

export default class SiteHeaderLogo extends React.PureComponent {
  render() {
    const esgdcLogo = this.props.altColour ? "logo_edcp_dark_blue.svg" : "Header_logo_Tagline.svg"

    return (
      <div className="logo-wrapper centering">
        <a href="https://www.esgdc.org/">
          <img className="logo" alt="ESG Data Convergence Initiative" src={`/images/${esgdcLogo}`} />
        </a>
        <a href="https://www.expandresearch.com">
          <img className="logo secondary" alt="Expand Research" src={`/images/expand-logo-dark.svg`} />
        </a>
      </div>
    );
  }
}
