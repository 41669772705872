import React from "react";
import { withStyles, makeStyles, formatMs } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { url } from "../../../../constants";
import { fn, api } from "app/utils";
import axios from "axios";
import {
  Backdrop,
  CircularProgress,
  DialogContentText,
  Grid,
} from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer,
    color: "#fff",
  },
}));

export default function RevokeDataAccessRequest(props) {
  /// Dialog
  const [open, setOpen] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [submitInProgress, setSubmitInProgress] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /// Select
  const classes = useStyles();

  /// Snackbar
  const [sbState, setSbState] = React.useState({
    openSb: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });
  const { vertical, horizontal, openSb } = sbState;

  let requestErrorMsg = "";
  let submitSuccess = false;
  const setSubmitSuccess = (g) => {
    submitSuccess = g;
  };

  const handleClickSb = (newState, requestHandler) => async () => {
    if (!submitInProgress) {
      await requestHandler();
    }

    if (submitSuccess) {
      setSbState({
        severity: "success",
        message: "Access Revoked Successfully",
        openSb: true,
        ...newState,
      });
      setTimeout(() => {
        props.onSubmitHandler();
      }, 2000);
    } else
      setSbState({
        severity: "error",
        message: "Failed: " + requestErrorMsg + ".",
        openSb: true,
        ...newState,
      });
    setConfirmDialogOpen(false);
    setOpen(!submitSuccess);
  };
  const handleCloseSb = () => {
    setSbState({ ...sbState, openSb: false });
  };

  const patchRequestRevoke = async () => {
    setSubmitInProgress((x) => !x);

    let data = JSON.stringify({
      user_type: props.prefilledForm.requester_type,
      user_CompanyName: props.prefilledForm.requester_company,
      approvedAssets: props.prefilledForm.approver_gp_selected_assets,
      gp_CompanyName: props.prefilledForm.approver_gp_company,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url:
        url.da_api_base +
        "/da_request_revoke?id=" +
        props.prefilledForm.da_request_id,
      headers: {
        "Content-Type": "application/json",
        //Authorization: "Bearer " + props.token,
      },
      data: data,
      withCredentials: false,
    };

    try {
      const resp = await axios.request(config);

      if (resp.status === 200) setSubmitSuccess(true);
    } catch (error) {
      console.log(error);
      requestErrorMsg = error.response.data;
      setSubmitSuccess(false);
    }

    setSubmitInProgress(false);
  };

  return (
    <div>
      <Button color="primary" variant="outlined" onClick={handleClickOpen}>
        {"VIEW REQUEST"}
      </Button>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Data Access Request
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            <Grid style={{ padding: "10px", fontSize: "12px" }} container>
              <Grid item xs={4}>
                <p>
                  <b>Requester: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>{props.prefilledForm.requester_company}</p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Requester Type: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>{fn.renderUserType(props.prefilledForm.requester_type)}</p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Date Created: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {fn.formatDate(props.prefilledForm.created_at) +
                    " (" +
                    fn.prettyDate(props.prefilledForm.created_at) +
                    ")"}
                </p>
              </Grid>
              {props.prefilledForm.requester_notes !== "" && (
                <>
                  <Grid item xs={4}>
                    <p>
                      <b>Requester Notes: </b>
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <p>{props.prefilledForm.requester_notes}</p>
                  </Grid>
                </>
              )}
              <Grid item xs={4}>
                <p>
                  <b>Approved Date: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {fn.formatDate(props.prefilledForm.approved_at) +
                    " (" +
                    fn.prettyDate(props.prefilledForm.approved_at) +
                    ")"}
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Approved Assets: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {props.prefilledForm.approver_gp_selected_assets.join(", ")}
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Approved Level: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {props.prefilledForm.approver_gp_access_levels.toUpperCase()}
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Approved KPIs: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <ol style={{ marginLeft: "-2em", marginTop: "0px" }}>
                  {props.prefilledForm.approver_gp_selected_kpis.map((kpi) => (
                    <li>{kpi.toUpperCase()}</li>
                  ))}
                </ol>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Company Names: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {props.prefilledForm.approver_deanonymization
                    ? "Granted"
                    : "Not Granted"}
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>SFDR PAI:</b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {props.prefilledForm.approver_sfdr_pai
                    ? "Granted"
                    : "Not Granted"}
                </p>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            Back
          </Button>
          <Button
            onClick={() => {
              setConfirmDialogOpen(true);
            }}
            className={classes.button}
            color="secondary"
            variant="contained"
          >
            REVOKE
          </Button>
        </DialogActions>
      </Dialog>
      {confirmDialogOpen && (
        <>
          <Dialog open={confirmDialogOpen}>
            <DialogTitle>Confirm Revoke Access</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>
                Are you sure you want to Revoke this Access?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setConfirmDialogOpen((x) => !x);
                }}
                className={classes.button}
              >
                No
              </Button>
              <Button
                onClick={handleClickSb(
                  {
                    vertical: "top",
                    horizontal: "right",
                  },
                  patchRequestRevoke
                )}
                className={classes.button}
                color="secondary"
                variant="contained"
              >
                YES
              </Button>
            </DialogActions>
            <Backdrop className={classes.backdrop} open={submitInProgress}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Dialog>
        </>
      )}
      <Snackbar
        open={openSb}
        autoHideDuration={6000}
        onClose={handleCloseSb}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleCloseSb} severity={sbState.severity}>
          {sbState.message}
        </Alert>
        {/* <Alert onClose={handleCloseSb} autoHideDuration={6000} severity="error">This is an error message!</Alert> */}
      </Snackbar>
    </div>
  );
}
