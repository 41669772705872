import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Table } from '@xandanet/react-components';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import * as selector from 'app/containers/report/selector';
import { makeGetProducts, makeGetProductOptions } from 'app/containers/product/selector';

@connect((state) => {
	const getReports = selector.makeGetReports()
	const getGroups = selector.makeGetGroups()
	const getProducts = makeGetProducts()
	const getProductOptions = makeGetProductOptions()

	return {
		reports: getReports(state),
		productOptions: getProductOptions(state),
		products: getProducts(state),
		groups: getGroups(state)
	}
})

export default class List extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 1
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const queryArgs = {
			'page': this.state.currentPage,
			'status': '1',
			'orderby': 'created',
			'order': 'desc'
		}
		this.props.dispatch(fetchData({
			type: 'REPORT',
			url: `/admin/report${fn.buildUrlQuery(queryArgs, '?')}`,
		}))

		this.props.dispatch(fetchData({
			type: 'PRODUCT',
			url: `/sisense/folders`,
		}))

		this.props.dispatch(fetchData({
			type: 'GROUP',
			url: `/sisense/groups`,
		}))
	}

	handlePageChange = (currentPage) => {
		this.setState({ currentPage }, this.fetchData);
	}

	render() {
		const {
			reports,
			groups,
			productOptions,
		} = this.props;

		return (
			<div className="page-wrap with-header">
				<div className="centering">
					<div className="page-header">
						<h1>Reports</h1>

						<div className="page-header-actions">
							<Link to="/admin/reports/add" className="button">Add New</Link>
						</div>
					</div>

						<div className="box-wide">
					<ContentLoader
						data={reports.currentCollection}
						isLoading={reports.isLoading}
						noResults="No reports can be found."
					>
							<span className="pagination-status">(Showing items {reports.pager.to} of {reports.pager.total})</span>
							<table className="list-table">
								<thead>
									<tr>
										<th className="col-title">Title</th>
										<th className="col-permissions">Products</th>
										<th className="col-permissions">Groups</th>
										<th className="col-date">Created</th>
									</tr>
								</thead>
								<tbody>
									{_.map(reports.collection, (report, id) => {
										return (
											<tr key={`report${id}`}>
												<td><Link to={`/admin/reports/${report.id}`}>{report.title}</Link></td>
												<td><Link to={`/admin/reports/${report.id}`}>
												{report.product_ids ? (
													<ul className="inline">
														{_.map(report.product_ids, (id) => {
															const productOption = _.find(productOptions, {'id' : id})
															if((productOption || {}).title){
														        return <li>{productOption.title}</li>
														    }
														})}
													</ul>
												) : (
													<span dangerouslySetInnerHTML={{__html: `&ndash;`}}></span>
												)}
												</Link></td>
												<td><Link to={`/admin/reports/${report.id}`}>
												{report.group_ids ? (
													<ul className="inline">
														{_.map(report.group_ids, (id) => {
															const sisenseGroup = _.find(groups.data, {'_id' : id})
															if((sisenseGroup || {}).name){
														        return <li>{sisenseGroup.name}</li>
														    }
														})}
													</ul>
												) : (
													<span dangerouslySetInnerHTML={{__html: `&ndash;`}}></span>
												)}
												</Link></td>
												<td><Link to={`/admin/reports/${report.id}`}>{fn.formatDate(report.created_at)}</Link></td>
											</tr>
										)
									})}
								</tbody>
							</table>
					</ContentLoader>
					</div>
				</div>
			</div>
		);
	}

}
