import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Dialog, Form, Loader, TextInput } from "@xandanet/react-components";
import { api, fn } from "app/utils";
import { fetchData } from "app/actions";
import { url, cookieDomain } from "app/constants";
import { PageTitle, SiteHeaderLogo } from "app/components";
import * as selector from "./selector";
import ReactGA from "react-ga";

@connect((state, ownProps) => {
  const getLoginFactors = selector.makeGetLoginFactors();
  const getActiveLoginFactor = selector.makeGetActiveLoginFactor();

  return {
    loginFactors: getLoginFactors(state),
    activeLoginFactor: getActiveLoginFactor(state)
  };
})
export default class Login extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			submitting: false
		}
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value, wrongPassword: false });
	}

	handleSubmit = async () => {
		let response, error;
		this.setState({
			submitting: true,
			wrongPassword: false
		})

		const formData = new FormData();
		formData.append('email', this.state.email)
		formData.append('password', this.state.password)

		response = await api.post('/account/login', formData);
		fn.hideNotification();
		if (!api.error(response)) {
      if(true === response.data.password_expired){
        return fn.navigate({
            pathname:`/login/password-expired`,
            state: {user_id: response.data.user_id}
        })
      }

	  console.log(response.data.jwt_token, cookieDomain);
      fn.saveCookie("jwt_token", response.data.jwt_token, {
        path: "/",
        domain: cookieDomain
      });

			await this.fetchFactors()

			// redirect to the activated factor
			if(_.isEmpty(this.props.activeLoginFactor)){
				fn.navigate('/login/verify')
			} else {
				fn.navigate({pathname:`/login/verify/${this.props.activeLoginFactor.factorType}/validate`, state: {response: this.props.activeLoginFactor}})
			}

		} else {
			this.setState({ wrongPassword: true })
		}
		this.setState({submitting: false})
	}

	fetchFactors = () => {
		return this.props.dispatch(fetchData({
			type: 'LOGINFACTOR',
			url: 'account/login/factors'
		}))
	}

	render() {
		return (
			<div className="login-page-wrap">
        <SiteHeaderLogo altColour={true} />

        <div className="page-content">
          <div className="page-content-inner">
            <div className="page-header">
              <PageTitle>Log In</PageTitle>
            </div>
            {this.props.location.query.reset &&
              <div className="form-description">
                <p>A password reset link has been sent to your email. Please follow the instructions and return here.</p>
				  <p>Not receiving the email? Please reach out to us at <a href="mailto:info@esgdc.org">info@esgdc.org</a></p>
              </div>
            }
            <Form onSubmit={this.handleSubmit}>
              <TextInput wide name="email" label="Email" validation="required|email" onChange={this.handleInputChange} className="input-main" value="" autoFocus/>
              <TextInput wide name="password" label="Password" validation="required" type="password" onChange={this.handleInputChange} className="input-main" value=""/>

              <div className="form-submit">
                <button className="button-primary" >
                  {!this.state.submitting
                    ? <span>Log In</span>
                    : <Loader />
                  }
                </button>
                {this.state.wrongPassword &&
                  <div className="warning-text">Wrong password. Please try again.</div>
                }
              </div>

              <div className="action-forgotten-password">
                <Link to={url.forgotPassword}> Forgotten your password? </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
		);
	}
}
