import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  Typography,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import SelectLP from "./selectLP";
import SelectFunds from "./selectFunds";
import SelectKPIs from "./selectKPIs";
import SubmitApprove from "./submitDataAccess";
import AdditionalInformation from "./AdditionalInformation";
import { url } from "../../../../constants";
import axios from "axios";
import { fn } from "app/utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const preApprovedKPIs = [
  "womenInCSuite",
  "workRelatedAccidents",
  "totalNetNewHires",
  "organicNetNewHires",
  "scope1Emissions",
  "scope2Emissions",
  "scope3Emissions",
  "renewableEnergyConsumption",
  "womenOnboard",
  "underrepresentedGroupsOnboard",
  "lgbtqOnboard",
  "portcosEmployeeSurvey",
  "annualTurnover",
  "employeeSurveyResponseRate",
];

export default function CreateNewDataAccess(props) {
  const [open, setOpen] = props.openControls;
  const [submitInProgress, setSubmitInProgress] = React.useState(false);
  const [requestForm, setRequestForm] = React.useState({
    userId: "",
    user_FName: "",
    user_LName: "",
    user_CompanyName: "",
    user_type: "",
    gp_userId: props.userType.userId,
    gp_FName: props.userType.firstName,
    gp_LName: props.userType.lastName,
    gp_CompanyName: props.userType.company,
    selectedAssets: "",
    approvedAssets: [],
    approverNotes: "",
    approvedAccessLevels: "",
    approvedKPIs: preApprovedKPIs,
    tcCheck: true,
    deanonymization: false,
    sfdrPai: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const [sbState, setSbState] = React.useState({
    openSb: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });
  const { vertical, horizontal, openSb } = sbState;

  let requestErrorMsg = "";
  let submitSuccess = false;
  const setSubmitSuccess = (g) => {
    submitSuccess = g;
  };

  const handleClickSb = (newState) => async () => {
    if (!requestForm.tcCheck) {
      setSbState({
        severity: "error",
        message: "Please accept Terms and Conditions to submit form" + ".",
        openSb: true,
        ...newState,
      });
      return;
    }

    if (!submitInProgress) {
      if (requestForm.selectedAssets === "")
        requestForm.selectedAssets = requestForm.approvedAssets.join(", ");
      await postRequestData();
    }

    if (submitSuccess) {
      setSbState({
        severity: "success",
        message: `Data Access Request is submitted! ${fn.renderUserType(
          requestForm.user_type
        )} will be notified via E-Mail.`,
        openSb: true,
        ...newState,
      });
      props.onSubmitHandler();
      setRequestForm({
        userId: "",
        user_FName: "",
        user_LName: "",
        user_CompanyName: "",
        user_type: "",
        gp_userId: props.userType.userId,
        gp_FName: props.userType.firstName,
        gp_LName: props.userType.lastName,
        gp_CompanyName: props.userType.company,
        selectedAssets: "",
        approvedAssets: [],
        approverNotes: "",
        approvedAccessLevels: "",
        approvedKPIs: preApprovedKPIs,
        tcCheck: true,
        deanonymization: false,
        sfdrPai: false,
      });
      setActiveStep(0);
    } else
      setSbState({
        severity: "error",
        message: "Failed: " + requestErrorMsg + ".",
        openSb: true,
        ...newState,
      });

    setOpen(!submitSuccess);
  };

  const handleCloseSb = () => {
    setSbState({ ...sbState, openSb: false });
  };

  const postRequestData = async () => {
    setSubmitInProgress((x) => !x);

    if (requestForm.approverNotes === "") requestForm.approverNotes = " ";
    let data = JSON.stringify(requestForm);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url.da_api_base + "/da_request_create",
      headers: {
        "Content-Type": "application/json",
        "X-Request-Type": "Approved",
        "X-User-Type": "GP",
        //Authorization: "Bearer " + props.token,
      },
      data: data,
      withCredentials: false,
    };

    try {
      const resp = await axios.request(config);

      if (resp.status === 200) setSubmitSuccess(true);
    } catch (error) {
      console.log(error);
      requestErrorMsg = error.response.data;
      setSubmitSuccess(false);
    }

    setSubmitInProgress(false);
  };

  function getSteps() {
    return [
      "Select User",
      "Assets",
      "KPIs",
      "Additional Information",
      "Approve",
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <SelectLP
            formData={[requestForm, setRequestForm]}
            token={props.token}
          />
        );
      case 1:
        return (
          <SelectFunds
            formData={[requestForm, setRequestForm]}
            token={props.token}
          />
        );
      case 2:
        return (
          <SelectKPIs
            formData={[requestForm, setRequestForm]}
            token={props.token}
          />
        );
      case 3:
        return (
          <AdditionalInformation
            formData={[requestForm, setRequestForm]}
            token={props.token}
          />
        );
      case 4:
        return <SubmitApprove formData={[requestForm, setRequestForm]} />;
      default:
        return "Unknown step";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="paper"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Data Access Request
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleClickSb({
                  vertical: "top",
                  horizontal: "right",
                })}
                className={classes.button}
              >
                Approve
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button>
            )}
          </div>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={submitInProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <Snackbar
        open={openSb}
        autoHideDuration={6000}
        onClose={handleCloseSb}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleCloseSb} severity={sbState.severity}>
          {sbState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
