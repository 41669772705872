import { createSelector } from 'reselect'
import _ from 'lodash'

const getPages = state => state.page
const getPage = (state, id) => state.page.collection[id] || {}
const getPageBySlug = (state, slug) => _.find(state.page.collection, {'slug': slug}) || {}

export const makeGetPageBySlug = () => createSelector([getPageBySlug], obj => obj)
export const makeGetPages = () => createSelector([getPages], obj => obj)
export const makeGetPage = () => createSelector([getPage], obj => obj)
