import React, { useCallback, useState } from "react";
import { url } from "../../constants";
import { fn, api } from "app/utils";
import LP_Dashboard from "./lp/dashboard";
import GP_Dashboard from "./gp/dashboard";
import axios from "axios";
import {
  Backdrop,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PC_Dashboard from "./pc/dashboard";
import TCDialog from "./TCDialog";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function View(props) {
  const [userType, setUserType] = useState({
    userId: props.me.data.email,
    firstName: props.me.data.firstName,
    lastName: props.me.data.lastName,
    type: "",
    company: "",
    tcAcceptedDate: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [tcNotAccepted, setTcNotAccepted] = useState(false);
  const [userNotAllowed, setUserNotAllowed] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const token = fn.getCookie("jwt_token");
  const classes = useStyles();

  const getUserType = useCallback(async () => {
    console.log(token, userType);
    setIsLoading(true);
    const baseURL = url.da_api_base + "/da_get_usertype";
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "X-User-Id": userType.userId,
      },
    };
    console.log("Request config:", config);
    try {
      if (userType.userId) {
        const response = await axios.request(config);

        if (response.data.Count > 0) {
          config.url = url.da_api_base + "/da_get_user_metadata";
          const userMetadataResp = await axios.request(config);

          setUserType((ut) => ({
            ...ut,
            type: response.data.Items[0].type,
            company: response.data.Items[0].company,
          }));

          if (
            userMetadataResp.data.Count > 0 &&
            userMetadataResp.data.Items[0].acceptance_date !== ""
          ) {
            setUserType((ut) => ({
              ...ut,
              tcAcceptedDate: userMetadataResp.data.Items[0].acceptance_date,
            }));
          } else {
            setTcNotAccepted(true);
            setOpenDialog(true);
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
          setUserNotAllowed(true);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoadingError(true);
    }
    return false;
  }, []);

  React.useEffect(() => {
    getUserType().catch();
  }, [getUserType]);

  const renderUserView = () => {
    switch (userType.type) {
      case "LP":
        return <LP_Dashboard userType={userType} token={token} />;

      case "PC":
        return <PC_Dashboard userType={userType} token={token} />;

      case "GP":
        return <GP_Dashboard userType={userType} token={token} />;
    }
  };
  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {userNotAllowed && (
        <Typography className="page-wrap centering">
          User not assigned Access Management Rights
        </Typography>
      )}
      {isLoadingError && !isLoading && (
        <h3>An error has occurred while Loading dashboard...</h3>
      )}

      {/* Conditionally rendering views or the T&C dialog */}
      {!isLoading ? (
        tcNotAccepted ? (
          <TCDialog open={openDialog} userType={userType} token={token} />
        ) : (
          renderUserView() // This is called only if not loading and T&C are accepted
        )
      ) : null}
    </>
  );
}
