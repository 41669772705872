import React from 'react';
import { Link } from 'react-router';
import { Dialog, Form, Loader, TextInput } from '@xandanet/react-components';
import { api, fn } from 'app/utils';
import { url } from 'app/constants';
import { PageTitle, SiteHeaderLogo } from 'app/components';
import ReactGA from 'react-ga'

export default class TermsConditions extends React.PureComponent {
    constructor(props) {
		super(props);
    }

    handleAccept = async () => {
        const { me } = this.props

        const formData = new FormData()
        formData.append('terms_and_conditions', 1)

        const response = await api.update(`/account/`, formData)

        if(!api.error(response)){
            this.props.dispatch({
                type: 'ME_FULFILLED',
                payload: response,
            })

            ReactGA.event({
                category: 'Terms and Conditions',
                action: 'Accepted',
                label: `User terms accepted`
            })
        }

    }

	render() {
		return (
      <div className="login-page-wrap">
        <SiteHeaderLogo altColour={true} />
        <div className="page-content">
        <div className="centering tcs-spacing">
            <div className='biginfo-wrapper box tcs-wrapper'>
                <div className="biginfo-title">
                    <PageTitle alt>Terms & Conditions</PageTitle>
                </div>
                <div className="biginfo-subtitle"><strong>BEFORE USING THE EXPAND RESEARCH ONLINE PLATFORM (THE PLATFORM), PLEASE READ THESE TERMS OF USE CAREFULLY (THESE TERMS)</strong></div>
                <div className="biginfo-content">
                    <div className="biginfo-content-inner">
                        <p>After these Terms have been accepted they shall be binding on the User and subsequent Authorized Users may be asked to accept these Terms. Any subsequent Acceptance will not create separate contracts between the User and Expand except insofar as these Terms have changed in which case the first Authorized User to tick the accept box will bind the User to the updated Terms.  However, by clicking “Accept” or otherwise confirming acceptance with these Terms (including by way of using the Platform), each Authorized User shall be bound by these Terms individually and these Terms may therefore be enforced directly against each such Authorized User by Expand.</p>

                        <p>This Platform is owned and operated by Expand Research LLP and its licensors and Affiliates.  Expand Research LLP is a wholly owned Affiliate of The Boston Consulting Group, Inc. References to Expand, User, Authorized Users, and other capitalised terms used but not defined in these Terms have the meaning set out in Section 16 of the “Definitions” set out below.</p>
                        <h2>GENERAL TERMS</h2>
                        <p>The Platform is not intended to be a means for User and other users to coordinate their commercial behavior in the market in view of fixing prices, quantities, and allocating costumers or markets, nor as a forum for the exchange of confidential or otherwise commercially sensitive company-specific information.</p>
                        <p>It is the responsibility of the User to comply with competition law relying on its own legal advice; it is not the responsibility of Expand or its staff to ensure that User or other users comply with competition law in their own activities.</p>
                        <h3>1          Login Details and security</h3>
                        <p>1.1          The User will instruct its Authorized Users not to provide the Login Details to (or otherwise permit access to the Platform by) any person other than its Authorized Users, and accepts full responsibility at all times for any and all of their use of the Platform and violation of these Terms. The User will also take responsibility for any unauthorized access to or use of the Platform where, by its acts or omissions, its Login Details are disclosed to person(s) other than its Authorized Users.</p>
                        <p>1.2       Expand will be under no obligation to ascertain whether any person using the Login Details to access or use the Platform is properly authorized by the User, and will be entitled to assume, without enquiry, that any such person is an Authorized User and their access or use of the Platform is properly authorized by the User.1.3       The User will ensure all Authorized Users understand how to use the Platform and are appropriately supervised.1.4       The User and each Authorized User will keep all Login Details secret and will implement and maintain adequate security measures to prevent access to the Platform by any person other than an Authorized User and User will ensure compliance the same in respect of its Authorized Users.1.5     If Expand becomes aware, or suspects that any unauthorized person has obtained or has attempted to obtain unauthorized access to, or there is misuse of, the Platform or Login Details, then in addition to any other rights or remedies available under these Terms it may take such steps as it deems appropriate to change the Login Details and/or the security of the Platform. The User and any Authorized User will notify Expand immediately in writing or by telephone if it becomes aware of any such actual or potential misuse or unauthorized access.</p>
                        <h3>2          User Systems and the Platform</h3>
                        <p>2.1       The User will be responsible (at its own cost) for:<br/>
                        (a) the selection, provision, maintenance and support of the User Systems;<br/>
                        (b) co-operating with Expand in all matters relating to the Platform; and<br/>
                        (c) ensuring that the User Systems are at all times suitable for use with the Platform.</p>
                        <p>2.2       Expand is not responsible in any way for the configuration of the User Systems, the interoperability or reliability of the connection between the User Systems and the Platform and is not responsible for any communication failures, distortions or delays when using the Platform.</p>
                        <p>2.3       Expand has sole discretion and control over, and may modify at any time in its absolute discretion: (i) the functionality, performance, configuration, appearance and content of the Platform; (ii) the availability of the Platform to any person at any particular place, time or location; (iii) security protocols or procedures applicable to the Platform or User Systems; and (iv) Expand policies and procedures in relation to accessing and using the Platform.</p>
                        <p>2.4       The User understands and acknowledges that the Platform response times may vary due to external conditions, Platform performance, access device location or other factors, and that access to the Platform may be limited or unavailable during periods of peak demand, systems upgrades, routine or emergency maintenance, Force Majeure Events or for other reasons. Expand does not warrant the performance or availability of the Platform.</p>
                        <p>2.5       By accepting these Terms, the User and each Authorized User will also be deemed to accept and agrees to be subject to what is stated in any legal notices, guidelines, rules or other terms or conditions displayed or posted (including by links) on the Platform that are expressly stated as superseding particular provisions of these Terms or that supplement these Terms. By accepting the Privacy Policy, the User and each Authorized User will be deemed to accept and agrees to be subject to the Privacy Policy. These Terms hereby incorporate by this reference the Privacy Policy and any such legal notices, guidelines, rules or other terms or conditions.</p>
                        <h3>3          Confidentiality</h3>
                        <p>3.1       User and each Authorized User will treat any Reports, data, materials or other information received from or via the Platform as confidential and will not distribute it outside the User’s organization without Expand's prior written approval.</p>
                        <p>3.2       If the User or the Authorized User communicate any comment, questions or suggestions to Expand (collectively, "Feedback") regarding changes to the Platform, including, without limitation, new features or functionality, all such Feedback is, and will be treated as, non-confidential and non-proprietary. The User hereby assigns all right, title, and interest in, and Expand is free to use, without any attribution or compensation to the User, any ideas or other intellectual property contained in the Feedback, for any purpose whatsoever. The User agrees that Expand is not obligated to use, any such ideas contained in the Feedback.</p>
                        <h3>4          Confidentiality</h3>
                        <p>4.1       User accepts and agrees that Expand may process, disclose and transfer Personal Information of the User or Authorized Users in accordance with the Privacy Policy.</p>

                        <p>4.2       The User will, and will cause its Authorized Users, not to provide any Personal Information of any person other than its Authorized Users and only to the extent required to access and use the Platform. If Expand becomes aware of any Personal Information uploaded onto the Platform in violation of this section, it may without warning delete this information. The User represents that it complies with, and will continue to comply with, all applicable privacy and data protection laws and regulations with respect to any Personal Information that the User or its Authorized User may provide to Expand directly or through the use of the Platform.</p>
                        <h3>5           Consent to the processing of Personal Information</h3>
                        <p>5.1       The Authorized User grants consent to the following Personal Information being processed for the following purposes, and as otherwise provided in the Privacy Policy:</p>
                        <ol>
                            <li>Name, address, email address, IP address, image/photography, user activity</li>
                            <li>Managing access to the Platform, monitoring usage of the Platform and providing support related to accessing and using the Platform.</li>
                        </ol>
                        <p>The Authorized User is aware that, for these purposes, his/her Personal Information may be transferred to, stored at, and/or processed by Expand, seated in the USA, and/or other Affiliates of Expand or their service providers in other countries, including outside of the European Union. This explicitly includes data transfers to the USA, which may not ensure an adequate level of protection as deemed by the European Union. The Authorized User’s consent explicitly extends to and covers these practices.</p>

                        <p>5.2       The Authorized User may revoke this consent to the extent permitted by applicable law, by sending an email to: <u><a href="mailto:benchmarkingsupport@bcg.com">dataprotectionoffice@expandresearch.com<strong>.</strong>.</a></u></p>

                        <p>5.3       The Authorized User acknowledges that Personal Information may be processed without his/her consent to the extent permitted by applicable law.</p>

                        <h3>6            Usage of the Platform</h3>
                        <p>6.1       The User and its Authorized User will only use the Platform and any Reports, data, materials or other information made available on or via the Platform strictly for internal business purposes related to the relevant project in connection with which User has been granted access to the Platform as set out in the Individual Services Agreement. The User will not use or permit the use of the Platform for any other purpose and will not use or permit use of the Platform or Platform Software for any purpose or in any manner that is unlawful. In particular, User will not share Information to coordinate its behaviour with other users in view of fixing prices or quantities or allocating costumers or market. The User will abide and cause the Authorized User to abide by all state, local, national and international laws and regulations applicable to the User and Authorized User's use of the Platform.</p>

                        <p>6.2       If the User or any Authorized User fails to comply with its obligations under section 6.2 above, Expand has the right to put limits on the User's use the Platform.</p>

                        <p>6.3       Expand will monitor the use of the Platform and may alter, remove or monitor Information submitted via the Platform. Expand has no obligation to verify the accuracy of any Information.</p>

                        <p>6.4       The User agrees that Expand does not act on behalf of User or any of its Authorized Users (or any other user or its authorized users) when providing access to or use of the Platform or related services.</p>

                        <h3>7          Prohibited Conduct</h3>
                        <p>7.1       The User and its Authorized Users may use the Platform for lawful purposes only. The User or its Authorized Users may not upload to, or distribute or otherwise publish through the Platform any content that is the following:</p>
                        <ul>
                            <li>is libelous, defamatory, obscene, pornographic, abusive, harassing or threatening, or otherwise objectionable to Expand in its sole discretion;</li>
                            <li>contains computer viruses, worms, moles or other contaminating or destructive elements;</li>
                            <li>violates the rights of others, such as content that infringes any copyright, trademark, patent, trade secret or violates any right of privacy or publicity;</li>
                            <li>contains any false or misleading statement;</li>
                            <li>contains advertising; or</li>
                            <li>otherwise violates any applicable criminal or civil law. The User may not use the Platform for any commercial purpose and may not distribute over the Platform any solicitation of funds, goods and services. In addition, the User may not use the Platform to solicit subscribers to join other online information services that are competitive with the Platform.</li>
                        </ul>
                        <h3>8          Disclaimers</h3>
                        8.1       TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW:
                        <ol>
                            <li>THE PLATFORM, PLATFORM SOFTWARE AND ANY CONNECTED AND RELATED SERVICES, SYSTEMS, INFORMATION, COMPONENTS, INTERFACES, EQUIPMENT, DOCUMENTATION, MATERIALS AND TECHNOLOGY ARE PROVIDED ON AN "AS IS," “WHERE IS” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY;</li>
                            <li>WITHOUT LIMITING THE FOREGOING, EXPAND MAKES NO REPRESENTATION OR WARRANTY WHATSOEVER THAT THE PLATFORM, PLATFORM SOFTWARE OR INFORMATION PROVIDED UNDER OR IN CONNECTION WITH THE PLATFORM WILL BE OF SATISFACTORY QUALITY, FIT FOR PURPOSE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, CORRECT, COMPLETE, ERROR-FREE, FREE FROM VIRUSES AND MALICIOUS COMPONENTS, OR COMPATIBLE WITH THE USER SYSTEMS; AND</li>
                            <li>EXPAND DISCLAIMS ALL TERMS, CONDITIONS, WARRANTIES, REPRESENTATIONS AND UNDERTAKINGS WHICH MIGHT BE IMPLIED, WHETHER BY STATUTE OR OTHERWISE, IN RESPECT OF EXPAND’S OBLIGATIONS, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE.</li>
                        </ol>
                        <p>8.2       ALL DISCLAIMERS OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THESE TERMS) ARE MADE FOR THE BENEFIT OF THE EXPAN (INCLUDING ALL AFFILIATES) AND THEIR RESPECTIVE SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS AND SERVICE PROVIDERS, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.</p>

                        <h3>9          Liability</h3>
                        <p>9.1       TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: (A) EXPAND WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND, UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY, INCLUDING DAMAGES FOR LOSS OF PROFITS, USE OR DATA, LOSS OF OTHER INTANGIBLES, LOSS OF SECURITY OF SUBMISSIONS (INCLUDING UNAUTHORIZED INTERCEPTION BY THIRD PARTIES OF ANY INFORMATION), EVEN IF ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES; (B) WITHOUT LIMITING THE FOREGOING, EXPAND WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM THE USER’S USE OF OR INABILITY TO USE THE PLATFORM OR FROM ANY PRODUCTS OR THIRD PARTY MATERIALS, INCLUDING FROM ANY VIRUS THAT MAY BE TRANSMITTED IN CONNECTION THEREWITH; AND (C) THE MAXIMUM AGGREGATE LIABILITY OF EXPAND FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL BE THE LESSER OF THE AMOUNT THE USER HAS PAID BCG OR 50,000 USD. ALL LIMITATIONS OF LIABILITY OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR THE BENEFIT OF EXPAND (INCLUDING ALL AFFILIATES) AND THEIR RESPECTIVE SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS AND SERVICE PROVIDERS, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.</p>
                        <h3>10.         Intellectual Property</h3>
                        <p>10.1     All Intellectual Property Rights relating to the Platform and the software provided to enable access to and use of the Platform (<strong>Platform Software</strong>) remains vested in Expand or its licensors and may not be used by the User or any of its Authorized Users without the prior written consent of Expand or its licensors (as applicable). Expand owns all Intellectual Property Rights pre-existing or developed under these Terms or in connection with the Platform.</p>

                        <p>10.2     Subject to these Terms, Expand grants to the User a personal, revocable, non-exclusive, non-transferable, non-sublicensable licence to access the Platform Software and to permit use of the Platform Software by its Authorized Users, in each case solely for the purposes set forth in section 6.1, for the duration of these Terms.</p>

                        <p>10.3     The User and each Authorized User will not:</p>
                        <ol>
                            <li>cause or permit the reverse engineering, disassembly or decompilation of any Platform Software;</li>
                            <li>make the Platform Software available to any third party for any purpose;</li>
                            <li>recreate or copy the Platform Software;</li>
                            <li>re-export or re-distribute the Platform Software or any part of the Platform without Expand’s prior written permission; or</li>
                            <li>use any trade names, trademarks, service marks or logos of the Expand or its licensors without the express prior written consent of Expand (and nothing contained on the Platform should be construed as granting any such right); or</li>
                            <li>scrape, copy, duplicate, strip or mine data from the Platform without Expand’s express written permission.</li>
                        </ol>
                        <p>10.4     The User and its Authorized Users will at all times keep the Platform Software and other related materials and information secure and confidential, except to the extent required by applicable law or Regulations. If the User or any Authorized User receives a disclosure order from the Regulator or other governmental authority, it will promptly notify Expand of the required disclosure, and if requested assist Expand in challenging such order, in each case to the extent not precluded from doing so by applicable law or Regulations.</p>

                        <p>10.5     The User and each Authorized User will:</p>
                        <ol>
                            <li>not modify the paper or digital copies of any Platform Software or related materials and will not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text; and</li>
                            <li>not use any part of the Platform Software or related materials for commercial purposes without obtaining a license to do so from Expand.</li>
                        </ol>
                        <h3>11.         Aggregate Data</h3>
                        <p>Expand will own and retain all rights, title and interests in and to any and all Aggregate Data, including the rights to develop, test, maintain, improve, modify, create derivative works from, distribute, and otherwise commercialize Aggregate Data.</p>
                        <p>User and each Authorized User agrees to allow Expand to remotely collect, maintain, process, and use diagnostic, technical, usage and other related information with the aim of improving the Platform for any reason and in any way Expand sees fit, including, but not limited to, product enhancements or security evaluations. Any materials or data collected by Expand used to improve the Platform or service offerings will be stored and used in a way that does not identify the source.</p>
                        <h3>12.         Suspension</h3>
                        <p>12.1     The User acknowledges that, in addition to the events described in section 2.4, the Platform may fail or otherwise not be available or be subject to incorrect or restricted functionality or performance for whatever reason, including failure of technology, power failure, failure of communications links or security breach. Expand may suspend the User’s or any Authorized User’s access to and use of the Platform at any time and without prior notice, for any or no reason, including if Expand believes that the User or any Authorized User has violated or acted inconsistently with these Terms. No course of dealing will be construed as a promise of continued or continuous availability or capability of the Platform and Expand is not responsible to the User in relation to such unavailability or for incorrect or restricted functionality or performance.</p>
                        <h3>13          Termination</h3>
                        <p>13.1     These Terms will continue in force until terminated in accordance with this Section 13.</p>

                        <p>13.2     Expand may terminate the User’s or any of its Authorized User’s access to or use of the Platform for convenience at any time with immediate effect as set out in section 13.3.</p>

                        <p>13.3     Unless required by applicable law or Regulations, these Terms will terminate with immediate effect at the earliest of:</p>
                        <ol>
                            <li>Expand removing access to the Platform; and</li>
                            <li>either party giving written notice of termination to the other party, such termination to be effective, unless otherwise specified in the notice, on receipt of such notice; and</li>
                            <li>upon expiration or termination of the Individual Services Agreement with the User.</li>
                        </ol>
                        <p>13.4     Upon any termination of these Terms, the User’s (and its Authorized User’s) right to access and use the Platform will immediately cease, and Expand may, without liability to the User or any third party, immediately deactivate or delete user names, password and accounts, and all associated materials, without any obligation to provide any further access to such materials. Any termination of access to or use of the Platform will not affect any accrued or outstanding rights or liabilities of Expand or the User which have arisen prior to or upon termination, nor will it affect the coming into force or the continuance in force of any provisions of these Terms which are expressly or by implication intended to come into force or continue in force on or after that termination including the provisions in Sections 3, 4, 8, 9, 13.4, 14, and 15.</p>
                        <h3>14.         Governing law</h3>
                        <p>These Terms, and any claim or dispute arising in connection with these Terms, the Privacy Policy or use of the Platform, are governed by and will be construed in accordance with the laws of the Commonwealth of Massachusetts, U.S.A., without regard to its principles of conflicts of law.</p>
                        <h3>15          General</h3>
                        <p>15.1     All notices must be in writing and must be hand delivered, sent by email or other electronic means, or forwarded by registered or certified mail to the relevant party and will be deemed to have been delivered on receipt at the relevant address indicated in the Individual Services Agreement.</p>

                        <p>15.2     Expand may, at any time, amend or supplement these Terms, upon written notice including via the Platform. Any such amendments or supplements will not apply to any dispute between Expand and User arising prior to the date on which Expand provided such notice.</p>

                        <p>15.3     Neither these Terms nor the matters to which they relate will be assigned by either party without the prior written consent of other, save that Expand may assign these Terms to another entity in connection with the transfer of all or part of its assets or business to a member of Expand or to a third party without the User’s prior written consent.</p>

                        <p>15.4     These Terms and the Individual Services Agreement set out the entire agreement between the parties relating to their subject matter and override any prior correspondence or representations. In the event of a conflict between these Terms and the Individual Services Agreement, these Terms will prevail. Each party acknowledges that, in entering into these Terms, it has not relied on, and will have no right or remedy in respect of, any statement, representation, assurance or warranty (whether made negligently or innocently) other than in respect of Expand’s reliance on the person agreeing to these Terms having authority to bind the User and other than as expressly set out in these Terms, provided that nothing in this section limits or excludes any liability for fraud.</p>

                        <p>15.5     Each of the provisions of these Terms is severable. If any such provision is or becomes illegal, invalid or unenforceable in any respect under the law of any jurisdiction that will not affect or impair the legality, validity or enforceability in that jurisdiction of the other provisions of these Terms, or of that or any provision of these Terms in any other jurisdiction.</p>

                        <p>15.6     These Terms will be binding upon and take effect for the benefit of Expand and the User and their respective successors in title, permitted assigns and legal representatives and references to the parties will be construed accordingly.</p>

                        <p>15.7     These Terms do not, and will not be construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between Expand (or any of its Affiliates) and the User or any Authorized User.</p>

                        <p>15.8     Without limitation, a printed version of these Terms and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>

                        <h3>16          Definitions</h3>
                        <p>16.1     In these Terms the following words and phrases have the following meanings:</p>

                        <p><strong>Affiliate</strong> means, with respect to any Person, any other Person that, directly or indirectly through one or more intermediaries, controls, is controlled by, or is under common control with, such Person, and the term “control” (including the terms “controlled by” and “under common control with”) means the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of such Person, whether through ownership of voting securities, by contract or otherwise.</p>


                        <p><strong>Aggregate Data </strong>means de-identified, sanitized or anonymized data that is derived from Platform use by any Authorized User (including Personal Information) and used for analytical and statistical reporting purposes, but excludes Personal Information.</p>

                        <p><strong>Authorized User </strong>means, as applicable, the User's employees and agents which the User has authorized to use the Platform and who are bound by confidentiality obligations.</p>

                        <p><strong>Expand</strong> means Expand Research LLP and all of its respective Affiliates</p>

                        <p><strong>Force Majeure Event</strong> means any event whatsoever beyond Expand's reasonable control including (a) internet interruption, (b) failure, distortion or delay in any communications, systems, networks, hardware and software, power (c) suspension of trading, (d) acts of God, (e) voluntary or mandatory compliance with any Regulations (f) loss or non-grant of any necessary licence or consent (g) any change in any Regulations or interpretation of any Regulations; (h) accidental damage; (i) adverse weather conditions; (j) any labour dispute, (k) non-performance by suppliers, subcontractors or third parties; (l) interruption or failure of any utility service; or (m) war or civil commotion.</p>

                        <p><strong>Individual Services Agreement</strong> means the agreement between the User and Expand or The Boston Consulting Group thereof governing the provision of benchmarking/diagnostic/analytics services and under which User has been granted access to the Platform.</p>

                        <p><strong>Intellectual Property Rights</strong> means all patents, trademarks, service marks, trade and business names, copyright (including copyright in computer programs), rights in designs, database rights, rights in know-how, analytical concepts, approaches, methodologies trade secrets, rights in confidential information and all other intellectual property rights or forms of protection of a similar or equivalent nature or effect which may subsist anywhere in the world (whether or not registered or capable of registration), together with all applications for registration of and rights to apply for, and any licence to use, any of the above, anywhere in the world, whether under statutory law, common law, or otherwise.</p>

                        <p><strong>Login Details</strong> means the password and user identification or other security information in connection with the User’s or an Authorized User’s access to the Platform.</p>

                        <p><strong>Person</strong> means any individual or legal entity (whether or not incorporated) under any statutory or common law (whether private or public law) anywhere in the world.</p>

                        <p><strong>Personal Information</strong> means any information that relates to an identified or identifiable individual, such as an individual’s name or email address.</p>

                        <p><strong>Privacy Policy</strong> means the privacy policy of Expand.</p>

                        <p><strong>Regulations </strong>means all laws, statutes, orders, rules, treaties, regulations, directives, edicts, bye-laws, schemes, other instruments made under any statute or in exercises of the royal prerogative, rules, principles or guidance of a Regulator, decision, judgment and direction of a court, in each case as may be applicable to a User, Expand or the Platform.</p>

                        <p><strong>Regulator </strong>means any person, or law enforcement or other agency in a relevant jurisdiction having regulatory, supervisory or governmental authority (whether under a statutory scheme or otherwise) over all or any part of the business of the User or an Authorized User.</p>

                        <p><strong>Report </strong>means any report or output provided by Expand or made available via the Platform to the User or an Authorized User.</p>

                        <p><strong>The Boston Consulting</strong> Group means The Boston Consulting Group Inc. and all of its respective Affiliates (including Expand).</p>

                        <p><strong>User</strong> means the entity to whom Expand provides benchmarking/diagnostic/analytics services under an Individual Services Agreement and through whom the Authorized User accepting these Terms has been granted access to the Platform as an Authorized User.</p>

                        <p><strong>User Systems</strong> means the computer systems, technology and network infrastructure necessary for the User and any Authorized User to access and use the Platform.</p>

                        <p>16.2 In these Terms:</p>
                        <ol>
                            <li>headings are for convenience only and do not affect the interpretation of any provision of these Terms;</li>
                            <li>any reference to a section is a reference to a section of these Terms;</li>
                            <li>any reference to a person includes any individual, firm, company or other legal entity;</li>
                            <li>any obligation in these Terms on a person or party not to do something, includes an obligation not to agree, allow, permit or acquiesce to that thing being done;</li>
                            <li>any reference in these Terms to any enactment or statutory provision or subordinate legislation will be construed as a reference to it as from time to time replaced, amended, consolidated or re-enacted (with or without modification) and includes all orders, rules or regulations made under such enactment;</li>
                            <li>words denoting the singular include the plural and vice versa, and words denoting any gender include all genders; and</li>
                            <li>any use of the words include or including, or any like words, will be construed without limitation.</li>
                        </ol>
                    </div>
                </div>
                <div className="biginfo-actions">
                    <Link className="button-secondary" to={url.logout}>Decline</Link>
                    <button className="button-primary" onClick={this.handleAccept}>Accept</button>
                </div>
            </div>
        </div>
    </div>
    </div>
		);
	}

}
