import React from "react";
import { Paper, Tabs, Tab, Box, Tooltip, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import { url } from "../../../constants";
import PendingRequests from "./pending-requests";
import RequestHistory from "./requests-history";
import CreateNewRequest from "./new-request";

const useStyles = makeStyles({
  customTabRoot: {
    color: "#42BABA",
  },
  customTabIndicator: {
    backgroundColor: "#42BABA",
  },
  leftAlignTab: {
    justifyContent: "flex-start",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function PC_Dashboard(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [requestData, setRequestData] = React.useState([]);
  const [isPremium, setIsPremium] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRequestData = React.useCallback(async () => {
    const baseURL = `${url.da_api_base}/da_request_get`;
    const config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "X-User-Id": props.userType.userId,
        "X-User-Type": props.userType.type,
        "account-view": true,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.data && response.data.Count > 0) {
        const sortedData = response.data.Items.sort(
          (a, b) =>
            new Date(b.updated_at || b.created_at) -
            new Date(a.updated_at || a.created_at)
        );
        setRequestData(sortedData);
      } else {
        setRequestData([]); // Ensuring requestData is always an array
      }
    } catch (error) {
      console.error("Failed to fetch request data:", error);
      setRequestData([]); // Handling errors by setting requestData to an empty array
    }
  }, [props.userType.userId, props.userType.type]);

  const getUserTypeData = React.useCallback(async () => {
    const baseURL = `${url.da_api_base}/da_get_usertype`;

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "X-User-Id": props.userType.userId,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.Count > 0) {
        const userTypeData = response.data.Items[0];
        setIsPremium(userTypeData.premium);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, [props.userType.userId]);

  React.useEffect(() => {
    getRequestData().catch(console.error);
    getUserTypeData().catch(console.error);
  }, [getRequestData, getUserTypeData]);

  return (
    <div className="page-wrap">
      <div className="centering">
        <div
          className="page-header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2>Private Creditor Access Management</h2>
          <CreateNewRequest
            token={props.token}
            userType={props.userType}
            onSubmitHandler={getRequestData}
          />
        </div>
        <Paper square>
          <Tabs
            variant="fullWidth"
            value={value}
            indicatorColor="primary"
            classes={{
              root: classes.customTabRoot,
              indicator: classes.customTabIndicator,
            }}
            onChange={handleChange}
            aria-label="access management tabs"
          >
            {!isPremium && (
              <Tab
                label="Approved/Revoked Data Access"
                className={classes.leftAlignTab}
              />
            )}
            {isPremium && <Tab label="New Requests" />}
            {isPremium && <Tab label="Approved/Rejected Requests" />}
          </Tabs>
        </Paper>
        {isPremium && (
          <TabPanel value={value} index={0}>
            <PendingRequests
              data={requestData.filter((x) => x.status_id === "CREATED")}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={isPremium ? 1 : 0}>
          <RequestHistory
            data={requestData.filter((x) => x.status_id !== "CREATED")}
          />
        </TabPanel>
      </div>
    </div>
  );
}
