import React from 'react';
import { connect } from 'react-redux';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import { Loader } from '@xandanet/react-components';
import { SiteHeader, Notification, SiteFooter } from 'app/components';

@connect((state) => {
	return {
		me: state.me,
	};
})
export default class AdminArea extends React.PureComponent {

	constructor(props) {
		super(props);
	}

	async componentDidMount() {
		fn.hideNotification();
		if (fn.isLoggedIn()) {
			// get logged in user data
			await this.props.dispatch(fetchData({
				type: 'ME',
				url: '/account',
			}))
		} else {
		  fn.navigate('/logout');
		}
	}

	async componentWillUpdate(nextProps) {
    const { me } = nextProps

    if(!_.isEmpty(me.data)){
      if(1 !== me.data.mfa || "admin" !== me.data.roleName){
        fn.navigate('/logout');
      }
    }

		if (nextProps.notifications.show && (nextProps.location.pathname !== this.props.location.pathname)) {
			fn.hideNotification();
		}
	}

	render() {
		// return loader while its fetching
		if (this.props.me.isLoading) {
			return <Loader />;
		}

		// return the content when its fetched
		if (!this.props.me.isLoading) {
			const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, {
				me: this.props.me,
			}));

			return (
				<div className={`admin-area site logged-in`}>
					<SiteHeader {...this.props} loggedIn adminArea={true} />
					<div className="site-content">
						<Notification data={this.props.notifications} />
						{childrenWithProps}
					</div>
					{/*<AdminFooter />*/}
				</div>
			);
		}

		return null;
	}

}
