import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux'
import { fetchData } from 'app/actions';
import { Dialog, Form, Loader, TextInput } from '@xandanet/react-components';
import { api, fn } from 'app/utils';
import { url } from 'app/constants';
import { PageTitle, SiteHeaderLogo } from 'app/components';
import * as selector from './selector'

@connect((state, ownProps) => {
	const getLoginFactors = selector.makeGetLoginFactors()
	const getActiveLoginFactor = selector.makeGetActiveLoginFactor()

	return {
		loginFactors: getLoginFactors(state),
		activeLoginFactor: getActiveLoginFactor(state)
	}
})
export default class Verify extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			submitting: false
		}

		if(!fn.isLoggedIn()){
			fn.navigate(url.login)
		}
	}

	componentDidMount() {
		this.fetchData()
	}

	async fetchData() {
		await this.props.dispatch(fetchData({
			type: 'LOGINFACTOR',
			url: 'account/login/factors'
		}))
	}

	render() {
		const { loginFactors } = this.props

		return (
			<div className="login-page-wrap">
				<div className="page-wrap">
					<div className="page-content-wrap with-bg">
            <SiteHeaderLogo altColour={true} />
						<div className="login-wrapper">
							<div className="page-header">
								<PageTitle>2-Step Verification</PageTitle>
							</div>
							<div className="page-content">
								<p>You are required to setup multi-factor authentication. Please select your preferred method below.</p>

								<nav className="loginfactors">
									<ul>
									{
										_.map(loginFactors.data, (factor) => {

											// ONLY SHOW SMS
											if('sms' !== factor.factorType){
												return
											}

											let label
											switch (factor.factorType) {
												case 'sms':
													label = 'Text Message Code'
													break;
												case 'push':
													label = 'Okta Verify Mobile App'
													break;
											}

											if(!label){
												return
											}

											return (
												<li>
													<span className="loginfactors-title">{label}</span>
													<Link
														to={`/login/verify/${factor.factorType}`}
														className="button-primary loginfactors-action"
													>
														{factor.status === 'ACTIVE' ? 'Select' : 'Activate'}
													</Link>
												</li>
											)
										})
									}
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
