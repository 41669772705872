import React from 'react'
import { connect } from 'react-redux'
import { ContentLoader } from '@xandanet/react-components'
import { url } from 'app/constants'
import { fetchData } from 'app/actions'
import { SiteHeader, SiteFooter } from 'app/components'
import * as selector from './selector'
import ReactGA from 'react-ga'

@connect((state, ownProps) => {
	const getPageBySlug = selector.makeGetPageBySlug()

	return {
		page: getPageBySlug(state, ownProps.params.pageSlug)
	}
})

export default class View extends React.PureComponent {
	componentDidMount() {
		this.fetchData()
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.pageSlug !== prevProps.params.pageSlug) {
			this.fetchData()
		}
	}

	async fetchData () {
		await this.props.dispatch(fetchData({
			type: 'PAGE',
			url: `/page/${this.props.params.pageSlug}`,
		}))

		// google GA stuff here
		const { page } = this.props
		if(page.id) {
			ReactGA.event({
				category: 'Footer',
				action: 'Load',
				label: `${page.title} loaded`
			})
		}

	}

	render() {
		const {
			page,
		} = this.props

		console.log('Page: ', page)

		return (
				<div className="site-content">
					<ContentLoader
						data={page.id}
					>
						<div className="page-wrap centering">
							<h1>{page.title}</h1>
							<div className="box" dangerouslySetInnerHTML={{ __html: page.content }} />
						</div>
					</ContentLoader>
				</div>
		);
	}

}
