import React from 'react';
import { fn } from 'app/utils';
import { Notification } from 'app/components';

export default class AuthenticationArea extends React.PureComponent {
	componentDidMount() {
		if (fn.isLoggedIn()) {
			// fn.navigate('/products');
		}
	}

	componentWillUpdate(nextProps) {
		if (nextProps && this.props && (nextProps.location.pathname !== this.props.location.pathname)) {
			fn.hideNotification();
		}
	}

	render() {
		const siteAlertClass = this.props.notifications.show ? 'with-alert' : '';
		const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, ...this.props));

		return (
			<div className={`site ${siteAlertClass}`}>
				<div className="site-content site-content-login">
					<Notification data={this.props.notifications} />
					{childrenWithProps}
				</div>
			</div>
		);
	}
}
