import React from 'react';
import { Link } from 'react-router';
import { Dialog, Form, Loader, TextInput, ContentLoader } from '@xandanet/react-components';
import { api, fn } from 'app/utils';
import { PageTitle } from 'app/components';
import { url } from 'app/constants';

export default class Sms extends React.PureComponent {

	render() {
		const { loginFactor, sent } = this.props

    const showGoBack = (loginFactor.status === 'PENDING_ACTIVATION' && sent) ? true : false;

		return (
      <div className="page-content">
        <div className="page-content-inner">
          <div className="page-header">
            <PageTitle>2-Step Verification</PageTitle>
          </div>
          <div>
            <div className="form-description">
              <p>
                A text message with a 6-digit verification code will be sent to {loginFactor.profile.phoneNumber.replace(loginFactor.profile.phoneNumber.substring(4,10), 'XXXXXX')}
              </p>
            </div>
						<Form onSubmit={this.props.handleSubmit}>
							<div className="form-submit no-border">
								<button className="button-primary" disabled={(sent) ? true : false}>
									{sent ? 'Sent' : 'Send'}
								</button>
                {showGoBack &&
                  <Link className="form-submit-link" to={`${url.login}/verify/sms`}>Not received your code?</Link>
                }
							</div>
						</Form>


							<Form onSubmit={this.props.handleSubmit}>
								<TextInput
									name="passCode"
									onChange={this.props.handleInputChange}
									wide
									className="input-main"
									label="Enter Code"
									validation="required"
									disabled={(sent) ? false : true}
                  autoComplete="off"
								/>
								<div className="form-submit no-border">
									<button className="button-primary" disabled={(sent) ? false : true}>Next</button>
								</div>
							</Form>
					</div>
				</div>
			</div>
		);
	}
}
