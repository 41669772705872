import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Table } from '@xandanet/react-components';
import { fn } from 'app/utils';
import { fetchData } from 'app/actions';
import * as selector from '../../methodology/selector';
import { makeGetProducts, makeGetProductOptions } from 'app/containers/product/selector';
import * as adminPowerBISelector from '../powerbi-groups/selector';

@connect((state) => {
	const getMethodologies = selector.makeGetMethodologies()
	const getProducts = makeGetProducts()
	const getProductOptions = makeGetProductOptions()
	const getAllPowerBiGroups = adminPowerBISelector.makeGetAllPowerBIGroups();

	return {
		productOptions: getProductOptions(state),
		products: getProducts(state),
		methodologies: getMethodologies(state),
		allPowerBIGroups: getAllPowerBiGroups(state),
	}
})

export default class List extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 1,
			product: {},
			methodologies: {}
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const queryArgs = {
			'page': this.state.currentPage,
			'status': '1',
			'orderby': 'created',
			'order': 'desc',
			'admin': '1'
		}
		this.props.dispatch(fetchData({
			type: 'METHODOLOGY',
			url: `/methodology${fn.buildUrlQuery(queryArgs, '?')}`,
		}));

		this.props.dispatch(fetchData({
			type: 'ALL_POWER_BI_GROUPS',
			url: '/powerbi/groups',
		}));

		this.props.dispatch(fetchData({
			type: 'CATEGORY',
			url: `/categories`,
		}))
	}

	handlePageChange = (currentPage) => {
		this.setState({ currentPage }, this.fetchData);
	}

	render() {
		const {
			methodologies,
			productOptions,
			allPowerBIGroups
		} = this.props;

		return (
			<div className="page-wrap">
				<div className="centering">
					<div className="page-header">
						<h1>Resources</h1>

						<div className="page-header-actions">
							<Link to="/admin/resources/add" className="button">Add New</Link>
						</div>
					</div>
					<section className="box list methodologies">
						<ContentLoader
							data={methodologies.currentCollection}
							isLoading={methodologies.isLoading}
							noResults="No documents can be found."
						>
							<span className="pagination-status">(Showing items {methodologies.pager.to} of {methodologies.pager.total})</span>
							<table className="list-table">
								<thead>
									<tr>
										<th className="col-title">Title</th>
										<th className="col-permissions">Workspaces</th>
										<th className="col-categories">Categories</th>
										<th className="col-date">Created</th>
									</tr>
								</thead>
								<tbody>
									{_.map(methodologies.currentCollection, (id, index) => {
										const methodology = _.find(methodologies.collection, {'id' : id})

										if(!methodology){
											return false;
										}

										return (
											<tr key={`methodology${index}`}>
												<td><Link to={`/admin/resources/${methodology.id}`}>{methodology.title}</Link></td>
												<td><Link to={`/admin/resources/${methodology.id}`}>
												{methodology.power_bi_group_ids && allPowerBIGroups && allPowerBIGroups.collection ? (
													<ul className="inline">
														{_.map(methodology.power_bi_group_ids, (index) => {
															const powerBiGroupOption = _.find(allPowerBIGroups.collection, {'id' : index})
															console.log("OPTION",powerBiGroupOption);
															if((powerBiGroupOption || {}).alias){
																return <li>{powerBiGroupOption.alias}</li>
															}
														})}
													</ul>
												) : (
													<span dangerouslySetInnerHTML={{__html: `&ndash;`}}></span>
												)}
												</Link>
												</td>

                        <td>
                          <ul className="list-categories">
                            {methodology.categories.map((category) =>
                              <li className={`label-category category-${category.id}`}>{category.name}</li>
                            )}
                          </ul>
                        </td>

                        <td><Link to={`/admin/resources/${methodology.id}`}>{fn.formatDate(methodology.created_at)}</Link></td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</ContentLoader>
					</section>
				</div>
			</div>
		);
	}

}
