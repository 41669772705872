import React from 'react';
import { Dialog } from '@xandanet/react-components';

export default class ConfirmDialog extends React.PureComponent {

	close() {
		this.props.onClose && this.props.onClose();
		this.refDialog && this.refDialog.close();
	}

	confirm(){
		this.props.onConfirm && this.props.onConfirm();
		this.refDialog && this.refDialog.close();
	}

	setNotificationMessage = (data) => {
		console.log(data)
	}

	render() {
		const { content } = this.props;
		const defaultButtons = [
			<button key="cancel" className="button-secondary">Cancel</button>,
			<button key="confirm" className="button" onClick={this.confirm}>Confirm</button>,
		];

		const buttons = this.props.buttons.length > 0 ? this.props.buttons : defaultButtons;

		return (
			<Dialog
				{...this.props}
				content={content}
				buttons={buttons}
				ref={ref => this.refDialog = ref}
			>
				{this.props.children}
			</Dialog>
		);
	}
}
