import React from 'react';
import {PageTitle} from 'app/components';

export default class Content extends React.PureComponent {
    render() {
        return (
            <div className="page-wrap">
                <div className="page-content-wrap with-bg">
                    <div className="centering">
                        <div className="page-header">
                            <PageTitle>Not Found</PageTitle>
                        </div>
                        <p>The page you are looking for can not be found.</p>
                    </div>
                </div>
            </div>
        );
    }
}
