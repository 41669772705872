import React from 'react';
import {connect} from 'react-redux';
import {ContentLoader, Form, Select} from '@xandanet/react-components';
import {Carousel, CreateDocument} from 'app/components';
import {fn, api} from 'app/utils';
import {fetchData} from 'app/actions';
import * as selector from './selector';
import ReactGA from "react-ga";
import MultiBackend, { Preview } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';
import {DragDropContext} from 'react-dnd'
import { makeGetProduct } from 'app/containers/product/selector';

@connect((state, ownProps) => {
    const getMethodologies = selector.makeGetMethodologies()
    const getProduct = makeGetProduct()

    return {
        product: getProduct(state, ownProps.params.productId),
        methodologies: getMethodologies(state),
        documents: state.document,
        categoryOptions: state.categories?.data
    }
})

class List extends React.PureComponent {

    static getDerivedDataFromProps(nextProps, prevState) {

        if (_.isEmpty(nextProps.methodologies.collection) !== prevState.selectedId) {
            return nextProps
        }

        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            search: '',
            methodologies: {},
            selectedId: null,
            selectedDocuments: [],
            selectedCategories: [],
            inputValue: '',
            carouselHeight: 0
        };
    }

  resizeObserver () {
      return new ResizeObserver((entries) => {
        for (const entry of entries) {
          this.setState({"carouselHeight": entry.contentBoxSize[0].blockSize})
        }
    });
  }

  componentDidMount() {
      this.fetchData();
    }

    componentWillUnmount() {
      const resizeObserver = this.resizeObserver()
      const carousel = document.getElementById("carousel");
      if (carousel) {
        resizeObserver.unobserve(carousel);
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if ((this.props.methodologies.collection !== prevProps.methodologies.collection) || (this.props.methodologies.collection && !this.state.selectedId)) {
          this.setState({
              'selectedId': _.first(this.props.methodologies.currentCollection)
          })
      }

      const carousel = document.getElementById("carousel");
      if(carousel) {
        const resizeObserver = this.resizeObserver()
        resizeObserver.observe(carousel);
      }
    }

    fetchData() {
        this.handleGetSearchResults()

        this.props.dispatch(fetchData({
            type: 'CATEGORY',
            url: `/categories`,
        }))
    }

    handlePageChange = (currentPage) => {
        this.setState({currentPage}, this.fetchData);
    }

    handleInputChange = (name, value) => {
        this.setState({[name]: value})
    }

    handleChange = async (name, value) => {
      await this.setState({[name]: value})
      this.handleGetSearchResults()
    }

    handleGetSearchResults = () => {
      const queryArgs = {
        status: '1',
        orderby: 'created',
        order: 'desc',
        product_id: this.props.params.groupId,
        search: this.state.documentSearch,
        categories: this.state.selectedCategories
      }

      this.props.dispatch(fetchData({
        type: 'METHODOLOGY',
        url: `/methodology${fn.buildUrlQuery(queryArgs, '?')}`,
      }))

      ReactGA.event({
        category: 'Documents',
        action: 'search',
        label: `Document searched`
      })
    }

    onSubmitFormHandle = () => {
        this.fetchData();
    }

    loadOptions = async (inputValue, callback) => {
        // Check length of search string, if less then 3 then do nothing.
        if (inputValue && inputValue.length < 3 || inputValue.length > 10) {
            return;
        }

        const queryArgs = {
            product_id: this.props.params.productId,
            search: inputValue
        }

        // Send request
        const url = `/methodology/autocomplete${fn.buildUrlQuery(queryArgs, '?')}`
        const response = await api.get(url)

        if (!api.error(response)) {
            let newItems = [];
            _.map(response.data, (item, index) => {
                const newItem = {
                    id: item,
                    title: item,
                    index: index
                }
                newItems.push(newItem);
            })
            return {options: newItems};
        } else {
            return [];
        }

    };

    handleDownload = async (e, methodology) => {
        e.preventDefault()

        const url = await api.get(methodology.url, {responseType: 'blob'})
        const downloadUrl = window.URL.createObjectURL(new Blob([url.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', methodology.original_file_name);
        document.body.appendChild(link);
        link.click()

        ReactGA.event({
            category: 'Documents',
            action: 'Download',
            label: `Document downloaded`
        })
    }

    renderScrollItem = (methodology) => {
        const selected = this.state.selectedId == methodology.id;
        const pdfImgSrc = selected ? "/images/PDF_Icon_White@2x.png" : "/images/PDF_Icon_Black@2x.png"

        return (
            <div className={`scroll-item ${selected && "scroll-item-selected"}`}
                 onClick={() => this.setState({selectedId: methodology.id})} key={methodology.id}>
              <ul className="list-categories">
                {methodology.categories.map((category) =>
                  <li className={`label-category is-selected category-${category.id}`}>{category.name}</li>
                )}
              </ul>
                <div className="scroll-item-left">
                    <img className="scroll-item-pdf-icon" src={pdfImgSrc}/>
                    <div className="scroll-item-details-wrapper">
                        <div className="scroll-item-title">{methodology.title}</div>
                        <div className="scroll-item-date">{fn.formatDate(methodology.created_at, "DD-MM-YYYY")}</div>
                        <div className="scroll-item-description">{methodology.description}</div>
                    </div>
                </div>
                <span onClick={(e) => this.handleDownload(e, methodology)} className="scroll-item-download">
               Download
            </span>
            </div>
        )
    }

    handleAdd = async (id) => {
        const {selectedId} = this.state;
        let selectedDocuments = [...this.state.selectedDocuments];

        // Check is image already added.
        const imageExists = _.find(selectedDocuments, (document) => document.id === id);
        if (imageExists) {
            return;

        }

        // Load image from server and add into selectedDocument, so next time not to load from server.
        const images = (this.props.methodologies.collection[selectedId] || {}).images
        const selectedItem = _.filter(images, (image) => image.id === id)[0];

        const response = await api.get(selectedItem.url, {responseType: 'arraybuffer'})
        const prefix = "data:" + response.headers["content-type"] + ";base64,"
        const base64 = btoa(
            new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            )
        )
        const url = prefix + base64;
        const newselectedDocument = {
            ...selectedItem,
            url: url
        }
        selectedDocuments.push(newselectedDocument);

        this.setState({
            ...this.state,
            selectedDocuments: [...selectedDocuments]
        })
    }

    moveDocument = (items) => {
        this.setState({
            selectedDocuments: [...items]
        })
    }

    generatePreview(type, item, style) {
      const wrapper = document.getElementById('carousel-images-wrapper')
      const height = wrapper.offsetHeight * 0.5
      const width = wrapper.offsetWidth * 0.5
      Object.assign(style, {width, height, zIndex: 100000});
      return <img style={style} src={"/images/image-thumbail.png"} />;
    }

    handleSelectCategory = async (categoryId) => {
      if (_.includes(this.state.selectedCategories, categoryId)){
        await this.setState({
          selectedCategories: this.state.selectedCategories.filter((id) => id !== categoryId)
        });
      } else {
        await this.setState({
          selectedCategories: [...this.state.selectedCategories, categoryId]
        });

      }
      this.handleGetSearchResults()
    }

    render() {
        const { methodologies, product, categoryOptions } = this.props;
        const { selectedId, selectedCategories, carouselHeight } = this.state;

        return (
            <div className="page-wrap">
              <div className="centering">
                <div className="page-header">
                  <h1>Resources</h1>
                </div>
                <section className="list">
                  <section
                      id="methodologies"
                      className="box-wide methodologies shrink">
                      <div className="search-wrapper">
                          <Form
                              onSubmit={this.onSubmitFormHandle}>
                                <Select
                                  name="documentSearch"
                                  async={true}
                                  className="multi-select document-search"
                                  placeholder="Search Documents"
                                  multiple
                                  //cacheOptions
                                  onChange={this.handleChange}
                                  //onInputChange={this.handleInputChange}
                                  loadOptions={this.loadOptions}
                                  noOptionsMessage={() => 'No results found'}
                                  wide
                                />
                              <button></button>
                          </Form>
                      </div>

                      <div className="list methodologies">
                          <div className="box-content-wrapper">


                                  <div className="box-scroll" style={{maxHeight: `${carouselHeight}px`}}>
                                      <div className="box-scroll-inner">
                                        <div className="box-scroll-categories">
                                          <span className="box-scroll-categories-title">Document Category:</span>
                                          <ul className="list-categories">
                                          {_.map(categoryOptions, (category, index) => {
                                            const isSelected = _.includes(selectedCategories, category.id)
                                            return <li>
                                              <button className={`label-category ${isSelected ? "is-selected" : ""}`} onClick={() => this.handleSelectCategory(category.id)}>{category.name}</button>
                                            </li>
                                          })}
                                          </ul>
                                        </div>

                                        <ContentLoader
                                          data={methodologies.currentCollection}
                                          isLoading={methodologies.isLoading}
                                          noResults="">
                                          {_.map(methodologies.currentCollection, (id, index) => {
                                              const methodology = _.find(methodologies.collection, {'id': id})
                                              return this.renderScrollItem(methodology)
                                          })}
                                        </ContentLoader>
                                      </div>
                                  </div>

                            <ContentLoader
                              data={methodologies.currentCollection}
                              isLoading={methodologies.isLoading}
                              noResults="No documents can be found">
                                  <Carousel
                                      handleAdd={(id) => this.handleAdd(id)}
                                      moveDocument={(items) => this.moveDocument(items)}
                                      documents={this.state.selectedDocuments}
                                      images={(methodologies.collection[selectedId] || {}).images}/>

                                      <Preview generator={this.generatePreview} />
                              </ContentLoader>
                          </div>
                      </div>

                      <CreateDocument
                          productName={product.name}
                          productId={this.props.params.productId}
                          moveDocument={(items) => this.moveDocument(items)}
                          documents={this.state.selectedDocuments}
                      />

                          </section>
                </section>
              </div>
            </div>
        );
    }
}

export default DragDropContext(MultiBackend(HTML5toTouch))(List)
