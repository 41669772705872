import { createSelector } from 'reselect'
import { getProductOptions } from 'app/containers/product/selector'
import _ from 'lodash'
import * as adminPowerBISelector from "app/containers/admin/powerbi-groups/selector";

const getMethodologies = state => state.methodology
const getMethodology = (state, id) => state.methodology.collection[id] || {}

const getSelectedProductOptions = (state, id) => {
	const productOptions = getProductOptions(state)
	const methodology = (state.methodology.collection || {})[id]

	if(!methodology || !methodology.product_ids || !productOptions){
		return null
	}

	const selectedProductOptions = _.map(methodology.product_ids, (product_id) => {
		const product = _.find(productOptions, {'id': product_id})

	    return {
	    	id: product_id,
	        ...product && { title: product.title }
	    }
	})

	return selectedProductOptions
}

const getSelectedAdminGroupOptions = (state, id) => {
	const methodology = (state.methodology.collection || {})[id]
	const getAllPowerBiGroups = adminPowerBISelector.getAllPowerBIGroups(state);

	console.log("SELECTOR ADMIN METHODOLOGIES",methodology, "GROUP OPTIONS", getAllPowerBiGroups.collection);

	if(!methodology || !methodology.power_bi_group_ids || !getAllPowerBiGroups){
		return null
	}

	return _.map(methodology.power_bi_group_ids, (group_id) => {
		const group = _.find(getAllPowerBiGroups.collection, {'id': group_id})

		return {
			id: group_id,
			...group && {title: group.alias}
		}
	})

}


export const makeSelectedProductOptions = () => createSelector([getSelectedProductOptions], obj => obj)
export const makeGetMethodology = () => createSelector([getMethodology], obj => obj)
export const makeGetMethodologies = () => createSelector([getMethodologies], obj => obj)
export const makeSelectedAdminGroupOptions = () => createSelector([getSelectedAdminGroupOptions], obj => obj)