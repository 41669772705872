import React from "react";
import { browserHistory } from "react-router";
import Cookie from "universal-cookie";
import "babel-polyfill";
import moment from "moment";
import Store from "app/store";
import {
  fetchData,
  hideAlert,
  showNotification,
  storeToken,
} from "app/actions";
import { url } from "app/constants";
import { fn, api } from "app/utils";

const cookie = new Cookie();

export default {
  /**
   * Shows alert
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {array}   alerts	Array of alerts
   * @param   {array}   type		Type (success, warning, error)
   */
  showNotification(notifications, type) {
    notifications =
      typeof notifications === "string" ? [notifications] : notifications;
    Store.dispatch(showNotification({ notifications, type }));

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },

  /**
   * Hides the currently showing alert
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @return  {object}	Redux object
   */
  hideNotification() {
    Store.dispatch({ type: "NOTIFICATION_HIDE" });
  },

  escapeUrl(string) {
    if (!string.match(/^[a-zA-Z]+:\/\//)) {
      return `http://${string}`;
    }

    return string;
  },

  /**
   * Deletes cookie by key
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   key	Stored cookie key
   */
  deleteCookie(key) {
    return cookie.remove(key, { path: "/" });
  },

  /**
   * Gets cookie by key
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   key	Stored cookie key
   * @return  {string}		Value of the cookie
   */
  getCookie(key) {
    return cookie.get(key);
  },

  /**
   * Saves cookie
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   key		Cookie key
   * @param   {string}   value	Cookie value
   * @param   {object}   options	Options such as path, age, etc.
   */
  saveCookie(key, value, options) {
    return cookie.set(key, value, options);
  },

  /**
   * Gets token and return its value
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   key	Token key
   * @return  {string}		Token value
   */
  getToken(key) {
    const value = this.getCookie(key);
    Store.dispatch(storeToken(this.parseToken(value)));
    return this.parseToken(value);
  },

  /**
   * Converts token to a readable json object
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   token	Token string
   * @return  {object}			Formatted token object
   */
  parseToken(token = "") {
    if (!token) {
      return false;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  },

  /**
   * Builds an url query.
   *
   * @param      {<type>}                         params  The parameters
   * @param      {string}                         start   The start
   * @return     {(string)}  The url query.
   */
  buildUrlQuery(params = {}, start = "&") {
    if (_.isEmpty(params)) {
      return "";
    }

    let queryString = start;

    _.map(params, (value, key) => {
      if (key && value && value !== "all") {
        // check if value is an array and split
        if (_.isArray(value)) {
          value = value.join(",");

          if (!value) {
            return false;
          }
        }

        queryString = `${queryString}${key}=${value}&`;
      }
    });

    return queryString.replace(/\&$/, "");
  },

  /**
   * Formates date in the desired format
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   date		Date in YYYY-MM-DD HH:mm:ss format
   * @param   {string}   format	Date format
   * @return  {string}			Formatted date
   */
  formatDate(date, format = "Do MMM YYYY") {
    if (!date) {
      return null;
    }

    date = date !== "now" ? date : null;

    return moment(date).format(format);
  },

  /**
   * Returns the difference between 2 dates
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   date		Date in YYYY-MM-DD HH:mm:ss format
   * @param   {string}   diff		Difference, if not set defaults to 'years'
   * @return  {string}			Difference between 2 dates
   */
  diffDate(date, diff = "years") {
    return moment().diff(moment(date, "YYYY-MM-DD hh:mm:ss"), diff);
  },

  /**
   * Returns a nicer date such as today, yesterday, last friday, etc.
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {[type]}   date		Date in YYYY-MM-DD HH:mm:ss format
   * @return  {string}			Formatted date
   */
  prettyDate(date) {
    return moment(date).calendar();
  },

  prettyTime(val) {
    const hours = Math.floor(val / 60);
    const minutes = val % 60;
    let output = "";

    if (hours === 1) {
      output = `${hours} hour `;
    } else if (hours > 0) {
      output = `${hours} hours `;
    }

    return `${output}${minutes} mins`;
  },

  /**
   * Checks if the build is production
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @return  {boolean}		True if production, false if development
   */
  isProduction() {
    return process.env.NODE_ENV && process.env.NODE_ENV === "production";
  },

  /**
   * Navigate to an internal URL
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-31
   */
  navigate(to) {
    // if to is goBack then go back to the previous page
    if (to === "goBack") {
      return browserHistory.goBack();
    }

    return browserHistory.push(to);
  },

  /**
   * Formats the size to human readable size format
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {number}   bytes	Bytes in number
   * @return  {string}			Formatted size
   */
  bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
      return "N/A";
    }

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) {
      return `${bytes} ${sizes[i]})`;
    }

    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  },

  /**
   * Logs out the user, clears the redux object
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   */
  logOut() {
    return new Promise(async (resolve, reject) => {
      const user = this.getUser();
      if (user.sisense_token) {
        // make an api call to sisense logout so it can delete the cookie on expandresearch.sisense.com
        await api.get("/account/logout");
      }

      await this.deleteCookie("jwt_token");

      resolve(true);
    });
  },

  /**
   * Checks if user logged in or not
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @return  {boolean}		True if logged in, false if logged out
   */
  isLoggedIn() {
    if (this.getCookie("jwt_token")) {
      return true;
    }

    return false;
  },

  /**
   * Gets the current logged in user
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @return  {object}		User
   */
  getUser() {
    // if user role set return
    if (this.user) {
      return this.user;
    }

    // get global store
    const store = Store.getState();

    // check if user is fetched and has role
    if (store.me.isLoading || !store.me.data?._id) {
      return false;
    }

    // cache user role
    this.user = store.me.data;

    return this.user;
  },

  /**
   * Checks if the current user is admin
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @return  {boolean}		True if user is admin, false if user is not admin
   */
  isAdmin() {
    if ("local" == process.env.NODE_ENV) {
      return true;
    }

    return this.getUser().roleName == "admin";
  },
  renderUserType(userType) {
    switch (userType) {
      case "LP":
        return "Limited Partner";
      case "PC":
        return "Private Creditor";
    }
  },
  /**
   * Checks if the provided user is the logged in user
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @return  {boolean}		True if user matches
   */
  isMe(id) {
    if (this.getUser().id !== id) {
      return false;
    }

    return true;
  },

  getReportTypes() {
    return [
      {
        id: "working-group",
        title: "Working Group",
      },
      {
        id: "bespoke",
        title: "Bespoke",
      },
      {
        id: "benchmark",
        title: "Benchmark",
      },
    ];
  },

	getProductMeta(productId){
		const meta = [
			{
				// Business Strategies
				'id': '5ba8e78cbf0c1adb88fe7092',
				'description': 'Trading Strategies, Client Intelligence, Business Productivity',
			},
			{
				// Operational Strategies
				'id': '5bbe0ff1d622c02474c9aaa5',
				'description': 'Operations Costs, Operations Control and Efficiency, Client Impact'
			},
			{
				// Market Data
				'id': '5bbe1001d622c02474c9aaa8',
				'description': 'Market Data cost and consumption analysis'
			},
			{
				// Technology Strategies
				'id': '5bbe0fe3d622c02474c9aaa1',
				'description': 'IT Total Cost of Ownership, Human Capital Strategies, IT Effectiveness'
			},
			{
				// Corporate Functions
				'id': '5bbe100bd622c02474c9aaab',
				'description': 'Management & Strategy for transversal functions incl. Risk, Finance, Compliance'
			},
			{
				'id': '5ba8e8a0bf0c1adb88fe7133',
				'description': 'Compare your FX Trading volumes against a Benchmark across 8 dimensions with Expand’s FX Trading Portal.'
			},
			{
				// FX Trading
				'id': '5ba8e8a0bf0c1adb88fe7132',
				'description': 'The Expand Research Global eFX Trading benchmark is an exclusive evaluation of global eFX activity, which measures a firm’s ecommerce market share versus its peers.'
			},
			{
				// Rates Trading
				'id': '5ba8eababf0c1adb88fe714c',
				'description': 'Compare your Rates Trading volumes against a Benchmark covering both voice and electronic trades on Expand’s Rates Trading Portal.',
			},
			{
				// IT Reg Risk
				'id': '5bbe1055d622c02474c9aac0',
				'description': 'Understand the nature of regulatory interactions across Asia.'
			},
			{
				// IT Spend Benchmark
				'id': '5f0db3d2687c6c2bf8254006',
				'description': 'The purpose of this tool is to give a feeling for how the quantitative output will be presented in an interactive way for the OGBG benchmark. The numbers are kept deliberately random. Not all parts of the tool are currently populated, but the overall structure is presented for completeness.'
			},
			{
				// ESG Data Convergence Initiative – Free
				'id': '64b1110ab3c6562ba898ffea',
				'description': 'Inaugural year features and functionalities for all members'
			},
			{
				// ESG Data Convergence Initiative – Free | Analysis
				'id': '64b11125b3c6562ba898ffec',
				'description': 'Inaugural year EDCI benchmark dashboards'
			},
			{
				// ESG Data Convergence Initiative – Free | Data tables
				'id': '64b1112db3c6562ba898ffee',
				'description': 'Reference data and downloadable pivot tables'
			},
			{
				// ESG Data Convergence Initiative – Premium
				'id': '623c93b1a0f2531a98c30255',
				'description': 'Advanced features and functionalities (available free in 2023)'
			},
			{
				// ESG Data Convergence Initiative – Premium | Benchmark Summary
				'id': '64b559ed2f1c1b3d5823d5ab',
				'description': 'Overview of the EDCI benchmark composition and submission rates'
			},
			{
				// ESG Data Convergence Initiative – Premium | Benchmark Results
				'id': '64b559f62f1c1b3d5823d5ad',
				'description': 'Core analysis of the 2023 EDCI benchmark (advanced dashboard views)'
			},
			{
				// ESG Data Convergence Initiative – Premium | Trending Analysis
				'id': '64b55a032f1c1b3d5823d5af',
				'description': 'Year-on-year trending analysis for all KPIs'
			},
			{
				// ESG Data Convergence Initiative – Premium | Portfolio comparison
				'id': '64b55a0f2f1c1b3d5823d5b1',
				'description': 'Understand your own portfolio’s performance against like-for-like EDCI benchmarks'
			},
			{
				// ESG Data Convergence Initiative – Premium | Data Tables
				'id': '64b55a192f1c1b3d5823d5b3',
				'description': 'Reference data and downloadable pivot tables'
			},
		]

    return _.find(meta, { id: productId }) || {};
  },

  getUrlParam(param) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    return params[param];
  },

  isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  },
};
