import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Divider, Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const KPIs = [
  { label: "Scope 1 Emissions", value: "scope1Emissions" },
  { label: "Scope 2 Emissions", value: "scope2Emissions" },
  { label: "Scope 3 Emissions", value: "scope3Emissions" },
  {
    label: "Renewable Energy Consumption",
    value: "renewableEnergyConsumption",
  },
  { label: "% Women On Board", value: "womenOnboard" },
  {
    label: "% Under-Represented Groups On Board",
    value: "underrepresentedGroupsOnboard",
  },
  { label: "% LGBTQ On Board", value: "lgbtqOnboard" },
  { label: "% Women In C-Suite", value: "womenInCSuite" },
  { label: "Work-Related Accidents", value: "workRelatedAccidents" },
  { label: "Total Net New Hires", value: "totalNetNewHires" },
  { label: "Organic Net New Hires", value: "organicNetNewHires" },
  { label: "Annual Turnover %", value: "annualTurnover" },
  { label: "Portcos with Employee Survey", value: "portcosEmployeeSurvey" },
  {
    label: "Employee Survey Response Rate",
    value: "employeeSurveyResponseRate",
  },
];

export default function SelectKPIs(props) {
  const classes = useStyles();
  const [requestForm, setRequestForm] = props.formData;
  const [kpis, setKpis] = [
    requestForm.approvedKPIs,
    (kpis) => {
      setRequestForm((form) => ({ ...form, approvedKPIs: kpis }));
    },
  ];

  const handleKPIsChange = (event) => {
    if (event.target.checked) {
      if (kpis.indexOf(event.target.value) === -1) {
        kpis.push(event.target.value);
        setKpis(kpis);
      }
    } else {
      if (kpis.indexOf(event.target.value) !== -1) {
        setKpis(kpis.filter((chip) => chip !== event.target.value));
      }
    }
  };

  React.useEffect(() => {
    if (requestForm.user_type === "PC") {
      setRequestForm((form) => ({ ...form, approvedAccessLevels: "company" }));
    } else {
      setRequestForm((form) => ({ ...form, approvedAccessLevels: "company" }));
    }
  }, [requestForm.user_type]);

  return (
    <>
      <Typography
        gutterBottom
        style={{ padding: "10px", fontSize: "12px", marginBottom: "-15px" }}
      >
        KPI(s) will be visible for individual companies. Select the KPI(s) you
        want to share
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormGroup
            variant="outlined"
            style={{ color: "#808080" }}
            className={classes.formControl}
          >
            {KPIs.slice(0, KPIs.length / 2).map((KPI) => (
              <FormControlLabel
                key={KPI.value}
                control={
                  <Checkbox
                    checked={kpis.indexOf(KPI.value) !== -1}
                    onChange={handleKPIsChange}
                    name="checkedB"
                    color="primary"
                    value={KPI.value}
                  />
                }
                label={KPI.label}
              />
            ))}
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup
            variant="outlined"
            style={{ color: "#808080" }}
            className={classes.formControl}
          >
            {KPIs.slice(KPIs.length / 2).map((KPI) => (
              <FormControlLabel
                key={KPI.value}
                control={
                  <Checkbox
                    checked={kpis.indexOf(KPI.value) !== -1}
                    onChange={handleKPIsChange}
                    name="checkedB"
                    color="primary"
                    value={KPI.value}
                  />
                }
                label={KPI.label}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}
