import React from 'react'
import { fetchData } from 'app/actions';
import {FileUpload, Form, TextInput, Select} from "@xandanet/react-components";
import { connect } from 'react-redux';

import {fn, api} from 'app/utils';

@connect(state => {
    return {
        contactOptions: state.contactOptions.contactOptions
    }
})

export default class ContactForm extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: {},
            contactForm: {}
        }
    }

    async componentDidMount() {
        await this.props.dispatch(fetchData({
            type: 'CONTACT_OPTIONS',
            url: '/options',
        }))
    }

    handleChange = (value, key) => {
        const {contactOptions} = this.props;
        let selectedOption = {};

        // Change form field on selectOption
        if (value === 'selectOption') {
            selectedOption = _.find(contactOptions, {id: key})
            if (selectedOption) {
                this.setState({
                    selectedOption: selectedOption,
                    successMessage: null,
                    errorMessage: null,
                })
            }
        }
    }

    handleInputChange = (key, value) => {
        this.setState({
            contactForm: {
                ...this.state.contactForm,
                [key]: value
            }
        })
    }

    handleSubmit = async () => {
        const {contactForm, selectedOption} = this.state;
        let formData = new FormData();

        formData.append('contact_form[option_id]', selectedOption.id);
        formData.append('option_title', selectedOption.title && selectedOption.title)

        _.map(contactForm, (value, key) => {
            if (key === 'files') {
                if (_.isArray(value)) {
                    _.map(value, (file, fileKey) => {
                        formData.append(`contact_form[files][${fileKey}]`, file);
                    })
                    return;
                }
            }

            // Generate submission fields
            _.map(selectedOption.fields, (option, index) => {
                if (option.id == key) {
                    formData.append(`contact_form[fields][${index}][field_id]`, key);
                    formData.append(`contact_form[fields][${index}][value]`, value);
                    formData.append(`contact_form[fields][${index}][title]`, option.title);
                }
            })

        })

        const response = await api.post('/contact', formData)
        if (!api.error(response, false)) {
            this.setState({
                successMessage: 'Thank you for your request. We will be in touch with you soon.'
            });
            this.resetForm()
        } else {
            this.setState({
                errorMessage: 'Sorry, something went wrong. Please try again later'
            });
        }
    }

    resetForm = () => {
        this.setState({
            selectedOption: {}
        })
    }

    renderField = (field) => {
        switch (field.type) {
            case 'text':
                return (<TextInput
                    skipInitialOnChangeCall
                    key={field.id}
                    value=""
                    wide
                    name={field.id}
                    label={field.title}
                    validation="required"
                    onChange={this.handleInputChange} className="input-main ghost-input"/>);
                break;

            case 'file':
                return (<div className="file-wrapper" key={field.id}>
                    <FileUpload
                        accept={['.jpg', '.png', '.jpeg', '.pdf']}
                        skipInitialOnChangeCall
                        name="files"
                        multiple
                        wide
                        placeholder="Attach File"
                        onChange={this.handleInputChange}
                    />
                </div>)

            case 'textarea':
                return (
                    <TextInput
                        skipInitialOnChangeCall
                        key={field.id}
                        value=""
                        wide
                        textarea
                        name={field.id}
                        label={field.title}
                        validation="required"
                        onChange={this.handleInputChange} className="input-main ghost-input"/>
                )

            default:
                break;
        }
    }

    render() {
        const { selectedOption } = this.state;
        const { contactOptions } = this.props;

        return (
            <div className="contact-us">
                <Form onSubmit={this.handleSubmit} className="bug-report-form">
                    <Select
                        name="selectOption"
                        options={contactOptions}
                        value="title"
                        wide
                        className="select-input ghost-input"
                        skipInitialOnChangeCall
                        label="Reason for getting in touch"
                        onChange={this.handleChange}
                        placeholder="Select"
                    />

                    {this.state.successMessage && this.state.successMessage ? (
                        <div className="success-message">{this.state.successMessage}</div>) : ''}

                    {this.state.errorMessage && this.state.errorMessage ? (
                        <div className="error-message">{this.state.errorMessage}</div>) : ''}

                    <div className="contact-field-section">
                        {selectedOption && _.map(selectedOption.fields, (field) => {
                            return (this.renderField(field));
                        })}
                    </div>

                    <div className="submit-action">
                        <div className="buttonWrapper"><button className="button">Submit</button></div>
                    </div>
                </Form>
            </div>
        )
    }
}
