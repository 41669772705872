import React from 'react';
import {connect} from "react-redux";

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

// fake data generator
const getItems = count =>
    Array.from({length: count}, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`,
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    width: '100%',
});

const style = {
    border: 'none',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
}

@connect((state) => {
    return {}
})

class SortDocument extends React.Component {
    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            this.props.documents,
            result.source.index,
            result.destination.index
        );
        this.props.moveDocument(items);
    }

    removeDocument = (document) => {
        const selectedDocument = this.props.documents;
        const existingDocument = _.remove(selectedDocument, (curDocument) => {
            return document.id !== curDocument.id;
        })
        this.props.moveDocument(existingDocument);
    }

    render() {
        const {documents} = this.props;
        return (
            <DragDropContext
                onDragEnd={this.onDragEnd}>
                <Droppable
                    droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {_.map(documents, (item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div className="placeholder-image"
                                                 style={{
                                                     ...style,
                                                     backgroundImage: `url("${item.url}")`,
                                                     color: '#000'
                                                 }}>
                                                <div className="document-close"
                                                     onClick={() => this.removeDocument(item)}>
                                                    <i className="icon ion-android-remove-circle text-danger"></i>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            {this.props.children}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}
export default SortDocument;