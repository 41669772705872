export default function reducer(state = { alerts: null, type: null, show: false }, action) {
	switch (action.type) {
		case 'NOTIFICATION_SHOW': {
			return {
				...state,
				notifications: action.payload.notifications,
				type: action.payload.type,
				show: true,
			};
		}
		case 'NOTIFICATION_HIDE': {
			return {
				...state,
				alerts: null,
				type: null,
				show: false,
			};
		}
		default: {
			return state;
		}
	}
}
