import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import { url } from "../../../../constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer,
    color: "#fff",
  },
}));

export default function RejectDataAccessRequest(props) {
  const classes = useStyles();

  // Dialog state
  const [open, setOpen] = React.useState(false);
  const [submitInProgress, setSubmitInProgress] = React.useState(false);

  // Form state
  const [requestData, setRequestData] = React.useState({
    requester_type: props.prefilledForm.requester_type,
    requester_company: props.prefilledForm.requester_company,
    requester_assets: props.prefilledForm.requester_assets,
    gp_CompanyName: props.prefilledForm.approver_gp_company,
    approverNotes: "",
  });

  // Local state for approver notes input
  const [approverNotesInput, setApproverNotesInput] = React.useState("");

  // Snackbar state
  const [sbState, setSbState] = React.useState({
    openSb: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });
  const { vertical, horizontal, openSb } = sbState;

  let requestErrorMsg = "";
  let submitSuccess = false;
  const setSubmitSuccess = (g) => {
    submitSuccess = g;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSb = () => {
    setSbState({ ...sbState, openSb: false });
  };

  const handleClickSb = (newState, requestHandler) => async () => {
    if (!submitInProgress) {
      await requestHandler();
    }

    if (submitSuccess) {
      setSbState({
        severity: "success",
        message: "Request Rejected Successfully",
        openSb: true,
        ...newState,
      });
      setTimeout(() => {
        props.onSubmitHandler();
      }, 2000);
    } else {
      setSbState({
        severity: "error",
        message: "Failed: " + requestErrorMsg + ".",
        openSb: true,
        ...newState,
      });
    }

    setOpen(!submitSuccess);
  };

  const patchRequestReject = async () => {
    setSubmitInProgress(true);

    // Update requestData with approverNotesInput
    setRequestData((prevData) => ({
      ...prevData,
      approverNotes: approverNotesInput,
    }));

    let data = JSON.stringify({
      ...requestData,
      approverNotes: approverNotesInput,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url:
        url.da_api_base +
        "/da_request_reject?id=" +
        props.prefilledForm.da_request_id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
      withCredentials: false,
    };

    try {
      const resp = await axios.request(config);

      if (resp.status === 200) setSubmitSuccess(true);
    } catch (error) {
      console.log(error);
      requestErrorMsg = error.response.data;
      setSubmitSuccess(false);
    }

    setSubmitInProgress(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        className={classes.button}
        color="secondary"
        variant="contained"
      >
        Reject
      </Button>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Data Access Request
        </DialogTitle>
        <DialogContent dividers>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <TextField
              id="outlined-multiline-static"
              label="Please provide reason for Rejection"
              multiline
              variant="outlined"
              style={{ marginTop: "15px" }}
              onChange={(event) => {
                setApproverNotesInput(event.target.value);
              }}
              value={approverNotesInput}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            Back
          </Button>
          <Button
            onClick={handleClickSb(
              {
                vertical: "top",
                horizontal: "right",
              },
              patchRequestReject
            )}
            className={classes.button}
            color="secondary"
            variant="contained"
          >
            REJECT
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={submitInProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <Snackbar
        open={openSb}
        autoHideDuration={6000}
        onClose={handleCloseSb}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleCloseSb} severity={sbState.severity}>
          {sbState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
