import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Table } from '@xandanet/react-components';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import { SearchInput } from 'app/components';
import * as selector from './selector';
import ReactGA from "react-ga";

const selectProduct = id => (state, props) => {
	return { selectedProduct: id }
}

@connect((state) => {
	const getProducts = selector.makeGetProducts();
	return {
		products: getProducts(state),
	};
})

export default class List extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			selectedProduct: null,
		};
	}

	componentDidMount() {
		this.fetchData();

		// ReactGA.event({
		// 	category: 'Products',
		// 	action: 'load',
		// 	label: 'products page loaded'
		// })
	}

	async fetchData() {
		// await this.props.dispatch(fetchData({
		// 	type: 'PRODUCT',
		// 	url: '/sisense/folders',
		// }));

    const { products } = this.props
    if (products.collection?.folders?.length == 1) {
      const parentFolder = products.collection.folders[0];
      if (parentFolder?.folders?.length > 0) {
        const childFolder = parentFolder.folders[0];
        fn.navigate(`/products/${childFolder._id}`)
      }
    }
	}

	selectProduct = id => () => {
		if(id == this.state.selectedProduct){
			this.setState({selectedProduct: null})
		} else {
			this.setState(selectProduct(id))
		}
	}

	buildProduct = (folder, index, totalItems) => {
		const { selectedProduct } = this.state;
		const productMeta = fn.getProductMeta(folder.oid)

		let stateClassName = ''
		if(folder._id === selectedProduct || 1 === totalItems){
			stateClassName = 'product-selected'
		} else if (selectedProduct){
			stateClassName = 'product-not-selected'
		}

		if(_.isEmpty(folder.folders)) {
		  return
		}

		const childFolders = _.head(folder.folders)?.folders;
		const dashboardChildFolder = _.pickBy(childFolders, value => value.name == "Dashboard");
		// zero index will exist if the folder exists
		if (!dashboardChildFolder[0]) {
			// filter to only show if the product has Dashboards
			return
		}

		return (
			<li className={`product-entry ${stateClassName}`} onClick={this.selectProduct(folder._id)} key={folder._id}>
				<div className="product-entry-inner">

					<span className="product-name">{folder.name.replace(/^(\d+)./, '')}</span>
					<span className="product-desc">{productMeta.description}</span>
					<span className="product-name-alt">{folder.name.replace(/^(\d+)./, '')}</span>
					{folder.folders &&
						<ul className={`product-children count-` + folder?.folders?.length}>
							{_.map(_.sortBy(folder.folders, 'name'), childFolder => {
								return (
								<li className="product-child" key={childFolder._id}>
									<span className="child-title">{childFolder.name.replace(/^(\d+)./, '')}</span>
									<span className="child-desc">{fn.getProductMeta(childFolder.oid).description}</span>
									<Link className="button product-child-button" to={`/products/${childFolder._id}/dashboard/`} onClick={() => ReactGA.event({category: 'Products', action: 'Click', label: 'Analytics'})} onlyActiveOnIndex>Analytics</Link>
								</li>
								)
							})}
						</ul>
					}
				</div>
			</li>
		)
	}


	render() {
		const { products } = this.props;

		return (
						<ContentLoader
							data={products.collection}
							isLoading={products.isLoading}
						>
              <div className="page-header">
                <div className="centering">
                  <h1 className="page-title">Dashboard</h1>
                </div>
              </div>
							<div className="main centering">
								<div className="products">
									<ul className={`product-list`}>
										{_.map(_.sortBy(products.collection.folders, 'name'), (folder, index) => this.buildProduct(folder, index, products.collection.folders?.length))}
										{_.isEmpty(products.collection.folders) && (
											<li className="product-entry">
												<div className="product-entry-inner">
													<span className="product-name">No results found</span>
												</div>
											</li>
										)}
									</ul>
								</div>
							</div>
						</ContentLoader>
		);
	}

}
