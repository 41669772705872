import React from "react";
import _ from "lodash";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Form, TextInput, RichText, button } from "@xandanet/react-components";
import { api, fn } from "app/utils";
import { url } from "app/constants";
import { fetchData } from "app/actions";
import { FileUploadWithPreview, Meta, Notification } from "app/components";
import * as selector from "app/containers/page/selector";

@connect((state, ownProps) => {
  const getPage = selector.makeGetPage();

  return {
    page: getPage(state, ownProps.params.pageId),
  };
})
export default class Edit extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.params.pageId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page.slug !== this.props.page.slug) {
      this.setState();
    }
  }

  fetchData() {
    this.props.dispatch(
      fetchData({
        type: "PAGE",
        url: `/page/${this.props.params.pageId}`,
      })
    );
  }

  handleSubmit = async () => {
    const formData = new FormData();

    formData.append("title", this.state.title);
    formData.append("content", this.state.content);
    formData.append("slug", this.state.slug);

    // default to blank if this is a new page
    const pageId = this.props.params.pageId ? this.props.params.pageId : "";
    const response = await api.update(`/page/${pageId}`, formData);
    const error = api.error(response);

    if (!error) {
      this.props.dispatch({
        type: "PAGE_FULFILLED",
        payload: response,
      });

      fn.navigate(`/admin/pages/${response.data.id}`);
      fn.showNotification(
        `The page has been successfully ${pageId ? "updated" : "created"}.`,
        "success"
      );
    }

    return true;
  };

  handleInputChange = (name, value) => this.setState({ [name]: value });

  handleDelete = async () => {
    if (window.confirm("Are you sure you wish to delete this page?")) {
      const response = await api.delete(`/page/${this.props.params.pageId}`);
      const error = api.error(response);
      if (!error) {
        this.props.dispatch({
          type: "PAGE_DELETED",
          payload: { id: this.props.params.pageId },
        });
        fn.navigate("/admin/pages");
        fn.showNotification(
          `You have successfully deleted ${this.state.title}`,
          "success"
        );
      }
    }
  };

  render() {
    const { page } = this.props;

    return (
      <div className="page-wrap with-header">
        <div className="page-content-wrap with-bg">
          <div className="centering">
            <div className="page-header">
              <h1>{this.props.params.pageId ? "Edit" : "Create"} Page</h1>

              <div className="page-header-actions">
                {this.props.params.pageId && (
                  <button className="button-danger" onClick={this.handleDelete}>
                    Delete
                  </button>
                )}
              </div>
            </div>
            <Form onSubmit={this.handleSubmit}>
              <div className="box">
                <h3 className="box-heading">Details</h3>
                <TextInput
                  name="title"
                  label="Title"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  value={page.title}
                  validation="required"
                  wide
                />
                {page.slug && (
                  <div className="form-group">
                    <strong>
                      <Link to={`/page/${page.slug}`}>
                        {window.location.origin + `/page/${page.slug}`}
                      </Link>
                    </strong>
                  </div>
                )}
                <RichText
                  name="content"
                  label=""
                  value={page.content}
                  onChange={this.handleInputChange}
                  textarea={true}
                  wide
                />
              </div>
              <button className="button form-submit">
                {this.props.params.pageId ? "Update" : "Submit"}
              </button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
