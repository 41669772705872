import React from 'react';
import { Link } from 'react-router';
import { Dialog, Form, Loader, TextInput, ContentLoader } from '@xandanet/react-components';
import { api, fn } from 'app/utils';
import { PageTitle } from 'app/components';
import { url } from 'app/constants';

export default class Push extends React.PureComponent {

	render() {
		return (
			<div className="login-wrapper">
				<div className="page-header">
					<PageTitle>Set Up Text Message Verification</PageTitle>
				</div>
				<div className="page-content">
					<p>Enter the phone number you'll use to receive codes via text message, then click Send Code to verify that it works.</p>

					<Form onSubmit={this.props.handleSubmit}>
						<div className="form-submit">
							<button className="button-secondary">Send Code</button>
						</div>
						<div className="action-forgotten-password">
							<Link to="/login/verify">Select a different method</Link>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}
