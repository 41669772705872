import React from 'react';
import CarouselImage from './CarouselImage';
import {api} from 'app/utils'

export default class Carousel extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            currentIndex: 0,
            translateValue: 0,
            selected: [],
            fullScreenClicked: false
        }
    }

    componentDidUpdate(prevProps) {
        // reset back to page 1 if the images change
        if (this.props.images !== prevProps.images) {
            this.setState({'currentIndex': 0})
        }
    }

    clamp = (num, min, max) => {
        if (num >= max) {
            return (num % (max - min)) + min
        }
        if (num < min) {
            return max - Math.abs(num % (max - min))
        }
        return num;
    }

    goToPrevSlide = () => {
        if (this.state.currentIndex === 0) {
            return;
        }
        const {images} = this.props;
        this.setState(prevState => ({
            currentIndex: this.clamp(prevState.currentIndex - 1, 0, images.length || 0)
        }))
    }

    goToNextSlide = () => {
        if (this.state.currentIndex + 1 === this.props.images.length) {
            return;
        }

        const {images} = this.props;
        this.setState(prevState => ({
            currentIndex: this.clamp(prevState.currentIndex + 1, 0, images.length || 0)
        }))
    }

    handleDownloadImage = async (url) => {
        e.preventDefault()
    }

    // send drop id to parent component.
    handleAdd = (id) => {
        this.props.handleAdd(id);
    }

    renderSlideshow = () => {
        const {images, documents, moveDocument} = this.props;
        const {currentIndex, fullScreenClicked} = this.state;

        return (
            <React.Fragment>
              <p className="carousel-disclaimer">This content is intended for EDCI members only. Please do not share this document with non-EDCI members.</p>
                <div className="carousel-images-wrapper" id="carousel-images-wrapper">
                    {images.map((image, index) => (
                        <CarouselImage
                            image={image}
                            key={image.id}
                            documents={documents}
                            moveDocument={(items) => moveDocument(items)}
                            handleAdd={(id) => this.handleAdd(id)}
                            images={images}
                            fullscreen={fullScreenClicked}
                            selected={index === currentIndex}
                            closeFullScreen={this.closeFullScreen}/>
                    ))}

                    <div className={`carousel-button carousel-buttons-prev ${currentIndex === 0 ? "disabled" : ""}`}
                         onClick={this.goToPrevSlide}
                         title="Previous Slide">
                        <div className="arrow-container-left">
                            <i className="icon ion-ios-arrow-left icon-size-38"></i>
                        </div>
                    </div>

                    <div
                        className={`carousel-button carousel-buttons-next ${(currentIndex + 1) === images.length ? "disabled" : ""}`}
                        onClick={this.goToNextSlide}
                        title="Next Slide">
                        <div className="arrow-container-right">
                            <i className="icon ion-ios-arrow-right icon-size-38"></i>
                        </div>
                    </div>
                </div>

                <div className="carousel-footer">
                    {!this.state.fullScreenClicked ?
                        <div>
                            <span className="clickable carousel-footer-fullscreen" onClick={() => this.setState({fullScreenClicked: true})}>
                                <i className="icon ion-qr-scanner icon-size-20"></i> Full Screen
                            </span>
                        </div>
                        :
                        ''
                    }

                    <div className="carousel-counter">Slide {currentIndex + 1} of {images.length}</div>
                </div>
            </React.Fragment>
        )
    }

    closeFullScreen = () => {
        this.setState({
            fullScreenClicked: false
        })
    }

    render() {
        const {images} = this.props;

        if (!images) {
            return null
        }

        return (
            <React.Fragment>
                <div className="carousel" id="carousel">
                    {this.renderSlideshow()}
                </div>

                {this.state.fullScreenClicked ? <div className="carousel-fullscreen">
                    <div className="fullscreen-body">
                        {this.renderSlideshow()}
                    </div>
                </div> : ''}
            </React.Fragment>
        )
    }
}
