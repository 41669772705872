export default function reducer(state = { alerts: null, type: null, show: false }, action) {
  switch (action.type) {

    case 'MOBILE_MENU_TOGGLE': {
      return {
        ...state,
        activeMenu: action.payload
      };
    }

    case '@@router/LOCATION_CHANGE':
    case 'MOBILE_MENU_HIDE':
    return {
      ...state,
      activeMenu: false
    };

    default: {
      return state;
    }
  }
}
