import { fn } from "./";
import { url as urls } from "app/constants";
import axios from "axios";
import _ from "lodash";

export default {
  /**
   * Sends a get request to API
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   url		Request URL
   * @return  {object}			Response or the error
   */
  async get(url, config) {
    return await this.send(url, "GET", null, config);
  },

  /**
   * Sends a post request to API with the set data
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   url		Request URL
   * @param   {object}   data		Form data
   * @return  {object}			Response or the error
   */
  async post(url, data) {
    return await this.send(url, "POST", { data });
  },

  /**
   * Sends an update request to API with the set data
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   url		Request URL
   * @param   {object}   data		Form data
   * @return  {object}			Response or the error
   */
  async update(url, data) {
    return await this.send(url, "POST", { data });
  },

  /**
   * Sends a delete request to API with the set data
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   url		Request URL
   * @param   {object}   data		Form data
   * @return  {object}			Response or the error
   */
  async delete(url, data) {
    return await this.send(url, "DELETE", { data });
  },

  /**
   * Sends the request to API with the set method and data.
   * This function sets the URL and the token, returns the response or catches the error.
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {string}   url		Request URL which adds to the baseURL
   * @param   {string}   method	Request method (GET, POST, PUT, DELETE)
   * @param   {object}   data		Form data
   * @return  {object}			Response or the error
   */
  send(url, method, data, config) {
    data = _.assign({}, { method }, data, config);

    axios.defaults.baseURL = urls.expandApi;
    const token = fn.getCookie("jwt_token");

    if (token) {
      axios.defaults.headers = { Authorization: `${token}` };
      axios.defaults.withCredentials = true;
    }

    return axios(url, data)
      .then((response) => response)
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
        return false;
      });
  },

  /**
   * Creates a better structured object from the received data, merges the previous data with the current data
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {object}   	state	Redux state
   * @param   {object}   	action	Dispatched action with data payload
   * @param   {string}   	key		Reducer type
   * @return  {object}			Merged data
   */
  cachedMergeData: {},
  normalizeData(state, action, key = "id") {
    let collection = {};
    let currentCollection = state.currentCollection;
    let pager = {};

    // get the resposne from api
    const data = action.payload.data;

    // array of items
    if ("data" in data) {
      const collectionKey = "data";
      collection = _.keyBy(data[collectionKey], (o) => o[key]);
      currentCollection = data[collectionKey].map((o) => o[key]);

      pager = {
        currentPage: data.current_page,
        from: data.from,
        nextPageUrl: data.next_page_url,
        path: data.path,
        perPage: data.per_page,
        prevPageUrl: data.prev_page_url,
        to: data.to,
        total: data.total,
      };

      // if merge is true then merges the previous currentCollection with the new currentCollection
      if (action.merge) {
        // if mergeId is provided then will keep merging until the mergeId is the same, then resets
        if (action.mergeId) {
          if (this.cachedMergeData[action.type] === action.mergeId) {
            currentCollection = _.union(
              state.currentCollection,
              currentCollection
            );
          }
          // cache the new mergeId
          this.cachedMergeData[action.type] = action.mergeId;
        } else {
          currentCollection = _.union(
            state.currentCollection,
            currentCollection
          );
        }
      }
      // single item
    } else {
      collection[data[key]] = data;
    }

    return {
      collection: { ...state.collection, ...collection },
      currentCollection,
      filters: data.filters || [],
      misc: data.misc || {},
      pager: {
        ...state.pager,
        ...pager,
      },
    };
  },

  /**
   * Generates and shows the error message
   *
   * @author  Mark Homoki
   * @version 1.0
   * @since   2017-07-21
   * @param   {object}	response 	API response
   * @return  {boolean}				True if found error, false if not found
   */
  error(response, showNotification = true) {
    if (!response) {
      return true;
    }

    if (response.status === 200) {
      return false;
    }

    if (response.status === 403) {
      fn.navigate("/logout");
    }


    const errors = [];
    this.getErrors(errors, response.data);
    if (showNotification === true) {
      fn.showNotification(errors[0], "error");
    }

    return true;
  },

  getErrors(array = [], errors) {
    if (_.isObject(errors)) {
      _.map(errors, (error) => this.getErrors(array, error));
    } else {
      // check if server error then return a shorter message
      if (_.size(errors) > 2000) {
        return array.push("Something went wrong. Please try again later.");
      }
      return array.push(errors);
    }

    return false;
  },
};
