import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import { url } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function SubmitApprove(props) {
  const classes = useStyles();

  const [requestForm, setRequestForm] = props.formData;
  const [notes, setNotes] = [
    requestForm.approverNotes,
    (notes) => {
      setRequestForm((form) => ({ ...form, approverNotes: notes }));
    },
  ];
  const [check, setCheck] = [
    requestForm.tcCheck,
    (notes) => {
      setRequestForm((form) => ({ ...form, tcCheck: notes }));
    },
  ];
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const handleCheckbox = (event) => {
    setCheck(event.target.checked);
  };
  return (
    <>
      <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <TextField
          id="outlined-multiline-static"
          label="Add any notes to your requester regarding data permissions"
          multiline
          placeholder=""
          variant="outlined"
          style={{ marginTop: "15px" }}
          onChange={handleNotesChange}
          value={notes}
        />
        {/* <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              name="checkedB"
              color="primary"
              onChange={handleCheckbox}
            />
          }
          label={
            <div>
              <span>By checking this box you agree to the Data sharing </span>
              <Link>
                <a href={url.da_termsandconditions} target="_blank">
                  terms & conditions
                </a>
              </Link>
            </div>
          }
        /> */}
      </FormControl>
    </>
  );
}
