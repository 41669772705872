import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Dialog, Form, Loader, TextInput } from "@xandanet/react-components";
import { api, fn } from "app/utils";
import { fetchData } from "app/actions";
import { url, cookie } from "app/constants";
import { PageTitle, SiteHeaderLogo } from "app/components";
import * as selector from "./selector";
import ReactGA from "react-ga";

export default class ExpiredPassword extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			submitting: false
		}
	}

  componentDidMount(){
    // make sure the user has come from login and has the right payload
    if(!this.props.location.state.user_id){
      return fn.navigate(`${url.login}`);
    }
  }

	handleInputChange = (name, value) => {
		this.setState({ [name]: value, wrongPassword: false });
	}

	handleSubmit = async () => {
		let response, error;
		this.setState({
			submitting: true,
			error: false
		})

		const formData = new FormData();
		formData.append('user_id', this.props.location.state.user_id)
		formData.append('current_password', this.state.current_password)
		formData.append('password', this.state.password)
		formData.append('password_confirmation', this.state.password_confirmation)

		response = await api.post('/account/change-password', formData);

		fn.hideNotification();
    if(!api.error(response)){
      fn.navigate('/login');
      fn.showNotification(`Your password has been updated successfully.`, 'success')
    }
		this.setState({submitting: false})
	}

	render() {
		return (
      <div className="login-page-wrap">
        <SiteHeaderLogo altColour={true} />

        <div className="page-content">
          <div className="page-content-inner">
							<div className="page-header">
								<PageTitle>Password Expired</PageTitle>
							</div>
            <div>
              <div className="form-description">
                <p>
                  Password requirements: at least 12 characters, a lowercase letter, an uppercase letter,
                  a number, no parts of your username. Your password cannot be any of your last 24 passwords.
                </p>
              </div>
              <Form onSubmit={this.handleSubmit}>
                <TextInput wide name="current_password" label="Current Password" validation="required" type="password" onChange={this.handleInputChange} className="input-main" value=""/>
                <TextInput wide name="password" label="New Password" validation="required" type="password" onChange={this.handleInputChange} className="input-main" value=""/>
                <TextInput wide name="password_confirmation" label="Confirm New Password" validation="required" type="password" onChange={this.handleInputChange} className="input-main" value=""/>

                <div className="form-submit">
                  <button className="button-primary" >
                    {!this.state.submitting
                      ? <span>Submit</span>
                      : <Loader />
                    }
                  </button>
                  {this.state.wrongPassword &&
                  <div className="warning-text">Wrong password. Please try again.</div>
                  }
                </div>

                <div className="action-forgotten-password">
                  <Link to={url.logout}> Sign out </Link>
                </div>
              </Form>
            </div>
						</div>
					</div>
				</div>
		);
	}
}
