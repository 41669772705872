import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

export default class BackButton extends React.PureComponent {
	handleClick(e) {
		e.preventDefault();
		browserHistory.goBack();
	}

	render() {
		return (
			<button type="button" className={`button-back ${this.props.className}`} onClick={this.handleClick}>{this.props.children}</button>
		);
	}
}

BackButton.propTypes = {
	className: PropTypes.string,
}

BackButton.defaultProps = {
	className: 'button',
}