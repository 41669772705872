import { createSelector } from 'reselect';
import _ from 'lodash';

const getProducts = state => state.product

const getProduct = (state, id) => {

	/**
	 * Recursively find the folder we need based on its id
	 *
	 * @param  object folder all folders
	 *
	 * @return object folder with matching id
	 */
	const getFolder = (folder) => {
		let matchingFolder = false
		_.each(folder.folders, (subFolder) => {
			if(subFolder.oid == id){
				matchingFolder = subFolder
				return matchingFolder
			}

			const selectedFolder = _.find(subFolder.folders, (folder) => {
				// check if either id field matches
				return (folder._id == id || folder.oid == id)
			})
			if(selectedFolder){
				matchingFolder = selectedFolder
			} else {
				getFolder(subFolder)
			}
		})

		if(false !== matchingFolder){
			return matchingFolder
		}
	}

	return !_.isEmpty(state.product.collection) ? getFolder(state.product.collection) : {}
}

export const getProductOptions = (state) => {
	if(!state.product.collection){
		return []
	}

	let productIdOptions = []

	const getFolders = (folder) => {
		_.map(folder.folders, (subFolder, index) => {
			// dont include container folders
			if(subFolder.name.toLowerCase() == 'dashboard' || subFolder.name.toLowerCase() == 'data table'){
				return null;
			}

			productIdOptions.push({
				id: subFolder._id,
				title: subFolder.name
			});
			if (!_.isEmpty(subFolder.folders)) {
				getFolders(subFolder)
			}
		})
	}

	getFolders(state.product.collection)

	return productIdOptions
}

const getProductDashboards = (state, productId, dataTable) => {
	if(_.isEmpty(state.product.collection)) {
		return {}
	}

	const product = getProduct(state, productId)
  const productType = (dataTable) ? 'Data Table' : 'Dashboard'

	if(!product){
		return {}
	}
	const dashboards = (_.find((product.folders || {}), {name: productType}) || {}).dashboards

	return _.uniqBy(_.sortBy(dashboards, 'title'), 'oid')
}

const getProductDashboard = (state, productId, dashboardId, dataTable) => {
	if(_.isEmpty(state.product.collection)) {
		return {}
	}

	const product = getProduct(state, productId)
  const productType = (dataTable) ? 'Data Table' : 'Dashboard'

	if(!product){
		return {}
	}

	const dashboards = (_.find((product.folders || {}), {name: productType}) || {}).dashboards
	if(!dashboards){
		return null
	}

	const currentDashboard = (dashboardId) ? _.find(dashboards, {oid: dashboardId}) : dashboards[0]

	return currentDashboard
}

export const makeGetProductDashboards = () => createSelector([getProductDashboards], obj => obj);
export const makeGetProductDashboard = () => createSelector([getProductDashboard], obj => obj);
export const makeGetProductOptions = () => createSelector([getProductOptions], obj => obj)
export const makeGetProducts = () => createSelector([getProducts], obj => obj)
export const makeGetProduct = () => createSelector([getProduct], obj => obj)
