import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 20,
    marginBottom: 10,
  },
  code: {
    backgroundColor: "#f5f5f5",
    padding: 10,
    borderRadius: 4,
    overflowX: "auto",
  },
  codePre: {
    margin: 0,
  },
}));

export default function UserManual() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>User Manual for Request LaunchPad</div>

      <div className={classes.sectionTitle}>Overview</div>
      <p>
        The <code>Request LaunchPad</code> component is designed to facilitate
        bulk data entry and submission for managing requests. It provides a
        user-friendly interface for selecting general partners, entering
        requested assets, and submitting the data.
      </p>

      <div className={classes.sectionTitle}>Key Features</div>
      <ul>
        <li>Autocomplete selection of general partners</li>
        <li>Editable table for entering requested assets and comments</li>
        <li>Tooltips for providing additional information</li>
        <li>Snackbar notifications for user feedback</li>
        <li>Dialog for bulk message editing and submission</li>
        <li>Review dialog for reviewing and confirming submissions</li>
        <li>Progress bar for indicating the submission process</li>
      </ul>

      <div className={classes.sectionTitle}>How to Use</div>
      <p>
        Follow these steps to use the <code>Request LaunchPad</code> component:
      </p>
      <ol>
        <li>
          <strong>Open the Create Request Dialog:</strong>
          <ul>
            <li>
              Click the <code>Create New Request(s)</code> button to open the
              dialog for bulk data entry.
            </li>
          </ul>
        </li>
        <li>
          <strong>Select General Partner:</strong>
          <ul>
            <li>
              Use the autocomplete field to search and select a general partner.
            </li>
            <li>
              The selected partner will be added as a new row in the table. To
              remove a partner, select it again in the autocomplete field.
            </li>
          </ul>
        </li>
        <li>
          <strong>Enter Requested Assets and Comments:</strong>
          <ul>
            <li>
              Fill in the requested assets and any comments for each general
              partner in the respective input fields.
            </li>
          </ul>
        </li>
        <li>
          <strong>Edit or Clear Messages:</strong>
          <ul>
            <li>
              Click the <code>Edit</code> button to open a dialog for editing
              the default message or adding a custom message.
            </li>
            <li>
              Click the <code>Clear</code> button to remove all comments from
              the table.
            </li>
          </ul>
        </li>
        <li>
          <strong>Review and Submit Requests:</strong>
          <ul>
            <li>
              Click the <code>Submit All</code> button to review the data before
              submission. A dialog will open displaying the captured information
              in a formatted table.
            </li>
            <li>
              In the review dialog, click <code>Confirm Submit</code> to send
              the data to the server or <code>Edit</code> to return to the
              previous screen for further changes.
            </li>
            <li>
              During the submission process, a progress bar will indicate the
              progress of sending requests to the general partners.
            </li>
            <li>
              Once the submission is successful, all dialogs will close and the
              state will be cleared. If there is an error, an alert will be
              shown with contact information for support.
            </li>
          </ul>
        </li>
      </ol>

      <div className={classes.sectionTitle}>Functionality Explanation</div>
      <p>
        Below is a detailed explanation of each functionality provided by the{" "}
        <code>Request LaunchPad</code> component:
      </p>

      <div className={classes.sectionTitle}>Buttons</div>
      <ul>
        <li>
          <code>Create New Request(s)</code>: Opens the dialog for entering bulk
          requests.
        </li>
        <li>
          <code>Clear</code>: Clears the selected input in the autocomplete
          field.
        </li>
        <li>
          <code>Submit All</code>: Opens the review dialog to review and submit
          all the entered requests to the server.
        </li>
        <li>
          <code>Cancel</code>: Closes the dialog without saving any changes.
        </li>
        <li>
          <code>Add</code>: Adds comments to all empty cells in the table.
        </li>
        <li>
          <code>Edit</code>: Opens the dialog for editing the default or custom
          message.
        </li>
        <li>
          <code>Clear</code>: Clears all comments in the table.
        </li>
        <li>
          <code>Confirm Submit</code>: Confirms the submission of all data in
          the review dialog.
        </li>
      </ul>

      <div className={classes.sectionTitle}>Autocomplete</div>
      <p>
        The autocomplete field allows you to search and select a general partner
        from a predefined list. The selected partner is added to the table, and
        you can remove it by selecting it again.
      </p>

      <div className={classes.sectionTitle}>Table</div>
      <p>
        The table displays the list of selected general partners along with
        fields for entering requested assets and comments. Each row in the table
        represents a request and includes the following columns:
      </p>
      <ul>
        <li>
          <strong>General Partner</strong>: The name of the selected general
          partner.
        </li>
        <li>
          <strong>Requested Assets</strong>: Input field for entering the
          requested assets.
        </li>
        <li>
          <strong>Comments</strong>: Input field for entering comments to the
          general partner.
        </li>
        <li>
          <strong>SFDR PAI</strong>: Checkbox to indicate SFDR PAI status.
        </li>
        <li>
          <strong>Deanonymization</strong>: Checkbox to indicate deanonymization
          status.
        </li>
        <li>
          <strong>Delete</strong>: Icon button to remove the row from the table.
        </li>
      </ul>

      <div className={classes.sectionTitle}>Tooltips</div>
      <p>
        Tooltips provide additional information for each column in the table.
        Hover over the column headers to view the tooltips.
      </p>

      <div className={classes.sectionTitle}>Snackbar Notifications</div>
      <p>
        Snackbar notifications provide feedback to the user upon submission of
        the requests. A success message indicates that the data was submitted
        successfully, while an error message indicates that there was an issue
        with the submission.
      </p>

      <div className={classes.sectionTitle}>Dialogs</div>
      <p>The component includes three dialogs:</p>
      <ul>
        <li>
          <strong>Create Request Dialog</strong>: Opens when the{" "}
          <code>Create New Request(s)</code> button is clicked. It contains the
          autocomplete field and the table for entering requests.
        </li>
        <li>
          <strong>Edit Message Dialog</strong>: Opens when the <code>Edit</code>{" "}
          button is clicked. It allows you to edit the default or custom message
          to be added to the comments field.
        </li>
        <li>
          <strong>Review Data Submission Dialog</strong>: Opens when the{" "}
          <code>Submit All</code> button is clicked. It allows you to review the
          data before submission, with options to confirm submission or edit the
          data. During submission, it shows a progress bar indicating the
          submission process.
        </li>
      </ul>

      <div className={classes.sectionTitle}>How to Edit Messages</div>
      <p>To edit messages in the comments field:</p>
      <ol>
        <li>
          Click the <code>Edit</code> button to open the Edit Message Dialog.
        </li>
        <li>
          Select either the default message or a custom message using the radio
          buttons.
        </li>
        <li>
          If you select a custom message, enter the message in the text field
          provided.
        </li>
        <li>
          Click <code>Save</code> to save the changes and close the dialog.
        </li>
        <li>
          Click <code>Add</code> to add the edited message to all empty comment
          fields in the table.
        </li>
      </ol>

      <div className={classes.sectionTitle}>How to Clear Messages</div>
      <p>
        To clear all comments in the table, click the <code>Clear</code> button
        next to the Edit button.
      </p>

      <div className={classes.sectionTitle}>How to Submit Requests</div>
      <p>
        Once all the data has been entered, click the <code>Submit All</code>{" "}
        button to open the Review Data Submission Dialog. Review the data and
        click <code>Confirm Submit</code> to send the requests to the server.
        During the submission process, a progress bar will indicate the
        submission progress. A snackbar notification will indicate whether the
        submission was successful or if there was an error. If the submission is
        not successful, an alert will be shown with contact information for
        support.
      </p>
    </div>
  );
}
