import { api } from 'app/utils';

const defaultState = {
	data: {},
	error: null,
	isLoading: true,
};

export function dashboard(state = defaultState, action) {
	switch	(action.type) {
		case 'DASHBOARD_PENDING': {
			return {
				...state,
				isLoading: true,
			};
		}
		case 'DASHBOARD_REJECTED': {
			return {
				...state,
				isLoading: false,
				error: action.payload.data,
			};
		}
		case 'DASHBOARD_FULFILLED': {
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};
		}
		default: {
			return state;
		}
	}
}

const dashboardFilterdefaultState = {
	data: {},
	error: null,
	isLoading: true,
};

export function dashboardFilter(state = dashboardFilterdefaultState, action) {
	switch	(action.type) {
		case 'DASHBOARDFILTER_PENDING': {
			return {
				...state,
				isLoading: true,
			};
		}
		case 'DASHBOARDFILTER_REJECTED': {
			return {
				...state,
				isLoading: false,
				error: action.payload.data,
			};
		}
    case 'DASHBOARDFILTER_RESET': {
			return dashboardFilterdefaultState;
		}
		case 'DASHBOARDFILTER_FULFILLED': {
      const values = !_.isEmpty(action.payload.values) ? action.payload.values : []
			return {
				...state,
				isLoading: false,
				data: {
          ...state.data,
          [action.payload.dashboard] : {
            ...state.data[action.payload.dashboard],
            [action.payload.instanceid]: {
              ...action.payload
            }
          }
        }
			};
		}
    case 'DASHBOARDFILTER_SET_MEMBERS': {
			return {
				...state,
				isLoading: false,
				data: {
          ...state.data,
          [action.payload.dashboard]: {
            ...state.data[action.payload.dashboard],
            [action.payload.key]: {
              ...state.data[action.payload.dashboard][action.payload.key],
              jaql: {
                ...state.data[action.payload.dashboard][action.payload.key].jaql,
                filter: {
                  ...state.data[action.payload.dashboard][action.payload.key].jaql.filter,
                  members: action.payload.members
                }
              }
            }
          }
        }
			};
		}
		default: {
			return state;
		}
	}
}
