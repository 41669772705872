import React from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from '@xandanet/react-components';

export default class FileUploadWithPreview extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.value !== nextProps.value) {
			this.setState({ value: nextProps.value })
		}
	}

	handleReset = () => {
		this.updateValue(null)
	}

	onChange = (name, value) => this.updateValue(value)

	updateValue = (value) => {
		this.setState({ value })
		this.props.onChange(this.props.name, value)
	}

	render() {
		const { value } = this.state

		if (value) {
			return (
				<div className="repeatable-row-file">
					<div className="filepreview">
						<img className="filepreview-image" src={value.preview ? value.preview : value} />
						<button className="filepreview-remove button-danger-tiny" onClick={this.handleReset} dangerouslySetInnerHTML={{__html: `&#x02A2F;`}} />
					</div>
				</div>
			);
		}

		return (
			<div className="repeatable-row-file">
				<FileUpload
					{...this.props}
					onChange={this.onChange}
					value={[]}
					accept=".jpg,.png,.pdf,.svg"
					required
				/>
			</div>
		);
	}
}
