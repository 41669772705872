import React from 'react';
import { fn } from 'app/utils';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

@connect((state) => {
	return {
		notifications: state.notification,
	}
})
export default class AppWrapper extends React.PureComponent {
	componentWillMount(){
		ReactGA.initialize('UA-134731508-1');
    // if the user has a return_to in the URL save it so we can use it post-login
    const returnTo = fn.getUrlParam('return_to');
    if (returnTo !== null && fn.isValidHttpUrl(returnTo)) {
      localStorage.setItem('loginReturnTo', returnTo);
    }
	}

	render() {
		return React.Children.map(this.props.children, child => React.cloneElement(child, {
			notifications: this.props.notifications,
		}));
	}
}
