import { createSelector } from 'reselect'
import _ from 'lodash'

const getLoginFactors = state => state.loginFactor
const getActiveLoginFactor = (state) => {
	if(_.isEmpty(state.loginFactor.data)){
		return {}
	}
	console.log('getActiveLoginFactor',state);

	let activeFactor;
	let pendingFactor;

	// get the first activefactor and the first pendingfactor
	state.loginFactor.data.forEach(o => {
    if(o.factorType === 'sms'){
  		if (o.status === 'ACTIVE' && !activeFactor) {
  			activeFactor = o
  		}
  		if (o.status === 'PENDING_ACTIVATION' && !pendingFactor) {
  			pendingFactor = o
  		}
    }
	});


	// find the first one you find
	if(activeFactor){
		return activeFactor
	} else if(pendingFactor){
		return pendingFactor
	}

	return {}
}
const getLoginFactor = (state, id) => _.find(state.loginFactor.data, {factorType: id})

export const makeGetActiveLoginFactor = () => createSelector([getActiveLoginFactor], obj => obj)
export const makeGetLoginFactors = () => createSelector([getLoginFactors], obj => obj)
export const makeGetLoginFactor = () => createSelector([getLoginFactor], obj => obj)
