import { createSelector } from 'reselect'
import { getProductOptions } from 'app/containers/product/selector'
import _ from 'lodash'

const getGroups = state => state.groups
const getReports = state => state.report
const getReport = (state, id) => state.report.collection[id] || {}

const getSelectedGroupOptions = (state, id) => {

	if(!((state.report || {}).collection[id] || {}).group_ids || !state.groups){
		return null;
	}

	return state.report.collection[id].group_ids.map((group_id) => {
		const group = _.find(state.groups.data, {'_id': group_id})

        return {
        	_id: group_id,
            ...group && { name: group.name }
        }
    })
}

const getSelectedProductOptions = (state, id) => {
	const productOptions = getProductOptions(state)
	const report = (state.report.collection || {})[id]

	if(!report || !report.product_ids || !productOptions){
		return null
	}

	const selectedProductOptions = _.map(report.product_ids, (product_id) => {
		const product = _.find(productOptions, {'id': product_id})

	    return {
	    	id: product_id,
	        ...product && { title: product.title }
	    }
	})

	return selectedProductOptions
}

export const makeGetGroups = () => createSelector([getGroups], obj => obj)
export const makeGetReports = () => createSelector([getReports], obj => obj)
export const makeGetReport = () => createSelector([getReport], obj => obj)
export const makeGetSelectedGroupOptions = () => createSelector([getSelectedGroupOptions], obj => obj)
export const makeGetSelectedProductOptions = () => createSelector([getSelectedProductOptions], obj => obj)
