import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import {
  ContentLoader,
  Table,
  Checkbox,
  Accordion,
  Radio,
  Select,
} from "@xandanet/react-components";
import { api, fn } from "app/utils";
import { url } from "app/constants";
import { fetchData } from "app/actions";
import { SearchInput, PageTitle } from "app/components";
import * as selector from "app/containers/product/selector";
import { makeGetDashboard, makeGetDashboardFilters } from "./selector";
import ReactGA from "react-ga";
import windowProxy from "porthole-proxy";

@connect((state, ownProps) => {
  const getProduct = selector.makeGetProduct();
  const getProducts = selector.makeGetProducts();
  const getProductDashboard = selector.makeGetProductDashboard();
  const getproductDashboards = selector.makeGetProductDashboards();
  const getDashboard = makeGetDashboard();
  const getDashboardFilters = makeGetDashboardFilters();

  const dataTable = ownProps.location.pathname.includes("data-table");

  return {
    product: getProduct(state, ownProps.params.productId),
    products: getProducts(state, dataTable),
    productDashboards: getproductDashboards(
      state,
      ownProps.params.productId,
      dataTable
    ),
    dashboard: getDashboard(state),
    dashboardFilters: getDashboardFilters(state),
    sisense: state.sisense,
  };
})
export default class View extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      product: {},
      products: {},
      dashbaord: {},
      showFilters: false,
    };
  }

  async componentDidMount() {
    await this.fetchFolders();
    await this.fetchDashboard();

    const { dashboard } = this.props;

    if (dashboard.data.title) {
      ReactGA.event({
        category: "Dashboard",
        action: "Load",
        label: `${dashboard.data.title} loaded`,
      });
    }
    window.addEventListener("message", this.receiveSisenseFilters, false);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { dashboard, location } = this.props;

    if (dashboard) {
      // this.fetchFilters();
    }

    if (prevProps.location.pathname !== location.pathname) {
      this.setState({ hideIframe: true });
      this.fetchDashboard();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveSisenseFilters);
  }

  receiveSisenseFilters = (e) => {
    if (!e.data.filterUpdate) {
      return;
    }

    const { jaql } = e.data.filterUpdate;
    let { members, all } = jaql.filter;
    const { dashboard, dashboardFilters } = this.props;

    const matchingFilters = _.filter(
      dashboardFilters.data[dashboard.data._id],
      (o) => o.jaql.dim === jaql.dim
    );

    // quit if we don't this filter in our sidebar
    if (_.isEmpty(matchingFilters)) {
      return;
    }

    const instanceid = _.head(matchingFilters).instanceid;

    if (!members && all) {
      const values = (
        dashboardFilters.data[dashboard.data._id][`${instanceid}`] || {}
      ).values;
      members = _.map(values, (o) => o.data);
    }

    if (members) {
      this.handleOnFilterChange(instanceid, members);
    }
  };

  currentDashboardId = () => {
    const { params, productDashboards } = this.props;
    console.log("this.props", this.props);

    if (params.dashboardId) {
      return params.dashboardId;
    } else {
      return (_.head(productDashboards) || {}).oid;
    }
  };

  async fetchFolders() {
    await this.props.dispatch(
      fetchData({
        type: "PRODUCT",
        url: `/sisense/folders`,
      })
    );
  }

  handleSelectDashboardChange = (name, value) => {
    const viewType = this.props.location.pathname.includes("data-table")
      ? "data-table"
      : "dashboard";
    if (value) {
      this.props.router.push(
        `/products/${this.props.params.productId}/${viewType}/${value}`
      );
    }
  };

  async fetchDashboard() {
    const dashboardId = this.currentDashboardId();
    if (dashboardId) {
      await this.props.dispatch(
        fetchData({
          type: "DASHBOARD",
          url: `/sisense/dashboards/${dashboardId}`,
          redirectOnError: false,
        })
      );
    }
    this.setState({ hideIframe: false });
  }

  fetchFilters = async () => {
    const { dashboard, dashboardFilters } = this.props;
    if (!dashboard) {
      return;
    }

    _.map(dashboard.data.filters, async (f) => {
      const { jaql, instanceid } = f;

      if (!(jaql || {}).dim) {
        return;
      }

      if (!(dashboardFilters.data[dashboard.data._id] || {})[instanceid]) {
        const response = await api.post(
          `sisense/elasticubes/${dashboard.data.title}/jaql`,
          {
            datasource: jaql.datasource,
            metadata: [{ dim: jaql.dim, sort: "asc" }],
          }
        );

        this.props.dispatch({
          type: "DASHBOARDFILTER_FULFILLED",
          payload: {
            dashboard: dashboard.data._id,
            instanceid,
            jaql,
            values: _.map(response.data.values, (v) => _.head(v)),
            datasource: response.data.datasource,
          },
        });
      }
    });
  };

  sendFilter = (name) => {
    const { dashboard, dashboardFilters } = this.props;
    const filterJaql = (
      ((dashboardFilters.data || {})[dashboard.data._id] || {})[name] || {}
    ).jaql;

    if (_.isEmpty(filterJaql)) {
      return;
    }
    // temporarily stop receiveing filters
    window.removeEventListener("message", this.receiveSisenseFilters);

    // override with selected options from the state
    filterJaql.filter.all = false;

    this.ifm.contentWindow.postMessage(
      {
        updateFilter: { jaql: filterJaql },
      },
      "https://sisense.esgdc.org"
    );

    // Delay receiving new filters to avoid queue issues
    window.addEventListener("message", this.receiveSisenseFilters, false);
  };

  handleOnFilterChange = async (name, value) => {
    const { dashboard, dashboardFilters } = this.props;

    if (!_.isArray(value)) {
      value = [value];
    }

    // opening the accordion triggers a change so check if values were changed
    const existingMembers =
      dashboardFilters.data[dashboard.data._id][name].jaql.filter.members || [];
    if (_.isEqual(existingMembers, value)) {
      return;
    }

    await this.props.dispatch({
      type: "DASHBOARDFILTER_SET_MEMBERS",
      payload: {
        dashboard: dashboard.data._id,
        key: name,
        members: value,
      },
    });
    this.sendFilter(name);
  };

  handleOnFilterReset = async () => {
    const { dashboard } = this.props;
    const response = await api.get(
      `sisense/dashboards/${dashboard.data.oid}/restore`
    );
    if (!api.error(response)) {
      // this.props.dispatch({
      //   type: 'DASHBOARDFILTER_RESET'
      // })
      // this.fetchFilters();
      this.ifm.contentWindow.postMessage(
        {
          refreshDashboard: true,
        },
        "https://sisense.esgdc.org"
      );
    }
  };

  handleToggleFilters = () =>
    this.setState({ showFilters: this.state.showFilters ? false : true });

  renderDashboardFilters = () => {
    const { dashboard, dashboardFilters } = this.props;
    const { showFilters } = this.state;

    if (_.isEmpty(dashboardFilters.data[dashboard.data._id])) {
      return;
    }

    const activeClass = showFilters ? "is-active" : "";

    return (
      <div className={`dashboard-filters filters ${activeClass}`}>
        <div className="filters-inner">
          <h2>Filter</h2>
          {_.map(
            _.orderBy(
              dashboardFilters.data[dashboard.data._id],
              "jaql.title",
              "asc"
            ),
            (f) => {
              const { selectedFilters } = this.state;
              const selected = f.jaql.filter.members;
              const selectedLabel =
                _.size(selected) > 0
                  ? _.size(selected) > 1
                    ? `(${_.size(selected)} selected)`
                    : `${selected}`
                  : ``;

              if (_.isEmpty(f.values)) {
                return;
              }

              return (
                <Accordion
                  title={
                    <React.Fragment>
                      {f.jaql.title}{" "}
                      <span className="selected">{selectedLabel}</span>
                    </React.Fragment>
                  }
                >
                  {f.jaql.filter.multiSelection ? (
                    <React.Fragment>
                      <span
                        className="selectall"
                        onClick={() =>
                          this.handleOnFilterChange(
                            f.instanceid,
                            _.map(f.values, (v) => v.data)
                          )
                        }
                      >
                        Select All
                      </span>

                      <Checkbox
                        name={f.instanceid}
                        options={f.values}
                        valueKey="data"
                        labelKey="text"
                        onChange={this.handleOnFilterChange}
                        value={selected}
                        wide
                      />
                    </React.Fragment>
                  ) : (
                    <Radio
                      name={f.instanceid}
                      options={f.values}
                      valueKey="data"
                      labelKey="text"
                      onChange={this.handleOnFilterChange}
                      value={selected}
                      wide
                    />
                  )}
                </Accordion>
              );
            }
          )}
          <span className="filters-reset" onClick={this.handleOnFilterReset}>
            Reset to Default Filters
          </span>
        </div>
      </div>
    );
  };

  render() {
    const {
      product,
      products,
      productDashboards,
      dashboardFilters,
      dashboard,
      sisense,
    } = this.props;

    const { hideIframe } = this.state;

    // Regex to remove numbering from Dashboard title and tabs
    if (!_.isEmpty(productDashboards)) {
      _.forEach(productDashboards, function(dashboard, i) {
        productDashboards[i].customTitle = productDashboards[i].title.replace(
          /^(\d+)./,
          ""
        );
      });
    }

    const viewType = this.props.location.pathname.includes("data-table")
      ? "data-table"
      : "dashboard";

    return (
      <div className="page-wrap">
        <ContentLoader data={dashboard.data} isLoading={dashboard.isLoading}>
          <div className="centering">
            <div className="page-header">
              {!_.isEmpty(productDashboards) && (
                <Select
                  className="dashboard-selector"
                  name="dashboards"
                  label=""
                  options={productDashboards}
                  valueKey="oid"
                  labelKey="customTitle"
                  value={dashboard.data.oid}
                  label=""
                  onChange={this.handleSelectDashboardChange}
                  skipInitialOnChangeCall={true}
                />
              )}
              <ul className="tabs dashboard-tabs">
                {!_.isEmpty(productDashboards) &&
                  _.map(
                    productDashboards,
                    (d) =>
                      d.oid && (
                        <li
                          className={
                            "tabs-tab " +
                            (dashboard.data.oid === d.oid ? "is-active" : "")
                          }
                        >
                          <Link
                            to={`/products/${
                              this.props.params.productId
                            }/${viewType}/${d.oid}`}
                          >
                            {d.customTitle}
                          </Link>
                        </li>
                      )
                  )}
              </ul>
              {/*<span className="button-filter dashboard-filter-toggle" onClick={this.handleToggleFilters}>Filter</span>*/}
            </div>

            {!hideIframe && (
              <div className="dashboard">
                <iframe
                  className="dashboard-iframe"
                  id="ifm"
                  frameBorder="0"
                  src={`https://sisense.esgdc.org/app/main#/dashboards/${
                    dashboard.data.oid
                  }?embed=true&r=false&edit=true`}
                  scrolling="auto"
                  ref={(f) => (this.ifm = f)}
                />
                {/* Only load the filters (that have jaql) when they have all been loaded */}
                {/*(_.filter(dashboard.data.filters, (o) => o.jaql ) || {}).length === Object.keys(dashboardFilters.data[dashboard.data._id] || {}).length &&
                  this.renderDashboardFilters()
                }*/}
              </div>
            )}
          </div>
        </ContentLoader>
      </div>
    );
  }
}
