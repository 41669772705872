import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  Snackbar,
  Typography,
  Grid,
  Tooltip,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  LinearProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EmailIcon from "@material-ui/icons/Email";
import { url } from "../../../constants";
import UserManual from "./UserManual";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    wordWrap: "break-word",
  },
  column_checkbox: {
    width: "20px",
  },
  column_id: {
    width: "200px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  tableRow: {
    height: "auto",
  },
  textField: {
    width: "100%",
    "& .MuiInputBase-root": {
      height: "auto",
    },
    "& .MuiOutlinedInput-inputMultiline": {
      minHeight: "40px",
      maxHeight: "80px",
      overflow: "auto",
      fontSize: "0.875rem",
    },
  },
  notesColumn: {
    padding: theme.spacing(1),
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      borderRadius: "10px",
    },
    "scrollbar-width": "thin",
    "scrollbar-color": "darkgrey lightgrey",
  },
  groupHeader: {
    fontWeight: "bold",
  },
  groupItems: {
    listStyleType: "none",
    padding: 0,
  },

  tableRowOdd: {
    backgroundColor: theme.palette.action.hover,
  },
  tableRowEven: {
    backgroundColor: theme.palette.common.white,
  },
  tooltipTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    fontSize: "0.875rem",
  },
  tooltipDescription: {
    fontSize: "0.75rem",
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  autocomplete: {
    width: "33vw",
    "& .MuiInputBase-input": {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
    },
  },
  clearButton: {
    marginLeft: theme.spacing(1),
    marginRight: "10%",
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  clearSearchButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
    },
  },
  editButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  reviewTable: {
    fontSize: "0.875rem",
  },
  emailIcon: {
    marginRight: theme.spacing(1),
  },
  option: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    "& .MuiCheckbox-root": {
      paddingLeft: "50px",
      transform: "scale(0.8)",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "0.85rem",
    },
  },
  groupHeader: {
    fontWeight: "bold",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    zIndex: 1,
    fontSize: "14px",
  },
  groupItems: {
    listStyleType: "none",
    padding: 0,
    paddingLeft: theme.spacing(2),
  },
  tooltipButtonTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    backgroundColor: "#3EB2B6",
    padding: theme.spacing(1),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    color: "#fff",
  },
  tooltipButtonDescription: {
    marginTop: 0,
    fontSize: "13px",
    backgroundColor: "#fff",
    padding: theme.spacing(1),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    color: "grey",
  },
  tooltipButton: {
    backgroundColor: "transparent",
    padding: 0,
    maxWidth: 220,
    border: "1px solid lightgrey",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function CreateBulkRequests(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rows, setRows] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [defaultNote] = useState("This is a default note.");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editMessage, setEditMessage] = useState("");
  const [radioValue, setRadioValue] = useState("default");
  const [manualDialogOpen, setManualDialogOpen] = useState(false);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentGP, setCurrentGP] = useState("");
  const [reload, setReload] = useState(false);
  const [isPremium, setIsPremium] = React.useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const baseURL = url.da_api_base + "/da_get_list_gp";
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "x-user-type": "GP",
      },
    };

    try {
      const response = await axios.request(config);
      if (response.data && response.data.Items) {
        setTableData(response.data.Items);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserTypeData = React.useCallback(async () => {
    const baseURL = url.da_api_base + "/da_get_usertype";

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "X-User-Id": props.userType.userId,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.Count > 0) {
        const userTypeData = response.data.Items[0];
        setIsPremium(userTypeData.premium);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, [props.token, props.userType.userId]);

  React.useEffect(() => {
    getUserTypeData().catch();
  }, [getUserTypeData]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleClearInput = () => {
    setInputValue("");
  };

  const addRow = (event, newValue) => {
    if (!newValue) return;

    const gpData = tableData.find((item) => item.company === newValue.company);

    if (!gpData) return;

    const exists = rows.some((row) => row.gp_CompanyName === newValue.company);
    if (exists) {
      setRows(rows.filter((row) => row.gp_CompanyName !== newValue.company));
    } else {
      const newRow = {
        id: rows.length,
        userId: props.userType.userId,
        user_FName: props.userType.firstName,
        user_LName: props.userType.lastName,
        user_CompanyName: props.userType.company,
        user_Comments: defaultNote,
        gp_userId: gpData.email,
        gp_FName: gpData.firstName,
        gp_LName: gpData.lastName,
        gp_CompanyName: newValue.company,
        selectedAssets: "",
        requester_deanonymization: true,
        requester_sfdr_pai: true,
      };
      setRows((prevRows) => {
        const updatedRows = prevRows.filter(
          (row) => row.gp_CompanyName !== newRow.gp_CompanyName
        );

        return [...updatedRows, newRow];
      });
    }
    setInputValue("");
  };

  const handleTextChange = (id, field) => (event) => {
    const { value } = event.target;
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleCheckboxChange = (id, field) => () => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, [field]: !row[field] } : row
      )
    );
  };

  const handleClearMessages = () => {
    setRows((prevRows) =>
      prevRows.map((row) => ({ ...row, user_Comments: "" }))
    );
  };

  const handleEditOpen = () => {
    setEditMessage("");
    setRadioValue("default");
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditSave = () => {
    setEditDialogOpen(false);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "default") {
      setEditMessage(defaultNote);
    } else {
      setEditMessage("");
    }
  };

  const handleEditMessageChange = (event) => {
    setEditMessage(event.target.value);
  };

  const handleAddMessage = () => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.user_Comments === ""
          ? {
              ...row,
              user_Comments:
                radioValue === "default" ? defaultNote : editMessage,
            }
          : row
      )
    );
  };

  const deleteRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const handleSubmit = async () => {
    setSending(true);
    const startTime = Date.now();

    const totalDuration = 10000; // 10 seconds
    const minDuration = 3000; // 3 seconds

    try {
      await axios.post(url.da_api_base + "/da_request_create", rows, {
        headers: {
          "x-request-type": "Create",
          "X-User-Type": "LP",
        },
        withCredentials: false,
      });
    } catch (error) {
      setSnackbarMessage(
        `Error submitting data. Please contact support@example.com`
      );
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      setSending(false);
      setReviewDialogOpen(false);
      return;
    }

    const endTime = Date.now();
    const elapsedTime = endTime - startTime;
    const remainingTime = Math.max(0, minDuration - elapsedTime);

    setProgress(100);
    setTimeout(() => {
      setSnackbarMessage("Request submitted successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      setSending(false);
      setReviewDialogOpen(false);
      setOpen(false);
      setRows([]);
      setInputValue("");
      setReload(true);
    }, remainingTime);
  };

  const handleReviewOpen = () => {
    setReviewDialogOpen(true);
  };

  const handleReviewClose = () => {
    setReviewDialogOpen(false);
  };

  const handleManualOpen = () => {
    setManualDialogOpen(true);
  };

  const handleManualClose = () => {
    setManualDialogOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#42BABA",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 10,
    },
  }))(TableCell);

  return (
    <div style={{ padding: 20 }}>
      {isPremium ? (
        <span>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
            disabled={!isPremium}
          >
            Create New Request(s)
          </Button>
        </span>
      ) : (
        <Tooltip
          title={
            <React.Fragment>
              <Typography className={classes.tooltipButtonTitle}>
                EDCI Premium
              </Typography>
              <Typography className={classes.tooltipButtonDescription}>
                Upgrade to Premium to request data from General Partners.
                Limited Partners can request EDCI tailored benchmark performance
                data sets of funds and portfolio companies from General Partners
                in a single workflow.
              </Typography>
            </React.Fragment>
          }
          classes={{ tooltip: classes.tooltipButton }}
          arrow
          placement="bottom"
          enterDelay={300}
          leaveDelay={200}
          interactive
        >
          <span>
            <Button color="primary" variant="contained" disabled={!isPremium}>
              {"Create New Request(s)"}
            </Button>
          </span>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "95%",
            maxWidth: "none",
            minHeight: "40%",
            maxHeight: "95%",
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          Request LaunchPad
          <IconButton
            aria-label="help"
            onClick={handleManualOpen}
            style={{ marginLeft: "10px", color: "#42BABA" }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <Autocomplete
                id="grouped-demo"
                className={classes.autocomplete}
                options={tableData.sort(
                  (a, b) => -b.company.localeCompare(a.company)
                )}
                groupBy={(option) => option.company[0].toUpperCase()}
                getOptionLabel={(option) => option.company}
                onChange={addRow}
                onInputChange={handleInputChange}
                inputValue={inputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select General Partner"
                    variant="outlined"
                  />
                )}
                renderOption={(option, { selected }) => (
                  <div className={classes.option}>
                    <Checkbox
                      checked={rows.some(
                        (row) => row.gp_CompanyName === option.company
                      )}
                      onChange={(e) => addRow(e, option)}
                      color="default"
                      height="5px"
                    />
                    {option.company}
                  </div>
                )}
                renderGroup={(params) => [
                  <div key={params.key} className={classes.groupHeader}>
                    {params.group}
                  </div>,
                  <ul className={classes.groupItems}>{params.children}</ul>,
                ]}
              />
              <Button
                variant="contained"
                onClick={handleClearInput}
                className={classes.clearSearchButton}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            style={{ marginTop: 20, maxHeight: "500px" }}
            className={classes.customScrollbar}
          >
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography className={classes.tooltipTitle}>
                          General Partner
                        </Typography>
                        <Typography className={classes.tooltipDescription}>
                          The selected General Partner (GP) will receive an
                          email requesting access to the assets entered in the
                          'Requested Assets' column. The email will include any
                          comments you add, and your preferences around company
                          names and SFDR data. After submitting your request,
                          you will see it in the 'New Request' tab. When the GP
                          has processed your request, it will appear in the
                          'Approved/Rejected' tab.
                        </Typography>
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                    enterDelay={300}
                    leaveDelay={200}
                    interactive
                    disableFocusListener={false}
                    disableHoverListener={false}
                    disableTouchListener={false}
                  >
                    <StyledTableCell>General Partner</StyledTableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography className={classes.tooltipTitle}>
                          Requested Assets
                        </Typography>
                        <Typography className={classes.tooltipDescription}>
                          Please add the assets you wish the General Partner
                          (GP) to grant you access to as free text. These assets
                          can include specific funds you are invested in or
                          individual portfolio companies
                        </Typography>
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                    enterDelay={300}
                    leaveDelay={200}
                    interactive
                    disableFocusListener={false}
                    disableHoverListener={false}
                    disableTouchListener={false}
                  >
                    <StyledTableCell>Requested assets</StyledTableCell>
                  </Tooltip>
                  <StyledTableCell>
                    <Grid container alignItems="center">
                      <Tooltip
                        title="Add comments to all empty cells"
                        arrow
                        placement="top"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddMessage}
                        >
                          Add
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Edit custom message"
                        arrow
                        placement="top"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleEditOpen}
                          className={classes.editButton}
                        >
                          Edit
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Clear all comments cells"
                        arrow
                        placement="top"
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleClearMessages}
                          className={classes.clearButton}
                        >
                          Clear
                        </Button>
                      </Tooltip>
                    </Grid>
                  </StyledTableCell>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography className={classes.tooltipTitle}>
                          SFDR PAI
                        </Typography>
                        <Typography className={classes.tooltipDescription}>
                          Please choose whether you would like the General
                          Partner (GP) to provide SFDR PAI data for their
                          portfolio companies. If you select this option, the GP
                          will be informed of your interest in receiving SFDR
                          PAI data and may grant you access when the data is
                          available.
                        </Typography>
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                    enterDelay={300}
                    leaveDelay={200}
                    interactive
                    disableFocusListener={false}
                    disableHoverListener={false}
                    disableTouchListener={false}
                  >
                    <StyledTableCell>SFDR PAI</StyledTableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography className={classes.tooltipTitle}>
                          Company Names
                        </Typography>
                        <Typography className={classes.tooltipDescription}>
                          Please choose whether you would like the General
                          Partner (GP) to reveal the actual names of their funds
                          and portfolio companies, or if you are happy to have
                          this shared anonymously. If you select this option,
                          the GPs will be asked to share portfolio company
                          names. Otherwise, GPs will be asked to share data
                          anonymously.
                        </Typography>
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                    enterDelay={300}
                    leaveDelay={200}
                    interactive
                    disableFocusListener={false}
                    disableHoverListener={false}
                    disableTouchListener={false}
                  >
                    <StyledTableCell>Company Names</StyledTableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography className={classes.tooltipTitle}>
                          Delete
                        </Typography>
                        <Typography className={classes.tooltipDescription}>
                          Delete the selected General Partner
                        </Typography>
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                    enterDelay={300}
                    leaveDelay={200}
                    interactive
                    disableFocusListener={false}
                    disableHoverListener={false}
                    disableTouchListener={false}
                  >
                    <StyledTableCell>Delete</StyledTableCell>
                  </Tooltip>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    className={
                      index % 2 === 0
                        ? classes.tableRowEven
                        : classes.tableRowOdd
                    }
                  >
                    <TableCell className={classes.column_id}>
                      {row.gp_CompanyName}
                    </TableCell>
                    <TableCell className={classes.notesColumn}>
                      <TextField
                        value={row.selectedAssets}
                        multiline
                        rows={4}
                        className={`${classes.textField} ${
                          classes.customScrollbar
                        }`}
                        variant="outlined"
                        placeholder="Please enter requested assets"
                        onChange={handleTextChange(row.id, "selectedAssets")}
                      />
                    </TableCell>
                    <TableCell className={classes.notesColumn}>
                      <TextField
                        className={`${classes.textField} ${
                          classes.customScrollbar
                        }`}
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="Please write notes to the GP"
                        value={row.user_Comments}
                        onChange={handleTextChange(row.id, "user_Comments")}
                      />
                    </TableCell>
                    <TableCell className={classes.column_checkbox}>
                      <Checkbox
                        checked={row.requester_sfdr_pai}
                        color="default"
                        onChange={handleCheckboxChange(
                          row.id,
                          "requester_sfdr_pai"
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.column_checkbox}>
                      <Checkbox
                        checked={row.requester_deanonymization}
                        color="default"
                        onChange={handleCheckboxChange(
                          row.id,
                          "requester_deanonymization"
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.column_checkbox}>
                      <IconButton onClick={() => deleteRow(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleReviewOpen}
            color="primary"
            variant="contained"
          >
            Submit All
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Message</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Message Type</FormLabel>
            <RadioGroup
              aria-label="message-type"
              name="message-type"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="default"
                control={<Radio />}
                label="Default"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            label="Message"
            multiline
            rows={4}
            value={editMessage}
            onChange={handleEditMessageChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={radioValue === "default"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={manualDialogOpen}
        onClose={handleManualClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>User Manual</DialogTitle>
        <DialogContent>
          <UserManual />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleManualClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={reviewDialogOpen}
        onClose={handleReviewClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle />
        <DialogContent>
          {sending ? (
            <div>
              <Typography variant="h6">
                <EmailIcon className={classes.emailIcon} />
                Sending data access requests to GPs
              </Typography>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {currentGP}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="h5">Review Submission</Typography>
              <TableContainer component={Paper}>
                <Table className={classes.reviewTable}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>General Partner</StyledTableCell>
                      <StyledTableCell>Requested Assets</StyledTableCell>
                      <StyledTableCell>Comments</StyledTableCell>
                      <StyledTableCell>SFDR PAI</StyledTableCell>
                      <StyledTableCell>Company Names</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.gp_CompanyName}</TableCell>
                        <TableCell>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: row.selectedAssets.replace(/\n/g, "<br>"),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: row.user_Comments.replace(/\n/g, "<br>"),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {row.requester_sfdr_pai ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {row.requester_deanonymization ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {sending ? null : (
            <>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                Confirm Submit
              </Button>
              <Button onClick={handleReviewClose} color="primary">
                Edit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnackbar(false);
          if (reload) {
            window.location.reload();
          }
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar(false);
            if (reload) {
              window.location.reload();
            }
          }}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

const useStylesToolTip = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "white",
    padding: theme.spacing(1),
    marginLeft: "0px",
    marginRight: "0px",
    color: "grey",
    fontSize: "0.75rem",
  },
  title: {
    fontSize: "1rem",
  },
  iconButton: {
    color: "#42BABA",
  },
}));
