import React from 'react';
import { Link } from 'react-router';
import { fn, api } from 'app/utils';
import { url } from 'app/constants';
import { ContactForm } from "./index";
import { Dialog, FileUpload, Form, TextInput, Select } from '@xandanet/react-components';
import ReactGA from 'react-ga';

export default class SiteFooter extends React.PureComponent {

	constructor(props){
		super(props)

		this.baseState = this.state
	}

	getDialogContent = () => (
		<ContactForm/>
	)

	render() {
		const currentYear = (new Date()).getFullYear()

		return (
			<footer className="footer" role="contentinfo">
				<div className="centering">
					<div className="footer-bottom">
						<nav className="footer-links">
							<ul className="menu footer-menu">
								<li className="menu-item">
									<a href='mailto:benchmarksupport@esgdc.org'>Contact Us</a>
								</li>
								<li className="menu-item"><a href="https://www.esgdc.org/" target='_blank'>About</a></li>
								<li className="menu-item"><a href="https://www.esgdc.org/privacy-policy/" target='_blank'>Privacy Policy</a></li>
							</ul>
						</nav>
						<div className="logos-wrapper">
							<Link to="https://expandresearch.com" onClick={() => ReactGA.event({category: 'Footer', action: 'Click', label: 'Expand Research link'})}><img className="logo" src="/images/Header_logo_TaglineWhite.svg" /></Link>
						</div>
					</div>
				</div>
			</footer>
		);
	}

}
