import React from 'react';
import { connect } from 'react-redux';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import { Loader } from '@xandanet/react-components';
import { SiteHeader, Notification, SiteFooter } from 'app/components';
import { TermsConditions } from 'app/containers/auth';

@connect((state) => {
	return {
		me: state.me
	};
})
export default class AuthorizedArea extends React.PureComponent {

	constructor(props) {
		super(props)
	}

	async componentDidMount() {
		fn.hideNotification()

		if (fn.isLoggedIn()) {
			// get logged in user data
			await this.props.dispatch(fetchData({
				type: 'ME',
				url: '/account/',
			}))
		} else {
			fn.navigate({
				pathname: url.logout,
				state: {
					referrer: this.props.location.pathname,
					showDefaultAlert: false,
				},
			})
		}
	}

	componentWillUpdate(nextProps) {
    const { me } = nextProps

    if(!_.isEmpty(me.data)){
      if(1 !== me.data.mfa){
        fn.navigate('/logout');
      }
    }

		if (nextProps.notifications.show && (nextProps.location.pathname !== this.props.location.pathname)) {
			fn.hideNotification()
		}
	}

	render() {
		const { me } = this.props

		// return loader while its fetching
		if (me.isLoading) {
			return <Loader />
		}

		// if(me.data.terms_and_conditions != 1) {
    //
		// 	return (
		// 		<div className={`site not-logged-in`}>
		// 			<div className="site-content site-content-login">
		// 				<TermsConditions {...this.props} />
		// 			</div>
		// 		</div>
		// 	)
		// }
		// return the content when its fetched
		if (!this.props.me.isLoading) {
			const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, {
				me: me,
			}))

			// dont show the background on some pages
			let backgroundImage = true;
			if(this.props.location.pathname.includes('dashboard') || this.props.location.pathname.includes('data-table')){
				backgroundImage = false;
			}

			return (
				<div className={`site ${backgroundImage ? "has-background":""}`}>
					<SiteHeader {...this.props} loggedIn />
          <Notification data={this.props.notifications} />
          {childrenWithProps}
					<SiteFooter />
				</div>
			);
		}

		return null;
	}
}
