import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import store from 'app/store';
import routes from 'app/routes';
import { createTheme, ThemeProvider } from "@material-ui/core";

const history = syncHistoryWithStore(browserHistory, store);
const app = document.getElementById('app');
const theme = createTheme({
   palette: {
      primary: {
         main: "#42BABA",
		 contrastText: "#fff"
                },
			}
		});
ReactDOM.render((
	<Provider store={store}>
		<ThemeProvider theme={theme}>
		<Router history={history} routes={routes()} />
		</ThemeProvider>
	</Provider>
), app);

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
	if (module.hot) {
		module.hot.accept();
		ReactDOM.render((
			<AppContainer>
				<Provider store={store} key={Math.random()}>
					<ThemeProvider theme={theme}>
					<Router history={history} routes={routes()} />
					</ThemeProvider>
				</Provider>
			</AppContainer>
		), app);
	}
}
