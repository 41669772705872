import React from "react";
import { IndexRedirect, IndexRoute, Route, Redirect } from "react-router";
import { fn } from "app/utils";

import { url } from "app/constants";
import * as C from "app/containers";

import * as Auth from "app/containers/auth";
import * as NotFound from "app/containers/404";
import * as Report from "app/containers/report";
import * as Methodology from "app/containers/methodology";
import * as Product from "app/containers/product";
import * as Dashboard from "app/containers/dashboard";
import * as Page from "app/containers/page";

import * as PowerBIGroups from 'app/containers/powerbi-groups';
import * as PowerBIReports from 'app/containers/powerbi-reports';

import * as AdminReport from "app/containers/admin/report";
import * as AdminMethodology from "app/containers/admin/methodology";
import * as AdminPage from "app/containers/admin/page";
import * as AccessManagement from "app/containers/access-management";

export default function Routes() {
  const returnTo = fn.getUrlParam("return_to");
  let queryParams;
  if (returnTo != "") {
    queryParams = { return_to: returnTo };
  }

  return (
    <Route path="/" component={C.AppWrapper}>
      <IndexRedirect to={`/groups`} query={queryParams} />

      <Route component={C.AuthenticationArea}>
        <Route path={url.login} component={Auth.Login} />

        {/* FORCE USERS TO USE SMS */}
        {/*<Route path={`${url.login}/verify`} component={Auth.Verify} />*/}
        <Redirect from={`${url.login}/verify`} to={`${url.login}/verify/sms`} />

        <Route path={`${url.login}/verify/:factorId`} component={Auth.Enroll} />
        <Route
          path={`${url.login}/verify/:factorId/validate`}
          component={Auth.Validate}
        />
        <Route path={url.logout} component={Auth.Logout} />
        <Route
          path={`${url.login}/password-expired`}
          component={Auth.ExpiredPassword}
        />
        <Route path={url.forgotPassword} component={Auth.ForgotPassword} />
        {/*<Route path={`${url.resetPassword}/:token`} component={Auth.ResetPassword} />*/}
      </Route>

      <Route
        path={`${url.termsConditions}/accept`}
        component={Auth.TermsConditions}
      />

      <Route component={C.AdminArea}>
        <Route path={url.admin} accessLevel={["admin"]}>
          <Route path="reports">
            <IndexRoute component={AdminReport.List} />
            <Route path="add" component={AdminReport.Edit} />
            <Route path=":reportId" component={AdminReport.Edit} />
          </Route>

          <Route path="resources">
            <IndexRoute component={AdminMethodology.List} />
            <Route path="add" component={AdminMethodology.Edit} />
            <Route path=":methodologyId" component={AdminMethodology.Edit} />
          </Route>

          <Route path="pages">
            <IndexRoute component={AdminPage.List} />
            <Route path="add" component={AdminPage.Edit} />
            <Route path=":pageId" component={AdminPage.Edit} />
          </Route>
        </Route>
      </Route>

      <Route component={C.AuthorizedArea}>
        <Route path="groups">
          <IndexRoute component={PowerBIGroups.List} />
          <Route path=":groupId/report/:reportId" component={PowerBIReports.View} />
          <Route path=":groupId/report/:reportId/page/:pageId" component={PowerBIReports.View} />
          <Route path=":groupId/report/:reportId/methodologies" component={Methodology.List} />
          <Route
              path=":groupId/access-management"
              component={AccessManagement.View}
          />
          <Route path="all" component={PowerBIGroups.List} />
        </Route>
      </Route>

      <Route component={C.AuthorizedArea}>
        <Route path="products">
          <Redirect from=":productId" to=":productId/dashboard"/>
          <IndexRoute component={Product.List}/>

          <Route path=":productId/resources" component={Methodology.List}/>
          <Route path=":productId/reports" component={Report.List}/>
          <Route path=":productId/dashboard" component={Dashboard.View}/>
          <Route path=":productId/dashboard/:dashboardId" component={Dashboard.View}/>

          <Route path=":productId/data-table" component={Dashboard.View} dataTable>
            <Route path=":dashboardId" component={Dashboard.View} dataTable/>
          </Route>
          <Route
            path=":productId/access-management"
            component={AccessManagement.View}
          />
          <Route path="all" component={Product.List} />
        </Route>

        <Route path="page/:pageSlug" component={Page.View} />
      </Route>

      <Route path="*" component={NotFound.View} />
    </Route>
  );
}
