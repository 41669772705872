import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Table } from '@xandanet/react-components';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import { SearchInput } from 'app/components';
import * as selector from './selector';
import ReactGA from "react-ga";

const selectGroup = group_id => (state, props) => {
    return { selectedGroup: group_id }
}

@connect((state) => {
    const getGroups = selector.makeGetPowerBIGroups();
    return {
        powerBIGroups: getGroups(state),
    };
})

export default class List extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            selectedGroup: null,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.dispatch(fetchData({
            type: 'POWER_BI_GROUP',
            url: '/powerbi/groups-access',
        }));
    }

    selectGroup = group_id => () => {
        if(group_id === this.state.selectedGroup){
            this.setState({selectedGroup: null})
        } else {
            this.setState(selectGroup(group_id))
        }
    }

    buildGroup = (group, index, totalItems) => {
        const { selectedGroup } = this.state;

        let stateClassName = ''
        if(group.group_id === selectedGroup || 1 === totalItems){
            stateClassName = 'product-selected'
        } else if (selectedGroup){
            stateClassName = 'product-not-selected'
        }

        return (
            <li className={`product-entry ${stateClassName}`} onClick={this.selectGroup(group.group_id)} key={group.group_id}>
                <div className="product-entry-inner">
                    <span className="product-name">{group.alias}</span>
                    <span className="product-name-alt">{group.alias}</span>
                    {group.reports &&
                        <ul className={`product-children count-` + group.reports.length}>
                            {_.map(group.reports, childReport => {
                                return (
                                    <li className="product-child" key={childReport.id}>
                                        <span className="child-title">{childReport.name}</span>
                                        <span className="child-desc">{childReport.description}</span>
                                        <Link className="button product-child-button" to={`/groups/${group.group_id}/report/${childReport.report_id}${childReport.pages.length > 0 ? `/page/${childReport.pages[0]}` : ''}`} onlyActiveOnIndex>View
                                            Report</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    }
                </div>
            </li>
        )
    }


    render() {
        const { powerBIGroups } = this.props;

        console.log("POWERBI PROPS",this.props);

        return (
            <ContentLoader
                data={powerBIGroups.collection}
                isLoading={powerBIGroups.isLoading}
            >
                <div className="page-header">
                    <div className="centering">
                        <h1 className="page-title">Dashboard</h1>
                    </div>
                </div>
                <div className="main centering">
                    <div className="products">
                        <ul className={`product-list`}>
                            {_.map(powerBIGroups.collection, (group, index) => this.buildGroup(group, index, powerBIGroups.collection.length))}
                        </ul>
                    </div>
                </div>
            </ContentLoader>
        );
    }

}
