import React from 'react';

export default class PageTitle extends React.PureComponent {

	render() {
		const title = this.props.children;
		const { alt } = this.props;
		const altClass = alt ? 'page-title-alt' : 'page-title';
		return (
			<h1 className={altClass}>{title}</h1>
		)
	}
}
