import React from 'react';
import { fetchData } from 'app/actions';
import {connect} from "react-redux";
import {Link} from "react-router";
import ReactGA from "react-ga";
import {ContentLoader, Select} from "@xandanet/react-components";
import { api } from "app/utils";
import * as selector from "./selector";

import * as pbi from 'powerbi-client';

// Ensure the Power BI types are available
import * as models from 'powerbi-models';

import {postData} from "../../actions";
import {makeGetPowerBIReports} from "./selector";

@connect((state) => {
    const getReport = selector.makeGetPowerBIReports();
    return {
        powerBIReports: getReport(state),
    };
})

export default class View extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            selectedGroup: null,
        };
        this.embeddedReport = null;
        this.powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
        );
    }

    componentDidMount() {
        this.getEmbedToken();
    }

    componentDidUpdate(prevProps) {
        // Check if the embed token has changed and call embedReport
        if (this.props.powerBIReports?.collection?.token && prevProps.powerBIReports?.collection?.token !== this.props.powerBIReports?.collection?.token) {
            // Check if pageId param is passed
            if (this.props.params.pageId) {
                this.embedReport(this.props.params.pageId);
            } else {
                // else redirect to with the page id /groups/:groupId/report/:reportId/:pageId
                this.props.router.push(`/groups/${this.props.params.groupId}/report/${this.props.params.reportId}/page/${this.props.powerBIReports.collection.pages[0].name}`);
                this.embedReport(this.props.params.pageId);
            }
        }

        // Check if pageId is null
        if (!this.props.params.pageId) {
            // Redirect to the first page
            if(this.props.powerBIReports?.collection?.pages){
                this.props.router.push(`/groups/${this.props.params.groupId}/report/${this.props.params.reportId}/page/${this.props.powerBIReports.collection.pages[0].name}`);
            } else {
                this.props.router.push(`/groups`);
            }
        }

        // Add check for pageId change
        if (prevProps.params.pageId !== this.props.params.pageId && this.props.params.pageId !== null) {
            this.setPage(this.props.params.pageId);
        }

        // Add check for reportId or groupId change and clear existing report
        if (prevProps.params.reportId !== this.props.params.reportId || prevProps.params.groupId !== this.props.params.groupId) {
            if (this.embeddedReport) {
                this.powerbi.reset(document.getElementsByClassName('reportContainer')[0]);
            }
            this.getEmbedToken();
        }

    }

    // Function to call API
    getEmbedToken = async() => {
        this.props.dispatch(postData({
            type: 'POWER_BI_REPORT',
            url: '/powerbi/reports/embed-token',
            data: {
                groupId: this.props.params.groupId,
                reportId: this.props.params.reportId,
            },
        }));
    }

    // Function to embed Power BI report
    embedReport = (pageName) => {
        console.log("BOUT TO GEN", pageName, this.props);
        // Get report container
        const reportContainer = document.getElementsByClassName('reportContainer')[0];

        // Remove existing report if present
        if (this.embeddedReport) {
            this.powerbi.reset(reportContainer);
        }

        // Create a new report
        this.embeddedReport = this.powerbi.embed(reportContainer, {
            type: 'report',
            accessToken: this.props.powerBIReports.collection.token,
            id: this.props.params.reportId,
            embedUrl: this.props.powerBIReports.collection.embedUrl,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.All,
            viewMode: models.ViewMode.View,
            pageName: pageName,
            settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                zoomLevel: 1
            }
        });

        // Report event listeners
        this.embeddedReport.on('loaded', () => {
            console.log('Report loaded');
        });

        this.embeddedReport.on('rendered', () => {
            console.log('Report rendered');
        });

        this.embeddedReport.on('error', (event) => {
            console.error('Error', event.detail);
        });
    }

    // Function to set the page of the embedded report
    setPage = (pageName) => {
        if (this.embeddedReport) {
            this.embeddedReport.setPage(pageName)
                .catch(error => {
                    console.error('Error setting page', error);
                });
        }
    }

    render() {
        const { powerBIReports, powerBIGroups } = this.props;
        console.log("VIEW REPORT PROPS",this.props);

        return (
            <div className="page-wrap">
                <ContentLoader data={powerBIReports.collection} isLoading={powerBIReports.isLoading}>
                    <div className="centering">
                        <div className={"page-header"}>
                            <ul className="tabs dashboard-tabs">
                                {!_.isEmpty(powerBIReports.collection.pages) &&
                                    powerBIReports.collection.pages.map((page, index) => {
                                        return (
                                            <li key={index} className={`tabs-tab ${page.name === this.props.params.pageId ? "is-active" : ""}`}>
                                                <Link to={`/groups/${this.props.params.groupId}/report/${this.props.params.reportId}/page/${page.name}`}>{page.displayName}</Link>
                                            </li>
                                        );
                                    })
                                }

                            </ul>
                        </div>
                        <div className="powerBIReportWrapper">
                            <div className={"reportContainer"}>

                            </div>
                        </div>
                    </div>
                </ContentLoader>
            </div>
        );

        // return (
        //     <div className="page-wrap">
        //         <ContentLoader data={powerBIReports?.collection} isLoading={powerBIReports?.isLoading}>
        //             <div className="centering">
        //                 <div className={"powerBIReportWrapper"}>
        //                     <ul className="tabs dashboard-tabs">
        //                         {!_.isEmpty(powerBIReports?.collection?.pages) &&
        //                             powerBIReports?.collection?.pages.map((page, index) => {
        //                                 return (
        //                                     <li key={index} className={`tabs-tab ${page.name === this.props.params.pageId ? "is-active" : ""}`}>
        //                                         <Link to={`/groups/${this.props.params.groupId}/report/${this.props.params.reportId}/page/${page.name}`}>{page.displayName}</Link>
        //                                     </li>
        //                                 );
        //                             })
        //                         }
        //
        //                     </ul>
        //                     <div className={"reportContainer"}>
        //
        //                     </div>
        //                 </div>
        //             </div>
        //         </ContentLoader>
        //     </div>
        // );
    }

}
