import React from "react";
import { connect } from "react-redux";
import { fetchData } from "app/actions";
import { api, fn } from "app/utils";
import { url } from "app/constants";
import { SiteHeaderLogo } from "app/components";
import { Sms } from "./validate-factors";
import * as selector from "./selector";

@connect((state, ownProps) => {
  const getLoginFactors = selector.makeGetLoginFactors();
  const getActiveLoginFactor = selector.makeGetActiveLoginFactor();

  return {
    loginFactors: getLoginFactors(state),
    activeLoginFactor: getActiveLoginFactor(state)
  };
})
export default class Validate extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {sent:false}

		if(!fn.isLoggedIn()){
			fn.navigate(url.login)
		}

		if(!((this.props.location || {}).state || {}).response){
			fn.navigate('/login/verify')
		}
	}

	componentDidMount() {
		this.fetchData()
	}

	componentDidUpdate(prevProps, prevState) {
		// currently pending activation
		if('PENDING_ACTIVATION' === this.props.activeLoginFactor.status){
			this.setState({sent: true})
		}

	}

	fetchData = () => {
		this.props.dispatch(fetchData({
			type: 'LOGINFACTOR',
			url: 'account/login/factors'
		}))


	}

	handleInputChange = (name, value) => this.setState({ [name]: value })

	handleSubmit = async () => {
		const { activeLoginFactor } = this.props

		if(!activeLoginFactor.id){
			return
		}

		const formData = new FormData()

		formData.append('factorId', activeLoginFactor.id)

		if(this.state.passCode){
			formData.append('passCode', this.state.passCode)
		}

		let response
		if('PENDING_ACTIVATION' === activeLoginFactor.status){
			response = await api.post('/account/login/activate-factor', formData);
		} else {
			response = await api.post('/account/login/validate-factor', formData);
		}

		if (api.error(response)) {
			fn.showNotification(response.data, 'error')
		}
		if(response.data.factorResult === 'CHALLENGE'){
			this.setState({'sent':true})
		}
		if(response.data.factorResult === 'SUCCESS'){
      const getTokenUrl = `${url.sisenseApi}v1/getcookie`;
      const redirectTo = localStorage.getItem('loginReturnTo') ?? encodeURIComponent(window.location.origin + "/products");

      localStorage.removeItem('loginReturnTo');

      const params = {

        jwt: fn.getCookie("jwt_token"),
        return_to: encodeURIComponent(
          `${getTokenUrl}?return_to=${redirectTo}&url=${url.expandApi}/account/login-callback`,
        )
      };

			window.location.href = 'https://sisense.esgdc.org/jwt' + fn.buildUrlQuery(params, '?')
		}
	}

	render() {

		if((!this.props.location.state || {}).response){
			return null
		}

		const { response } = this.props.location.state

		return (
      <div className="login-page-wrap">
        <SiteHeaderLogo altColour={true} />

        {(response || {}).factorType === 'sms' &&
        <Sms loginFactor={response} sent={this.state.sent} handleSubmit={this.handleSubmit} handleInputChange={this.handleInputChange} />
        }

      </div>
		);
	}
}
