import React from 'react';
import { Form, TextInput } from '@xandanet/react-components';
import { PageTitle } from 'app/components';

export default class Sms extends React.PureComponent {

	render() {
		return (
      <div className="page-content">
        <div className="page-content-inner">
          <div className="page-header">
            <PageTitle>2-Step Verification</PageTitle>
          </div>
          <div>
            <div className="form-description">
              <p>Enter the phone number you'll use to receive codes via text message then click Send Code to verify that it works.</p>
					</div>

					<Form onSubmit={this.props.handleSubmit} className="">
						<TextInput
							name="telephone"
							onChange={this.props.handleInputChange}
							wide
							className="input-main"
							value="+44"
							label="TELEPHONE"
							validation="required"
							autoFocus
						/>
						<div className="form-submit no-border">
							<button className="button-primary">Send Code</button>
						</div>
					</Form>
				</div>
			</div>
      </div>
		);
	}
}
