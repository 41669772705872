const host = "http://localhost";
const port = 8081;

const daApiBaseLocal = {
  host: "http://127.0.0.1",
  port: 5000
}

export const url = {
  login: "/login",
  logout: "/logout",
  account: "/account",
  resetPassword: "/reset-password",
  forgotPassword: "/forgot-password",
  termsConditions: "/terms-and-conditions",

  admin: "/admin",

  notFound: "/404",

  sisenseApi: "https://sisense.esgdc.org/api/",

  da_api_base:
    process.env.NODE_ENV === "production"
      ? "https://l5oceu8iug.execute-api.eu-west-2.amazonaws.com/production"
      : process.env.NODE_ENV === "development"
      ? "https://chd5yx6r6b.execute-api.eu-west-2.amazonaws.com/staging"
      : "http://127.0.0.1:5000",
  da_termsandconditions:
    "https://da-email-images.s3.eu-west-2.amazonaws.com/terms-conditions/T%26C+EDCI.pdf",
  expandApi:
    process.env.NODE_ENV === "production"
      ? "https://portal-api.esgdc.org"
      : "https://api.expand-edcp.hostings.co.uk",
    // : "http://localhost:8081"
};

export const cookieDomain = (() => {
  switch (process.env.NODE_ENV) {
    case "production":
      return ".esgdc.org";
    case "development":
      return ".expandresearch.com";
    case "local":
      return `localhost`;
  }
})();
