import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import PendingRequests from "./pending-requests";
import RequestHistory from "./requests-history";
import axios from "axios";
import { url } from "../../../constants";
import CreateNewRequest from "./new-request";
import CreateBulkRequests from "./bulk-requests";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function LP_dashboard(props) {
  const [value, setValue] = React.useState(0);
  const [requestData, setRequestData] = React.useState([]);
  const [isPremium, setIsPremium] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    customTabRoot: {
      color: "#42BABA",
    },
    customTabIndicator: {
      backgroundColor: "#42BABA",
    },
    leftAlignTab: {
      justifyContent: "flex-start",
    },
    tooltipButtonTitle: {
      fontWeight: "bold",
      fontSize: "18px",
      backgroundColor: "#3EB2B6",
      padding: theme.spacing(1),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      color: "#fff",
    },
    tooltipButtonDescription: {
      marginTop: 0,
      fontSize: "13px",
      backgroundColor: "#fff",
      padding: theme.spacing(1),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      color: "grey",
    },
    tooltipButton: {
      backgroundColor: "transparent",
      padding: 0,
      maxWidth: 220,
      border: "1px solid lightgrey",
      borderRadius: theme.shape.borderRadius,
    },
  }));

  const classes = useStyles();

  const getRequestData = React.useCallback(async () => {
    const baseURL = url.da_api_base + "/da_request_get";

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "X-User-Id": props.userType.userId,
        "X-User-Type": props.userType.type,
        "account-view": true,
        //authorization: "Bearer " + props.token,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.Count > 0) {
        const data = response.data.Items;
        data.sort((a, b) =>
          a.updated_at > b.updated_at || a.created_at > b.created_at
            ? -1
            : a.updated_at < b.updated_at || a.created_at < b.created_at
            ? 1
            : 0
        );
        setRequestData(data);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, [props.userType.userId, props.userType.type]);

  const getUserTypeData = React.useCallback(async () => {
    const baseURL = `${url.da_api_base}/da_get_usertype`;

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "X-User-Id": props.userType.userId,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.Count > 0) {
        const userTypeData = response.data.Items[0];
        setIsPremium(userTypeData.premium);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, [props.userType.userId]);

  React.useEffect(() => {
    getRequestData().catch(console.error);
    getUserTypeData().catch(console.error);
  }, [getRequestData, getUserTypeData]);

  return (
    <div className="page-wrap">
      <div className="centering">
        <div
          className="page-header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2>Limited Partner Access Management</h2>

          <CreateBulkRequests token={props.token} userType={props.userType} />
        </div>

        <Paper square>
          <Tabs
            variant="fullWidth"
            value={value}
            indicatorColor="primary"
            classes={{
              root: classes.customTabRoot,
              indicator: classes.customTabIndicator,
            }}
            onChange={handleChange}
            aria-label="tabs example"
          >
            {!isPremium && (
              <Tab
                label="Approved/Revoked Data Access"
                className={classes.leftAlignTab}
              />
            )}
            {isPremium && <Tab label="New Requests" />}
            {isPremium && <Tab label="Approved/Rejected Requests" />}
          </Tabs>
        </Paper>
        {isPremium && (
          <TabPanel value={value} index={0}>
            <PendingRequests
              data={requestData.filter((x) => x.status_id === "CREATED")}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={isPremium ? 1 : 0}>
          <RequestHistory
            data={requestData.filter((x) => x.status_id !== "CREATED")}
          />
        </TabPanel>
      </div>
    </div>
  );
}
