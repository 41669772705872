import React from 'react';
import { fn, api } from 'app/utils';
import { url } from 'app/constants';

export default class Logout extends React.PureComponent {

	async componentDidMount() {
		await fn.logOut();
		fn.navigate('/login');
	}

	render() {
		return null;
	}
}
