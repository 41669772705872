import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Form, TextInput, FileUpload, button, MultiSelect, Radio } from '@xandanet/react-components';
import { api, fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import { Meta, Notification, FileUploadWithPreview } from 'app/components';
import * as selector from 'app/containers/report/selector';
import { makeGetProducts, makeGetProductOptions } from '../../product/selector';

@connect((state, ownProps) => {
	const getReport = selector.makeGetReport()
	const getGroups = selector.makeGetGroups()
	const getSelectedGroupOptions = selector.makeGetSelectedGroupOptions()
	const getSelectedProductOptions = selector.makeGetSelectedProductOptions()
	const getProducts = makeGetProducts()
	const getProductOptions = makeGetProductOptions()

	return {
		report: getReport(state, ownProps.params.reportId),
		productOptions: getProductOptions(state),
		products: getProducts(state),
		selectedGroupOptions: getSelectedGroupOptions(state, ownProps.params.reportId),
		groups: getGroups(state),
		selectedProductOptions: getSelectedProductOptions(state, ownProps.params.reportId)
	};
})
export default class Edit extends React.PureComponent {

	constructor(props) {
		super(props);

		// assume we already have a file if loading an existing report
		const newFile = (props.params.reportId) ? false : true
		this.state = {
			file: this.props.report.file_path,
			productOptions: this.props.productOptions
		}
	}

	componentDidMount() {
		if(this.props.params.reportId){
			this.loadReport(this.props)
		}
		this.loadSisenseGroups(this.props)
		this.loadProducts(this.props)
	}

	componentWillReceiveProps(nextProps){
		if(this.props.params.reportId != nextProps.params.reportId){
			this.loadReport(nextProps)
		}

		if (this.props.report.file_path != nextProps.report.file_path) {
			this.setState({ file: nextProps.report.file_path })
		}
	}

	loadReport(props) {
		this.props.dispatch(fetchData({
			type: 'REPORT',
			url: `/admin/report/${props.params.reportId}`,
		}))
	}

	loadProducts(props){
		this.props.dispatch(fetchData({
			type: 'PRODUCT',
			url: `/sisense/folders`,
		}))
	}

	loadSisenseGroups(props) {
		this.props.dispatch(fetchData({
			type: 'GROUP',
			url: `/sisense/groups`,
		}))
	}

	handleSubmit = async () => {
		const formData = new FormData()

		// check if the user has uploaded a new file
		if(this.state.file !== this.props.report.file_path){
			formData.append('file', this.state.file)
		}

		if(this.state.group_ids){
			_.map(this.state.group_ids, (group, id) => {
				formData.append(`group_ids[${id}]`, group._id);
			});
		}

		if(this.state.product_ids){
			_.map(this.state.product_ids, (product, id) => {
				formData.append(`product_ids[${id}]`, product.id);
			});
		}

		formData.append('type', this.state.type)
		formData.append('title', this.state.title)
		formData.append('keywords', this.state.keywords)

		// default to blank if this is a new report
		const reportId = (this.props.params.reportId) ? this.props.params.reportId : ''
		const response = await api.update(`/report/${reportId}`, formData)
		const error = api.error(response)

		if(!error){
			fn.navigate(`/admin/reports/${response.data.id}`);
			fn.showNotification(`The report has been successfully ${reportId ? 'updated' : 'created'}.`, 'success')
		}
		/// ELSE SHOW AN ERROR
		return true
	}

	handleInputChange = (name, value) => this.setState({ [name]: value });

	handleRemoveFile = () => {
		this.props.dispatch(
			{
				type: 'REPORT_UPDATE',
				payload: {
					'id': this.props.report.id,
					'new_file': ''
				}
			}
		)
	}

	handleRemoveFile = () => {
		this.setState({file: null})
	}

	handleDeleteReport = async () => {
		if (window.confirm('Are you sure you wish to delete this report?')) {
			const response = await api.delete(`/report/${this.props.report.id}`)
			const error = api.error(response)
			if(!error){
				this.props.dispatch({
					type: 'REPORT_DELETED',
					payload: {'id': this.props.report.id }
				})
				fn.navigate('/admin/reports');
				fn.showNotification(`You have successfully deleted ${this.state.title}`, 'success');
			}
		}
	}

	render() {
		const {
			report,
			groups,
			selectedGroupOptions,
			productOptions,
			selectedProductOptions
		} = this.props;

		return (
			<div className="page-wrap with-header">
				<div className="page-content-wrap with-bg">
					<div className="centering">
						<div className="page-header">
							<h1>{this.props.params.reportId ? 'Edit' : 'Create'} Report</h1>
							<div className="page-header-actions">
								<button className="button-danger" onClick={this.handleDeleteReport}>Delete</button>
							</div>
						</div>
						<Form onSubmit={this.handleSubmit}>
							<div className="box">
								<h3 className="box-heading">Details</h3>
								<TextInput
									name="title"
									label="Title"
									autoComplete="off"
									onChange={this.handleInputChange}
									value={report.title}
									validation="required"
									wide
								/>
								<TextInput
									name="keywords"
									label="Search Keywords"
									value={report.keywords}
									onChange={this.handleInputChange}
									textarea={true}
									wide
								/>
								<Radio
									name="type"
									label="Type"
									layout="vertical"
									validation="required"
									options={fn.getReportTypes()}
									value={report.type}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="box">
								<h3 className="box-heading">Permissions</h3>
								<MultiSelect
									name="group_ids"
									label="Groups"
									options={_.values(groups.data)}
									onChange={this.handleInputChange}
									labelKey="name"
									valueKey="_id"
									value={selectedGroupOptions}
								/>
								<MultiSelect
									name="product_ids"
									label="Products"
									onChange={this.handleInputChange}
									value={selectedProductOptions}
								  	options={productOptions}
								/>
							</div>
							<div className="box">
								<h3 className="box-heading">File Download</h3>
								{!this.state.file || (this.state.file || {}).preview ? (
									<div>
										<FileUpload
										  name="file"
										  label=""
										  value=""
										  onChange={this.handleInputChange}
										  validation="required"
										/>
									</div>
								) : (
									<div class="form-group form-type-upload">
										<div class="form-field-wrapper">
										<div class="form-field">
												{report.original_file_name} <button type="button" className="remove button-danger" title="Remove File" onClick={this.handleRemoveFile}>Remove</button>
											</div>
										</div>
									</div>
								)}
							</div>
							<div>
								<button className="button form-submit">{this.props.params.reportId ? 'Update' : 'Submit'}</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		);
	}

}
