import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Table } from '@xandanet/react-components';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import * as selector from 'app/containers/page/selector';

@connect((state) => {
	const getPages = selector.makeGetPages()

	return {
		pages: getPages(state)
	}
})

export default class List extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 1,
			pages: {}
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const queryArgs = {
			'page': this.state.currentPage,
			'status': '1',
			'orderby': 'created',
			'order': 'desc'
		}
		this.props.dispatch(fetchData({
			type: 'PAGE',
			url: `/page${fn.buildUrlQuery(queryArgs, '?')}`,
		}))
	}

	handlePageChange = (currentPage) => {
		this.setState({ currentPage }, this.fetchData);
	}

	render() {
		const {
			pages,
		} = this.props;

		console.log(pages)

		return (
			<div className="page-wrap">
				<div className="centering">
					<div className="page-header">
						<h1>Pages</h1>

						<div className="page-header-actions">
							<Link to="/admin/pages/add" className="button">Add New</Link>
						</div>
					</div>
					<section className="box list pages">
						<ContentLoader
							data={pages.currentCollection}
							isLoading={pages.isLoading}
							noResults="No pages can be found."
						>
							<span className="pagination-status">(Showing items {pages.pager.to} of {pages.pager.total})</span>
							<table className="list-table">
								<thead>
									<tr>
										<th className="col-title">Title</th>
										<th className="col-date">Created</th>
									</tr>
								</thead>
								<tbody>
									{_.map(pages.currentCollection, (id, index) => {
										const page = _.find(pages.collection, {'id' : id})
										return (
											<tr key={`page${index}`}>
												<td><Link to={`/admin/pages/${page.id}`}>{page.title}</Link></td>
												<td><Link to={`/admin/pages/${page.id}`}>{fn.formatDate(page.created_at)}</Link></td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</ContentLoader>
					</section>
				</div>
			</div>
		);
	}

}
