import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Backdrop, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { url } from "../../../constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer,
    color: "#fff",
  },
  shortDescription: { padding: "10px", fontSize: "12px" },
}));
export default function CreateNewRequest(props) {
  /// Dialog
  const [open, setOpen] = React.useState(false);
  let requestErrorMsg = "";
  let submitSuccess = false;
  const setSubmitSuccess = (g) => {
    submitSuccess = g;
  };

  const handleClickOpen = () => {
    setOpen(true);
    setGp({});
    setSelectedFunds("");
    setComments("");
    requestErrorMsg = "";
  };
  const handleClose = () => {
    setOpen(false);
  };

  /// Select
  const classes = useStyles();
  const [gpList, setGPList] = React.useState([]);
  const [gp, setGp] = React.useState({});
  const [selectedFunds, setSelectedFunds] = React.useState("");
  const [comments, setComments] = React.useState("");
  const [submitInProgress, setSubmitInProgress] = React.useState(false);

  const getGPList = React.useCallback(async () => {
    const baseURL = url.da_api_base + "/da_get_list_gp";

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        //authorization: "Bearer " + props.token,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.Count > 0) {
        const data = response.data.Items;
        setGPList(data);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, []);

  /// Snackbar
  const [sbState, setSbState] = React.useState({
    openSb: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });
  const { vertical, horizontal, openSb, severity, message } = sbState;

  const handleClickSb = (newState) => async () => {
    if (!submitInProgress) await postRequestData();

    if (submitSuccess) {
      setSbState({
        severity: "success",
        message:
          "Your Data Access Request is submitted! You'll get an email communication from the General Partner.",
        openSb: true,
        ...newState,
      });
      props.onSubmitHandler();
    } else if (!submitSuccess)
      setSbState({
        severity: "error",
        message: "Failed: " + requestErrorMsg + ".",
        openSb: true,
        ...newState,
      });
    setOpen(!submitSuccess);
  };

  const postRequestData = async () => {
    setSubmitInProgress((x) => !x);

    let data = JSON.stringify({
      userId: props.userType.userId,
      user_FName: props.userType.firstName,
      user_LName: props.userType.lastName,
      user_CompanyName: props.userType.company,
      user_Comments: comments,
      gp_userId: gp.email,
      gp_FName: gp.firstName,
      gp_LName: gp.lastName,
      gp_CompanyName: gp.company,
      selectedAssets: selectedFunds,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url.da_api_base + "/da_request_create",
      headers: {
        "Content-Type": "application/json",
        "X-User-Type": "LP",
        "X-Request-Type": "Create",
        Authorization: "Bearer " + props.token,
      },
      data: data,
      withCredentials: false,
    };

    try {
      const resp = await axios.request(config);

      if (resp.status === 200) setSubmitSuccess(true);
    } catch (error) {
      console.log(error);
      requestErrorMsg = error.response.data;
      setSubmitSuccess(false);
    }

    setSubmitInProgress(false);
  };

  const handleCloseSb = () => {
    setSbState({ ...sbState, openSb: false });
  };

  React.useEffect(() => {
    getGPList().catch();
  }, [getGPList]);

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        style={{ backgroundColor: "#42BABA" }}
        onClick={handleClickOpen}
      >
        Create New Request
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Data Access Request
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom className={classes.shortDescription}>
            If you are a Limited Partner seeking access to funds managed by an
            EDCI general partner, use this form to submit your request.
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Autocomplete
              id="gp-select-list"
              options={gpList.sort((a, b) =>
                a.company.localeCompare(b.company)
              )}
              getOptionLabel={(option) => option.company}
              renderOption={(option, props) => (
                <Typography>{"" + option.company}</Typography>
              )}
              filterSelectedOptions={false}
              onChange={(e, value) =>
                value
                  ? setGp(gpList.filter((x) => x.email == value.email)[0])
                  : setGp({})
              }
              // style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="textarea-esg-user-select"
                  label="Type or Select General Partner Name"
                  variant="outlined"
                />
              )}
            />
            <TextField
              id="outlined-multiline-static"
              label="Companies/Funds (For access to specific companies/funds, list them here separated by a comma)"
              multiline
              maxRows={1}
              value={selectedFunds}
              onChange={(e) => setSelectedFunds(e.target.value)}
              placeholder="Enter Companies/Funds"
              variant="outlined"
              style={{ marginTop: "20px" }}
            />
            <TextField
              id="outlined-multiline-static-comments"
              label="Comments to General Partner"
              multiline
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Comments"
              variant="outlined"
              style={{ marginTop: "20px" }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClickSb({ vertical: "top", horizontal: "right" })}
            color="primary"
            variant="contained"
            style={{ backgroundColor: "#42BABA" }}
          >
            Submit Request
          </Button>
        </DialogActions>

        <Backdrop className={classes.backdrop} open={submitInProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Snackbar
        open={openSb}
        autoHideDuration={6000}
        onClose={handleCloseSb}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleCloseSb} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
