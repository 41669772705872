import React from 'react';
import { SiteHeader, SiteFooter } from 'app/components';
import { Content } from './';

export default class View extends React.PureComponent {
	render() {
		return (
			<div className="site with-header">
				<SiteHeader {...this.props} loggedIn />
				<div className="site-content">
					<Content />
				</div>
				<SiteFooter />
			</div>
		);
	}
}
