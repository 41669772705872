import React, { useCallback, useState } from "react";
import { url } from "../../constants";
import { fn, api } from "app/utils";
import axios from "axios";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
}));
export default function TCDialog(props) {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [tcChecked, setTcChecked] = useState(false);
  const [sbState, setSbState] = React.useState({
    openSb: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });
  const { vertical, horizontal, openSb } = sbState;
  const classes = useStyles();

  const handleClickSb = async (newState) => {
    if (!tcChecked) {
      setSbState({
        severity: "error",
        message: "Please accept Terms and Conditions to submit form" + ".",
        openSb: true,
        ...newState,
      });
      return;
    }
    await patchRequestTCAccepted(newState);
  };
  const handleCloseSb = () => {
    setSbState({ ...sbState, openSb: false });
  };
  const patchRequestTCAccepted = async (newState) => {
    setSubmitInProgress((x) => !x);

    let data = JSON.stringify({
      tcAccepted: true,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url.da_api_base + "/da_set_user_metadata",
      headers: {
        "Content-Type": "application/json",
        "X-User-Id": props.userType.userId,
      },
      data: data,
      withCredentials: false,
    };

    try {
      const resp = await axios.request(config);

      if (resp.status === 200) {
        setSbState({
          severity: "success",
          message: "Thank you for Accepting T&C" + ".",
          openSb: true,
          ...newState,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setSbState({
        severity: "error",
        message: "An Error occured, Please try after sometime" + ".",
        openSb: true,
        ...newState,
      });
    }

    setSubmitInProgress(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={props.open}
        scroll="paper"
        className={classes.dialogPaper}
      >
        <DialogTitle id="customized-dialog-title">
          Data Sharing Terms & Conditions
          <Typography style={{ color: "grey", fontSize: "small" }}>
            Before using the Data Sharing feature, please review our Terms &
            Conditions
          </Typography>
        </DialogTitle>

        <DialogContent dividers style={{ justifyContent: "flex-start" }}>
          <FormControlLabel
            style={{ margin: "10px" }} // Changed from padding to margin for better CSS practices
            control={
              <Checkbox
                onChange={(e) => setTcChecked(e.target.checked)}
                checked={tcChecked}
                color="primary"
              />
            }
            label={
              <span style={{ fontSize: "small" }}>
                {" "}
                {/* Changed div to span for correct nesting */}
                By checking this box, you agree to the Data Sharing{" "}
                <a
                  href={url.da_termsandconditions}
                  target="_blank"
                  rel="noopener noreferrer" // Added noreferrer for security reasons
                  style={{ color: "blue" }}
                >
                  Terms & Conditions
                </a>
                .
              </span>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" component="a" href="/">
            {" "}
            {/* Changed wrapping to use Button's component prop */}
            Later
          </Button>
          <Button
            onClick={() =>
              handleClickSb({ vertical: "top", horizontal: "right" })
            }
            color="primary"
            variant="contained"
          >
            Agree & Continue
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={submitInProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <Snackbar
        open={openSb}
        autoHideDuration={6000}
        onClose={handleCloseSb}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleCloseSb} severity={sbState.severity}>
          {sbState.message}
        </Alert>
      </Snackbar>
    </>
  );
}
