import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

// reducers
import notification from './notification';
import product from 'app/containers/product/reducer';
import report from 'app/containers/report/reducer';
import methodology from 'app/containers/methodology/reducer';
import page from 'app/containers/page/reducer';

import powerBIGroups from 'app/containers/powerbi-groups/reducer';
import powerBIReports from 'app/containers/powerbi-reports/reducer';
import allPowerBiGroups from 'app/containers/admin/powerbi-groups/reducer';

import me from './me';
import groups from './groups';
import categories from './categories';
import sisense from './sisense';
import loginFactor from 'app/containers/auth/reducer';
import {dashboard, dashboardFilter} from 'app/containers/dashboard/reducer';
import contact_options from './contact_options';
import header from './header';

const appReducer = combineReducers({
    notification,
    report,
    methodology,
    product,
    powerBIGroups,
    powerBIReports,
    allPowerBiGroups,
    page,
    me,
    groups,
    sisense,
    dashboard,
    dashboardFilter,
    loginFactor,
    header,
    categories,
    routing: routerReducer,
    contactOptions: contact_options
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = {};
    }

    if (action.type === '@@router/LOCATION_CHANGE') {
        window && window.scrollTo(0, 0);
    }

    if (action.type === 'RESET_COLLECTION') {
        state[action.collection] = undefined;
    }

    return appReducer(state, action);
};
export default rootReducer;
