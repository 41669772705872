import React from 'react';
import { connect } from 'react-redux'
import { fetchData } from 'app/actions';
import { api, fn } from 'app/utils';
import { url } from 'app/constants';
import { SiteHeaderLogo } from 'app/components';
import { Sms } from './enroll-factors';
import * as selector from './selector'
import ReactGA from "react-ga";

@connect((state, ownProps) => {
	const getLoginFactors = selector.makeGetLoginFactors()
	const getLoginFactor = selector.makeGetLoginFactor()

	return {
		loginFactors: getLoginFactors(state),
		loginFactor: getLoginFactor(state, ownProps.params.factorId)
	}
})
export default class Enroll extends React.PureComponent {
	constructor(props) {
		super(props);

		if(!fn.isLoggedIn()){
			fn.navigate(url.login)
		}
	}

	async componentDidMount() {
		await this.fetchData()

		// check if any factors are enabled
		if(((this.props || {}).loginFactor || {}).status === 'ACTIVE'){
			fn.navigate({pathname:`/login/verify/${this.props.params.factorId}/validate`, state: {response: this.props.loginFactor}})
		}
	}

	async fetchData() {
		await this.props.dispatch(fetchData({
			type: 'LOGINFACTOR',
			url: 'account/login/factors'
		}))

	}

	handleInputChange = (name, value) => this.setState({ [name]: value })

	handleSubmit = async () => {
		const { loginFactor } = this.props

		const formData = new FormData()
		formData.append('factorType', this.props.params.factorId)
		formData.append('provider', 'OKTA')

		if(this.state.telephone){
			formData.append('profile[phoneNumber]', this.state.telephone)
		}

		const response = await api.post('/account/login/enroll-factor', formData);

		if (api.error(response)) {
			fn.showNotification(response.data, 'error')
		} else {
			// added 2fa phone
			ReactGA.event({
				category: 'Authorization',
				action: 'Load',
				label: `Second factor phone number added`
			})

			fn.navigate({ pathname: `/login/verify/${this.props.params.factorId}/validate`, state: { response: response.data }})
		}
	}

	render() {
		const { loginFactor } = this.props

		return (
      <div className="login-page-wrap">
        <SiteHeaderLogo altColour={true} />

        {this.props.params.factorId === 'sms' &&
          <Sms loginFactor={loginFactor} handleSubmit={this.handleSubmit} handleInputChange={this.handleInputChange} />
        }

      </div>
		);
	}
}
