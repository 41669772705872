import { api } from 'app/utils';

const defaultState = {
  data: [],
  error: null,
  isLoading: true,
};

export default function reducer(state = defaultState, action) {
  switch	(action.type) {
    case 'CATEGORY_PENDING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'CATEGORY_REJECTED': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
      };
    }
    case 'CATEGORY_FULFILLED': {
      return {
        ...state,
        isLoading: false,
        data:  [...action.payload.data],
      };
    }
    default: {
      return state;
    }
  }
}
