import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { fn } from "app/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    marginTop: "2%",
    padding: "10px",
    fontSize: "12px",
  },
}));

export default function ReviewRequest(props) {
  const classes = useStyles();
  const [requestForm] = props.formData;
  console.log(props.formData);
  const isRequested1 = Boolean(props.prefilledForm?.requester_deanonymization);
  console.log("COMPANY NAMES");
  console.log(isRequested1);
  const isRequested2 = Boolean(props.prefilledForm?.requester_sfdr_pai);
  console.log("SFDRPAI");
  console.log(isRequested2);

  return (
    <>
      {props.prefilledForm && (
        <Paper className={classes.paper} elevation={1}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <strong>Requester: </strong>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>{props.prefilledForm.requester_company}</div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong>Requester Type: </strong>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>{fn.renderUserType(requestForm.user_type)}</div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong>Date Created: </strong>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>
                {fn.formatDate(props.prefilledForm.created_at) +
                  " (" +
                  fn.prettyDate(props.prefilledForm.created_at) +
                  ")"}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong>Requested Assets: </strong>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>{props.prefilledForm.requester_assets}</div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong>Notes: </strong>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>{props.prefilledForm.requester_notes}</div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong>Company Names: </strong>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>
                {props.prefilledForm.requester_deanonymization
                  ? "Requested"
                  : "Not Requested"}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong>SFDR PAI: </strong>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>
                {props.prefilledForm.requester_sfdr_pai
                  ? "Requested"
                  : "Not Requested"}
              </div>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
