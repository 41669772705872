import React from 'react';
import {SortDocument} from './index';
import {api, fn} from 'app/utils'
import {DropTarget} from 'react-dnd';
import moment from 'moment';

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        item: monitor.getItem()
    }
}

const dragTarget = {
    drop(props, monitor, component) {
        const droppedItem = monitor.getItem();
    }
};

class CreateDocument extends React.Component {

    state = {
        isDownloading: false,
        container: {}
    }

    createPDF = async () => {
        const allDocument = this.props.documents;

        // Check document exists
        if (_.isEmpty(allDocument)) {
            return;
        }

        this.setState({
            isDownloading: true
        });

        let imageIds = '';
        _.map(allDocument, (document, index) => {
            imageIds += document.id + ',';
        })

        const url = `methodology/${this.props.productId}/pdf?image_ids=${imageIds}&product_name=${this.props.productName}`;

        const res = await api.get(url, {
            responseType: 'blob',
        });

        if (!api.error(res)) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [res.data],
                {type: 'application/pdf'});

            const linkUrl = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = linkUrl;
            link.setAttribute('download', `bespoke-${this.props.productName}-report-${moment().format('YYYYMMDD')}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.setState({
                isDownloading: false
            })
        }
    }

    componentDidMount() {
        this.updateDocument();
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.updateDocument()
    }

    updateDocument = () => {
        let container = document.getElementById('methodologies');
        container = container.getBoundingClientRect();
        this.setState({
            container: container
        })
    }

    moveDocument = (items) => {
        if (items) {
            this.props.moveDocument(items);
        }
    }

    render() {
        const {documents, connectDropTarget, hovered, item} = this.props;
        const {isDownloading, container} = this.state;

        return connectDropTarget(
            <section className="document" style={{ top: `${ container.top ? container.top : 0 }px`}}>
               <div className="document-wrapper">
                    <section className="document-header">
                        <h2>Create your document</h2>
                        <p className="document-subtitle">Drag and drop slides to your document</p>
                    </section>

                    <section className="document-body">
                        <SortDocument
                            documents={documents}
                            moveDocument={(items) => this.moveDocument(items)}
                        >
                            <div className={`add-newItem ${hovered ? 'hovered' : ''}`}></div>
                        </SortDocument>
                    </section>

                    <footer className="document-footer">
                        <button className="button-document"
                                disabled={_.isEmpty(documents)}
                                onClick={this.createPDF}>
                            {isDownloading ? <i className="icon ion-load-a rotate"></i> : 'CREATE PDF'}
                        </button>
                    </footer>
                </div>
            </section>
        )
    }
}

export default DropTarget('image', dragTarget, collect)(CreateDocument);
