import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import { url } from "../../../../constants";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import {
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { fn } from "app/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function SelectLP(props) {
  const classes = useStyles();
  const [requestForm, setRequestForm] = props.formData;
  const [lpList, setLPList] = React.useState([]);
  const [userType, setUserType] = React.useState(requestForm.user_type);

  const handleChange = (lpItem) => {
    setRequestForm((form) => ({
      ...form,
      userId: lpItem.email,
      user_FName: lpItem.firstName,
      user_LName: lpItem.lastName,
      user_CompanyName: lpItem.company,
      user_type: lpItem.type,
    }));
  };

  const getLPList = async () => {
    const baseURL = url.da_api_base + "/da_get_list_gp";

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        "x-user-type": userType,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.Count > 0) {
        const data = response.data.Items;
        setLPList(data);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    setLPList([]);
    if (userType !== "") getLPList().catch();
    if (userType !== requestForm.user_type) {
      setRequestForm((form) => ({
        ...form,
        userId: "",
        user_FName: "",
        user_LName: "",
        user_type: "",
        user_CompanyName: "",
        selectedAssets: "",
        approvedAssets: [],
        approverNotes: "",
        approvedAccessLevels: "",
      }));
    }
  }, [userType]);

  return (
    <>
      <Typography
        gutterBottom
        style={{ padding: "10px", fontSize: "12px", marginBottom: "-5px" }}
      >
        Share data with a Limited Partner or a Private Creditor?
      </Typography>
      <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel id="userTypeLabel">Select</InputLabel>
        <Select
          id="demo-simple-select-outlined"
          labelId="userTypeLabel"
          value={userType}
          onChange={(e) => {
            setUserType(e.target.value);
          }}
          label="Select"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"LP"}>Limited Partner</MenuItem>
          <MenuItem value={"PC"}>Private Creditor</MenuItem>
        </Select>
      </FormControl>
      {userType && (
        <>
          <Typography
            gutterBottom
            style={{
              padding: "10px",
              fontSize: "12px",
              marginBottom: "-5px",
            }}
          >
            Select the {fn.renderUserType(userType)} you wish to share data with
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Autocomplete
              id="lp-select-list"
              options={lpList.sort((a, b) =>
                a.company.localeCompare(b.company)
              )}
              getOptionLabel={(option) => option.company}
              renderOption={(option) => (
                <Typography>{"" + option.company}</Typography>
              )}
              filterSelectedOptions={false}
              onChange={(e, value) =>
                value
                  ? handleChange(
                      lpList.filter((x) => x.email === value.email)[0]
                    )
                  : handleChange({
                      email: "",
                      firstName: "",
                      lastName: "",
                      company: "",
                    })
              }
              // style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="textarea-esg-user-select"
                  label={`Type or Select User Name`}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </>
      )}
    </>
  );
}
