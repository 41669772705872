import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Form, TextInput, FileUpload, button, Repeater, MultiSelect } from '@xandanet/react-components';
import { api, fn } from 'app/utils';
import { fetchData } from 'app/actions';
import { FileUploadWithPreview, Meta, Notification } from 'app/components';
import { makeGetProducts, makeGetProductOptions } from 'app/containers/product/selector';
import * as selector from 'app/containers/methodology/selector';
import * as adminPowerBISelector from "../powerbi-groups/selector";

@connect((state, ownProps) => {
	const getMethodology = selector.makeGetMethodology()
	const getProducts = makeGetProducts()
	const getProductOptions = makeGetProductOptions()
	const getSelectedProductOptions = selector.makeSelectedProductOptions()
	const getAllPowerBiGroups = adminPowerBISelector.makeGetAllPowerBIGroups();
	const getSelectedAdminGroupOptions = selector.makeSelectedAdminGroupOptions();

	return {
		productOptions: getProductOptions(state),
		products: getProducts(state),
		methodology: getMethodology(state, ownProps.params.methodologyId),
		selectedProductOptions: getSelectedProductOptions(state, ownProps.params.methodologyId),
		categories: state.categories?.data,
		selectedAdminGroupOptions: getSelectedAdminGroupOptions(state, ownProps.params.methodologyId),
		allPowerBIGroups: getAllPowerBiGroups(state),
	};
})
export default class Edit extends React.PureComponent {

	constructor(props) {
		super(props);

		// assume we already have a file if loading an existing methodology
		const newFile = (props.params.methodologyId) ? false : true
		this.state = {
			file: this.props.methodology.file_path,
			productOptions: this.props.productOptions
		}
	}

	componentDidMount() {
		if(this.props.params.methodologyId){
			this.loadMethodology(this.props);
		}
		this.loadProducts(this.props)
		this.loadCategories()
	}

	componentWillReceiveProps(nextProps){
		if(this.props.params.methodologyId != nextProps.params.methodologyId){
			this.loadMethodology(nextProps);
		}

		if (this.props.methodology.file_path != nextProps.methodology.file_path) {
			this.setState({ file: nextProps.methodology.file_path })
		}
	}

	loadProducts(props){
		// this.props.dispatch(fetchData({
		// 	type: 'PRODUCT',
		// 	url: `/sisense/folders`,
		// }))
		const { allPowerBIGroups } = this.props;
		if (!allPowerBIGroups.collection.length) {
			this.props.dispatch(fetchData({
				type: 'ALL_POWER_BI_GROUPS',
				url: '/powerbi/groups',
			}));
		}
	}

	loadMethodology(props) {
		this.props.dispatch(fetchData({
			type: 'METHODOLOGY',
			url: `/methodology/${props.params.methodologyId}`,
		}))
	}

	loadCategories() {
		this.props.dispatch(fetchData({
			type: 'CATEGORY',
			url: `/categories?show_all=1`,
		}))
	}

	handleSubmit = async () => {
		const formData = new FormData()

		_.map(this.state.images, (image, id) => {
			if(image.keywords){
				formData.append(`images[${id}][keywords]`, image.keywords)
			}

			// no id = new image
			if(image.id){
				formData.append(`images[${id}][id]`, image.id)
			}

			if(image.url){
				formData.append(`images[${id}][file]`, image.url)
			}
		})

		// Append power_bi_group_ids
		_.map(this.state.power_bi_group_ids, (group_id, id) => {
			formData.append(`power_bi_group_ids[${id}]`, group_id.id);
		});

		_.map(this.state.category_ids, (category) => {
			formData.append(`categories[]`, category.id)
		})

		// check if the user has uploaded a new file
		if(this.state.file !== this.props.methodology.file_path){
			formData.append('file', this.state.file)
		}
		formData.append('title', this.state.title)
		formData.append('keywords', this.state.keywords)
		formData.append('description', this.state.description)
    	formData.append('_method', "PUT") // fix for multipart data in Laravel

		// default to blank if this is a new methodology
		const methodologyId = (this.props.params.methodologyId) ? this.props.params.methodologyId : ''
		const response = await api.update(`/methodology/${methodologyId}`, formData)
		const error = api.error(response)

		if(!error){
			this.props.dispatch({
				type: 'METHODOLOGY_FULFILLED',
				payload: response,
			})

			fn.navigate(`/admin/resources/${response.data.id}`)
			fn.showNotification(`The methodology has been successfully ${methodologyId ? 'updated' : 'created'}.`, 'success')
		}

		return true
	}

	handleInputChange = (name, value) => this.setState({ [name]: value });

	handleRemoveFile = () => {
		this.props.dispatch(
			{
				type: 'REPORT_UPDATE',
				payload: {
					'id': this.props.methodology.id,
					'new_file': ''
				}
			}
		)
	}

	handleRemoveFile = () => {
		this.setState({file: null})
	}

	handleDelete = async () => {
		if (window.confirm('Are you sure you wish to delete this methodology?')) {
			const response = await api.delete(`/methodology/${this.props.methodology.id}`)
			const error = api.error(response)
			if(!error){
				this.props.dispatch({
					type: 'METHODOLOGY_DELETED',
					payload: {'id': this.props.methodology.id }
				})
				fn.navigate('/admin/resources');
				fn.showNotification(`You have successfully deleted ${this.state.title}`, 'success');
			}
		}
	}

	render() {
		const {
			methodology,
			productOptions,
			selectedProductOptions,
      		categories,
			allPowerBIGroups,
			selectedAdminGroupOptions
		} = this.props

		const formattedPowerBIGroups = Object.values(allPowerBIGroups.collection).map(group => ({
			id: group.id,
			title: group.alias
		}));

		return (
			<div className="page-wrap with-header">
				<div className="page-content-wrap with-bg">
					<div className="centering">
						<div className="page-header">
							<h1>{this.props.params.methodologyId ? 'Edit' : 'Create'} Resource</h1>

							<div className="page-header-actions">
								{this.props.params.methodologyId &&
									<button className="button-danger" onClick={this.handleDelete}>Delete</button>
								}
							</div>
						</div>
						<Form onSubmit={this.handleSubmit}>
							<div className="box">
								<h3 className="box-heading">Details</h3>
								<TextInput
									name="title"
									label="Title"
									autoComplete="off"
									onChange={this.handleInputChange}
									value={methodology.title}
									validation="required"
									wide
								/>
								<TextInput
									name="description"
									label="Description"
									value={methodology.description}
									onChange={this.handleInputChange}
									textarea={true}
									wide
								/>
								<TextInput
									name="keywords"
									label="Search Keywords"
									value={methodology.keywords}
									onChange={this.handleInputChange}
									textarea={true}
									wide
								/>
                <h3 className="box-heading">Categories</h3>
                <MultiSelect
                  name="category_ids"
                  label="Categories"
                  value={methodology.categories}
                  options={categories || []}
                  onChange={this.handleInputChange}
                  labelKey="name"
                />
								<h3 className="box-heading">Permissions</h3>
								<MultiSelect
								  name="power_bi_group_ids"
								  label="Workspaces"
								  value={selectedAdminGroupOptions}
								  options={formattedPowerBIGroups}
								  onChange={this.handleInputChange}
								/>
								<h3 className="box-heading">File Download</h3>
								{!this.state.file || (this.state.file || {}).preview ? (
									<FileUpload
									  name="file"
									  wide
									  label=""
									  value=""
									  onChange={this.handleInputChange}
									  validation="required"
									  accept=".jpg,.png,.pdf,.svg"
									/>
								) : (
									<div className="form-group form-type-upload form-group-wide">
										<div className="form-field-wrapper">
										<div className="form-field">
												{methodology.original_file_name} <button type="button" className="remove button-danger" title="Remove File" onClick={this.handleRemoveFile}>Remove</button>
											</div>
										</div>
									</div>
								)}
								<h3 className="box-heading">Image Slides</h3>
								<Repeater
									addButton="Add Image"
									name="images"
									value={methodology.images}
									onChange={this.handleInputChange}
								>
									<FileUploadWithPreview name="url"/>
									<div className="repeatable-row-keywords">
										<TextInput
											name="keywords"
											label="Search Keywords"
											textarea={true}
											onChange={this.handleInputChange}
											wide
										/>
									</div>
								</Repeater>
                <				button className="button form-submit">{this.props.params.methodologyId ? 'Update' : 'Submit'}</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		);
	}

}
