import React from 'react';
import { fn } from 'app/utils';

export default class Notification extends React.PureComponent {

	render() {
		const { notifications, show, type } = this.props.data;

		if (show === false) {
			return false;
		}

		const typeLabel = type ? `${_.startCase(_.toLower(type))}!` : '';

		return (
			<div className={`alert alert-${type}`}>
				<div className="centering">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={fn.hideNotification}>
						<i className="ion-close" />
					</button>
					<ul>
						{notifications && notifications.map((alert, i) => <li key={`alert_${i}`}><strong>{typeLabel}</strong> {alert}</li>)}
					</ul>
				</div>
			</div>
		);
	}
}