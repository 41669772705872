import _ from 'lodash';

const defaultState = {
	app: {},
	error: null,
	isLoading: false,
};

export default function sisense(state = defaultState, action) {
	switch	(action.type) {
		case 'SISENSE_PENDING': {
			return {
				...state,
				isLoading: true,
			};
		}
		case 'SISENSE_REJECTED': {
			return {
				...state,
				isLoading: false,
				error: action.payload.data,
			};
		}
		case 'SISENSE_LOADED': {
			return {
				...state,
				isLoading: false,
				app: action.payload,
			};
		}
		default: {
			return state;
		}
	}
}
