import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { url } from "../../../../constants";
import axios from "axios";
import {
  CircularProgress,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { fn, api } from "app/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  label: {
    fontSize: "12px", // Adjust the font size as needed
  },
}));

const useStylesChip = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

export default function SelectFunds(props) {
  const classes = useStyles();
  const [requestForm, setRequestForm] = props.formData;
  const [fundsList, setFundsList] = React.useState([]);
  const [fund, setFund] = React.useState("");
  const [assetLevel, setAssetLevel] = React.useState("funds");

  const handleFundsChange = (event) => {
    if (chipData.indexOf(event.target.value) === -1) {
      chipData.push(event.target.value);
      setChipData(chipData);
    }
    setFund("");
  };

  /// Chips
  const classesChip = useStylesChip();
  const [chipData, setChipData] = [
    requestForm.approvedAssets,
    (fundList) => {
      setRequestForm((form) => ({ ...form, approvedAssets: fundList }));
    },
  ];

  const handleDelete = (chipToDelete) => () => {
    setChipData(chipData.filter((chip) => chip !== chipToDelete));
  };

  const getFundList = React.useCallback(async () => {
    let baseURL = "";
    if (requestForm.user_type === "LP") {
      baseURL =
        assetLevel === "portCos"
          ? url.da_api_base + "/da_get_portcos"
          : url.da_api_base + "/da_get_funds";
    } else if (requestForm.user_type === "PC") {
      baseURL = url.da_api_base + "/da_get_portcos";
    }

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: baseURL,
      withCredentials: false,
      headers: {
        //authorization: "Bearer " + props.token,
        "x-user-comp-name": requestForm.gp_CompanyName,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.Count > 0) {
        const data = response.data.Items[0];

        if (requestForm.user_type === "LP") {
          if (assetLevel === "funds" && data.funds) {
            setFundsList(data.funds);
          } else if (assetLevel === "portCos") {
            setFundsList(data.portcos);
          }
        } else if (requestForm.user_type === "PC") {
          setFundsList(data.portcos);
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, [assetLevel, requestForm.user_type, url.da_api_base]);

  React.useEffect(() => {
    getFundList().catch();
  }, [getFundList]);

  return (
    <>
      {requestForm.user_type === "LP" && (
        <>
          <Typography
            gutterBottom
            style={{
              padding: "10px",
              fontSize: "12px",
              marginBottom: "-5px",
            }}
          >
            Fund assets will be shared at the portfolio company level. <br />
            Select the Asset(s) you want to share.
          </Typography>
          <FormControl component="fieldset">
            <FormLabel component="legend" />
            <RadioGroup
              aria-label="assetLevel"
              name="assetLevel"
              value={assetLevel}
              onChange={(e) => setAssetLevel(e.target.value)}
              row
            >
              <FormControlLabel
                value="portCos"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.label}>
                    Select PortCos
                  </Typography>
                }
              />
              <FormControlLabel
                value="funds"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.label}>
                    Select Funds
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      <Typography
        gutterBottom
        style={{ padding: "10px", fontSize: "12px", marginBottom: "-5px" }}
      >
        Select the PortCos(s) you want to share with the Private Creditor.
      </Typography>

      <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">Assets</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={fund}
          onChange={handleFundsChange}
          label="Assets"
        >
          <MenuItem value="">
            <em>Select</em>
          </MenuItem>
          {fundsList.map((fund) => (
            <MenuItem key={fund} value={fund}>
              {fund}
            </MenuItem>
          ))}
        </Select>

        <Paper component="ul" className={classesChip.root} elevation={0}>
          {chipData.map((data) => (
            <li key={data}>
              <Chip
                variant="outlined"
                label={data}
                onDelete={handleDelete(data)}
                className={classesChip.chip}
              />
            </li>
          ))}
        </Paper>

        {props.prefilledForm && (
          <Paper style={{ marginTop: "2%" }} elevation={1}>
            <Grid style={{ padding: "10px", fontSize: "12px" }} container>
              <Grid item xs={4}>
                <p>
                  <b>Requester: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>{props.prefilledForm.requester_company}</p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Requester Type: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>{fn.renderUserType(requestForm.user_type)}</p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Date Created: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {fn.formatDate(props.prefilledForm.created_at) +
                    " (" +
                    fn.prettyDate(props.prefilledForm.created_at) +
                    ")"}
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Requested Assets: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>{props.prefilledForm.requester_assets}</p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Notes: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>{props.prefilledForm.requester_notes}</p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>Company Names: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {props.prefilledForm.requester_deanonymization
                    ? "Requested"
                    : "Not Requested"}
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>SFDR PAI: </b>
                </p>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {props.prefilledForm.requester_sfdr_pai
                    ? "Requested"
                    : "Not Requested"}
                </p>
              </Grid>
            </Grid>
          </Paper>
        )}
      </FormControl>
    </>
  );
}
