import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ContentLoader, Table } from '@xandanet/react-components';
import { fn } from 'app/utils';
import { url } from 'app/constants';
import { fetchData } from 'app/actions';
import { SearchInput, PageTitle } from 'app/components';
import * as selector from './selector';

@connect((state, ownProps) => {
	const getProduct = selector.makeGetProduct()
	const getProducts = selector.makeGetProducts()

	return {
		product: getProduct(state, ownProps.params.productId),
		products: getProducts(state)
	}
})

export default class View extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 1,
			product: {}
		};
	}

	componentDidMount() {
		this.fetchData()
	}

	fetchData() {
		this.props.dispatch(fetchData({
			type: 'PRODUCT',
			url: `/sisense/folders`,
		}))
	}

	render() {
		const {
			product,
			products,
		} = this.props

		const dashboards = (_.find(product.folders, {name: 'Dashboard'}) || {}).dashboards

		return (
			<div className="page-wrap">
				<ContentLoader
						data={products.collection}
						isLoading={products.isLoading}
					>
					<h1>{(product || {}).name}</h1>

					<ul>
						{_.map(dashboards, (dashboard) => {
							return <li><Link to={`/products/${product._id}/dashboard/${dashboard.oid}`}>{dashboard.title}</Link></li>
						})}
					</ul>

				</ContentLoader>
			</div>
		);
	}
}
