const defaultState = {
    contactOptions: [],
    error:''
};

export default function reducer(state = defaultState, action) {
    switch	(action.type) {
        case 'CONTACT_OPTIONS_PENDING': {
            return {
                ...state
            };
        }

        case 'CONTACT_OPTIONS_REJECTED': {
            return {
                ...state,
                error: action.payload.data,
            };
        }

        case 'CONTACT_OPTIONS_FULFILLED': {
            return {
                ...state,
                contactOptions:  [...action.payload.data],
            };
        }

        default: {
            return state;
        }
    }
}
